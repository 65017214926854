import Request from '../Request.utils'
export interface IListSyncedProductsRequestQuery {
  hasImage?: boolean | null;
  isPublished?: boolean | null;
  page?: number;
  pageSize?: number;
  search?: string;
  status?: string | null;
}
export interface IDetailSyncedProductsRequestParams {
  id: string;
}
export interface IUpsertSyncedProductsRequestBody {
  collections?: any;
  data?: any;
  description?: string | '' | null;
  handle?: string | '' | null;
  id: string;
  images?: ({
    altText?: string | '' | null;
    height?: number;
    id?: string;
    url?: string | '' | null;
    width?: number;
  })[];
  onlineStoreUrl?: string | '' | null;
  productType?: string | '' | null;
  shopifyData?: object;
  tags?: string[];
  title?: string | '' | null;
  totalInventory?: number;
  variants?: ({
    availableForSale?: boolean;
    compareAtPrice?: string | '' | null;
    id?: string;
    inventoryQuantity?: number;
    price?: string | '' | null;
    sku?: string | '' | null;
    title?: string | '' | null;
  })[];
}
export interface IUpdateSyncedProductDataRequestBody {
  data?: any;
  id: string;
}


class SyncProductAPI {
  shopifyProductWebhook = async () => {
    const res = await Request.call('/api/sync-products/webhook', 'POST', undefined, undefined, undefined, );
    return res;
  }
  listSyncedProducts = async (query: IListSyncedProductsRequestQuery) => {
    const res = await Request.call('/api/synced-products', 'GET', undefined, query, undefined, );
    return res;
  }
  detailSyncedProducts = async (params: IDetailSyncedProductsRequestParams) => {
    const res = await Request.call('/api/synced-products/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  upsertSyncedProducts = async (body: IUpsertSyncedProductsRequestBody) => {
    const res = await Request.call('/api/synced-products', 'POST', undefined, undefined, body, );
    return res;
  }
  updateSyncedProductData = async (body: IUpdateSyncedProductDataRequestBody) => {
    const res = await Request.call('/api/synced-products/update-data', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new SyncProductAPI()