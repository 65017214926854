import { Col, Text, CMSLayout, Grid, Input, Button, useUIState, TouchField } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { SVG, ASSETS } from 'assets';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';

const ResellerRegister: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue();
  const { redirect }: any = route.params || {};

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [accountName, setAccountName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [town, setTown] = useState('');
  const [country, setCountry] = useState('');
  const [postCode, setPostCode] = useState('');

  const [{ loading }, setUI] = useUIState();

  const isMobile = rV({ xs: true, md: false });

  const submit = async () => {
    if (!firstName || !email || !password || !confirmPassword) {
      alert('Please fill in at least the following info: Fist Name, Email, Password & Confirm password');
      return;
    }
    if (password !== confirmPassword) {
      alert('Passwords not match');
      return;
    }
    setUI({ loading: true });
    try {
      const res = await Store.Api.User.register({
        firstName,
        lastName,
        email,
        password,
        addressLine1,
        addressLine2, town, country, postCode,
        accountName,
        role: 'reseller',
      });
      if (res.data.error) {
        setUI({ loading: false });
        return alert(String(res.data.error));
      }
      // const token = res.data.data?.token;
      // Store.Client.setToken(token);
      // UserStore.set({
      //   token,
      //   user: res.data.data?.publicInfo,
      // });
      alert('Register successfully. Please login now');
      reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect  });
    } catch(err) {
      setUI({ loading: false, errorMes: '' });
      alert(String(err));
    }
    
  };

  return (
    <Col flex1 middle={!isMobile} p2>
      <Col width={isMobile ? '100%' : '50%'} borderRadius={20} bgMain overflow={'hidden'}>
        <Col
          style={{
            backgroundColor: COLOR.MAIN,
            height: 100,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {/* <SVG.BottleGoose
            fill='white'
            width={'26.25%'}
          /> */}
          <Image source={ASSETS.LOGO} style={{ width: '65%', height: '100%', marginVertical: 5 }} resizeMode='contain' />
        </Col>
        <Col bgWhite p2>
          <Text bold fontSize={20}>Register here</Text>
          <Grid xs='100%' md='100%' lg='50%' marginHorizontal={-5}>
            <Col m0 mt1>
              <Input
                value={firstName}
                onChange={setFirstName}
                placeholder={"First Name"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={lastName}
                onChange={setLastName}
                placeholder={"Last Name"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={accountName}
                onChange={setAccountName}
                placeholder={"Account Name"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={email}
                onChange={setEmail}
                placeholder={"Email"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={password}
                onChange={setPassword}
                placeholder={"Password"}
                password
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder={"Confirm Password"}
                password
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={addressLine1}
                onChange={setAddressLine1}
                placeholder={"Address Line 1"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={addressLine2}
                onChange={setAddressLine2}
                placeholder={"Address Line 2"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={town}
                onChange={setTown}
                placeholder={"Town"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={country}
                onChange={setCountry}
                placeholder={"Country"}
              />
            </Col>
            <Col m0 mt1>
              <Input
                value={postCode}
                onChange={setPostCode}
                placeholder={"Post Code"}
              />
            </Col>
          </Grid>

          <Button
            isLoading={loading}
            onPress={submit}
            mt1
            text='Register'
            solid
            width={isMobile ? '100%' : '50%'}
            alignSelf='center'
            height={45}
          />

          <TouchField mt1 alignSelf={'center'} p0
            onPress={() => {
              reset(SCREEN.Login, { redirect: redirect === 'undefined' ? '' : redirect })
            }}
          >
            <Text textDecorationLine={'underline'}>Already registered? Login here</Text>
          </TouchField>
        </Col>
      </Col>
    </Col>
  )
};

ResellerRegister.routeInfo = {
  title: 'Reseller register - MSupply - Print Manager',
  path: '/reseller-register',
};

export default ResellerRegister;
