import React, { useEffect, useState, useRef, useMemo } from 'react';
import { Col, Row, Text, Button, modal, TouchField, showPopupMessage } from 'components';
import { useWindowDimensions } from 'react-native';
import { TPrintJob } from 'type';
import { COLOR } from 'const';
import Store from 'store';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

interface IProps {
    printJob: TPrintJob
}

const calculateDiscountPercent = (target, compare) => {
    return Math.floor((target - compare) * 100 / target) + '%';
}

const PaymentForm = ({ onPaid, amount, printJobId, queryString }) => {
    const formPaymentRef = useRef<HTMLFormElement>(null);
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const handleSubmit = async e => {
        !!e && e.preventDefault();
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: process.env.NODE_ENV === 'production'
                    ? `https://dev.msc.personify.tech/order/print-job/${printJobId}/complete${queryString}`
                    : `http://localhost:19006/order/print-job/${printJobId}/complete${queryString}`,
            },
        });

        if (result.error) {
            console.log(result.error.message);
        } else {
            onPaid(result);
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }
    return (
        <form ref={formPaymentRef} onSubmit={handleSubmit}>
            <PaymentElement />
            <Button
                mt1
                isLoading={isLoading}
                solid
                text={'Pay ' + amount}
                onPress={() => {
                    setIsLoading(true);
                    // formPaymentRef.current?.submit();
                    handleSubmit(undefined);
                }}
                height={40}
                width='100%'
            />
        </form>
    );
}

const PaymentPopup = ({ printJob }: IProps) => {
    const PaymentStore = Store.usePaymentStore();
    const { stripePromise } = PaymentStore;
    const { width, height } = useWindowDimensions();
    const [variations, setVariations] = useState([]);
    const [isFree, setIsFree] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [fulfillAmount, setFulfillAmount] = useState(printJob.quantity);

    const ProductStore = Store.useProductStore();
    const { productId } = printJob;
    const { product, uiState } = ProductStore.useProduct(productId);
    const [payData, setPayData] = useState<any>({
        show: false,
        data: {},
        options: {
            clientSecret: '',
        },
        savedPaymentMethods: [],
    });



    useEffect(() => {
        // if (uiState.errorMes) {
        //     console.log(uiState.errorMes);
        //     return setIsFree(true);
        // }
        if (!product) return;
        if (!product.variations || product.variations.length === 0) return setIsFree(true);
        const defaultVariationName = product.variations[0].variant;
        const variationName = printJob.productVariantionName || defaultVariationName;
        const findVariant = product.variations.find(v => v.variant === variationName);
        if (!findVariant) return setIsFree(true);
        const prices = findVariant.prices.filter(val => !!val.price);
        if (prices.length === 0) return setIsFree(true);
        setVariations(prices);
    }, [product]);

    const renderError = () => {
        return (
            <Col flex1 middle>
                <Text>{uiState.errorMes}</Text>

                <Button
                    mt2
                    width={100}
                    height={40}
                    solid
                    text='Close'
                    onPress={() => {
                        modal.hide();
                    }}
                />
            </Col>
        );
    };


    const renderFree = () => {
        return (
            <Col flex1 middle>
                <Text>This product is Free (0£)</Text>

                <Button
                    mt2
                    width={100}
                    height={40}
                    solid
                    text='Mark as PAID'
                />
            </Col>
        );
    };

    const renderVariation = () => {
        const defaultPrice = variations.length == 0 ? 0 : variations[0].price
        return (
            <Col flex1>
                <Col middle flex1>
                    <Text center mb2 width={'80%'}>Please choose one of the packages below.{'\n'}You can buy more than what you need for this print, and save the remaining for later usage to have better price.</Text>
                    <Col width={300}>
                        <Row backgroundColor={COLOR.MAIN} p1>
                            <Col width={75}>
                                <Text colorWhite fontSize={18}>Quantity</Text>
                            </Col>
                            <Col width={75} ml1>
                                <Text colorWhite fontSize={18}>Price</Text>
                            </Col>
                            <Col flex1 ml1 alignItems={'flex-end'}>
                                <Text colorWhite fontSize={18}>Discount</Text>
                            </Col>
                        </Row>
                        {variations.map((val, i) => {
                            const isDisabled = printJob.quantity > val.amount
                            return (
                                <Col
                                    backgroundColor={isDisabled ? COLOR.GREY_LIGHT : hoverIndex === i ? "rgba(0,0,0,0.3)" : COLOR.GREY_LIGHT}
                                    onMouseOver={() => {
                                        setHoverIndex(i);
                                    }}
                                    onMouseLeave={() => {
                                        setHoverIndex(-1);
                                    }}
                                    onPress={async () => {
                                        if (isDisabled) return;
                                        const res = await Store.Api.Order.stripeCreatePayment({
                                            amount: val.amount * val.price,
                                            productId: printJob.productId,
                                            variationName: printJob.productVariantionName,
                                            variationAmount: val.amount,
                                            variationPrice: val.price,
                                        });
                                        if (res.data.success && res.data.data?.client_secret) {
                                            setPayData({
                                                show: true,
                                                options: {
                                                    clientSecret: res.data.data?.client_secret,
                                                },
                                                data: {
                                                    amount: val.amount,
                                                    price: val.price,
                                                    displayAmount: val.amount * val.price + '£'
                                                },
                                            });
                                            return;
                                        }
                                        if (res.data.success && res.data.extraConfirmStepData && res.data.extraConfirmStepData.paymentMethods) {
                                            setPayData({
                                                show: true,
                                                options: {
                                                    clientSecret: '',
                                                },
                                                savedPaymentMethods: res.data.extraConfirmStepData.paymentMethods,
                                                data: {
                                                    amount: val.amount,
                                                    price: val.price,
                                                    displayAmount: val.amount * val.price + '£'
                                                },
                                            })
                                        }
                                    }}
                                >
                                    <Row p1 opacity={isDisabled ? 0.4 : 1} key={'options-' + i}>
                                        <Col width={75}>
                                            <Text fontSize={18}>{val.amount}</Text>
                                        </Col>
                                        <Col width={75} ml1>
                                            <Text bold color="red" fontSize={18}>{val.price} £</Text>
                                        </Col>
                                        <Col flex1 ml1 alignItems={'flex-end'}>
                                            <Text fontSize={18} ml1>{calculateDiscountPercent(defaultPrice, val.price)}</Text>
                                        </Col>
                                    </Row>
                                </Col>
                            )
                        })}
                    </Col>
                </Col>

            </Col>
        );
    };

    const renderPayment = () => {
        console.log('payData', payData);
        if (!payData.options.clientSecret && !!payData.savedPaymentMethods && payData.savedPaymentMethods.length > 0) {
            // val -> https://stripe.com/docs/api/payment_methods
            return (
                <Col flex1 middle>
                    <Text bold mb2>Amount: {payData.data?.displayAmount}</Text>
                    {payData.savedPaymentMethods.map((val, i) => {
                        return (
                            <Button
                                solid={i === 0}
                                outline={i !== 0}
                                height={40}
                                width={320}
                                text={`Pay with: ${val.card?.brand} ${val.card?.last4}`}
                                onPress={async () => {
                                    const { data } = payData;
                                    const res = await Store.Api.Order.stripeCreatePayment({
                                        amount: data.amount * data.price,
                                        productId: printJob.productId,
                                        variationName: printJob.productVariantionName,
                                        variationAmount: data.amount,
                                        variationPrice: data.price,
                                        paymentMethodId: val.id,
                                    });
                                    if (!res.data.success) return showPopupMessage({
                                        title: '',
                                        content: String(res.data.error),
                                        buttonOkText: 'OK',
                                        
                                        typeHighlight: 'danger',
                                        contentHighlight: 'Error'
                                  
                                      });
                                    //  alert(res.data.error);
                                    console.log('res.data', res.data);
                                    const queryString = `?amount=${payData.data?.amount}&price=${payData.data?.price}&f_amount=${fulfillAmount}`;
                                    const return_url = process.env.NODE_ENV === 'production'
                                        ? `https://dev.msc.personify.tech/order/print-job/${printJob.id}/complete${queryString}`
                                        : `http://localhost:19006/order/print-job/${printJob.id}/complete${queryString}`;
                                    window.location.href = return_url;
                                }}
                                mb1
                            />
                        )
                    })}
                    <Button
                        outline={true}
                        height={40}
                        width={320}
                        text={`Pay with new card`}
                        onPress={async () => {
                            const { data } = payData;
                            const res = await Store.Api.Order.stripeCreatePayment({
                                amount: data.amount * data.price,
                                productId: printJob.productId,
                                variationName: printJob.productVariantionName,
                                variationAmount: data.amount,
                                variationPrice: data.price,
                                paymentMethodId: 'new',
                            });
                            if (res.data.success && res.data.data?.client_secret) {
                                setPayData({
                                    show: true,
                                    options: {
                                        clientSecret: res.data.data?.client_secret,
                                    },
                                    data,
                                });
                                return;
                            }
                        }}
                    />
                </Col>
            );
        }
        return (
            <Col flex1 middle>
                <Elements stripe={stripePromise} options={payData.options}>
                    <PaymentForm
                        onPaid={e => {
                            console.log('onPaid', e);
                        }}
                        amount={payData.data?.displayAmount}
                        printJobId={printJob.id}
                        queryString={`?amount=${payData.data?.amount}&price=${payData.data?.price}&f_amount=${fulfillAmount}`}
                    />
                </Elements>
            </Col>
        );
    }

    return (
        <Col round1 bgWhite shadow p1 width={width * 0.7} height={height * 0.7} overflow='auto'>
            {uiState.errorMes ? renderError() :
                isFree ? renderFree() : payData.show ? renderPayment() : (
                    renderVariation()
                )}
        </Col>
    );
};

export default PaymentPopup;
