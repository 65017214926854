import React, { useEffect, useState, useRef } from 'react';
import { IScreen, TDesign } from 'type';
import { CMSLayout, Col, Row, Text, Button, TouchField, Input02, Select01, RatioCol, RatioCol2, UploadFile, useUIState, useRefState, Grid, Input, ShimmerLoading } from 'components';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { COLOR, SCREEN } from 'const';
import Store from 'store';
import { Image } from 'react-native';
import { Entypo, AntDesign } from '@expo/vector-icons';
import { UpsertResellerTabs } from './UpsertReseller.Tabs';
import { TimeHelper } from 'helpers';

export const UpsertResellerDesign = ({ resellerId }: any) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const status = route.params?.status;
  const UserStore = Store.useUserStore();
  const DesignStore = Store.useDesignStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [designs, getDesigns, setDesigns] = useRefState<Array<TDesign>>([])

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      if (getDesigns().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await DesignStore.getList(p, status, resellerId, 1);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setDesigns(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };
  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    getData(1);
    return unsubscribe;
  }, [status]);

  const renderDesigns = () => {
    if (designs.length === 0) return (
      <Col>
        <Text>No design found</Text>
      </Col>
    );
    return (
      <Grid xs='50%' md='25%' marginHorizontal={-30}>
        {designs.map((val, i) => {
          const showPublishedStatus = UserStore.user?.role === 'reseller';
          const isPublished = val.products.length > 0;
          return (
            <Col paddingTop={40} bgWhite shadow overflow={'hidden'} round1 m1 p1 key={val.id} onPress={() => navigation.navigate(SCREEN.UpsertDesign, { id: val.id })}>
              <RatioCol
                width={'100%'}
                ratio={1}
              >
                <Image source={{ uri: val.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
              </RatioCol>
              <Row height={50} mt1>
                <Text numberOfLines={2} subtitle1>{val.name}</Text>
              </Row>
              <Text>Artboard size: <Text bold>{val.width} x {val.height}</Text> ({val.unit || 'mm'})</Text>
              <Col
                absolute top={0} right={0} width={115}
              >
                {showPublishedStatus && (
                  <Col borderBottomLeftRadius={10}
                    backgroundColor={isPublished ? '#2E8304' : '#FF9A01'}
                    p0 middle
                  >
                    <Text color='white' caption>{isPublished ? 'Published' : 'Not published'}</Text>
                  </Col>
                )}
                <Col
                  p0 backgroundColor={COLOR.MAIN} marginTop={2} middle
                >
                  <Text color='white' caption>{val.isCustomizable ? 'Customisable' : 'Print on Demand'}</Text>
                </Col>
              </Col>
            </Col>
          )
        })}
      </Grid>
    );
  };

  return (
    <Col flex1 m2 marginTop={-5} p2>
      {errorMes ? (
        <Col flex1 middle>
          <Text color="red" subtitle1>{errorMes}</Text>
        </Col>
      ) : (
        fetching ? (
          <Row height={200} stretch marginHorizontal={-30}>
            <Col round1 bgWhite flex={1} m1 p1>
              <ShimmerLoading flex1 />
            </Col>
            <Col round1 bgWhite flex={1} m1 p1>
              <ShimmerLoading flex1 />
            </Col>
            <Col round1 bgWhite flex={1} m1 p1>
              <ShimmerLoading flex1 />
            </Col>
            <Col round1 bgWhite flex={1} m1 p1>
              <ShimmerLoading flex1 />
            </Col>
          </Row>
        ) : (
          renderDesigns()
        )
      )}

    </Col>
  )
}