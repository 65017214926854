import React, { useRef, useMemo, useState, useImperativeHandle, useEffect } from 'react';
import { TDesign, TProduct } from 'type';
import { Col, modal, Row } from 'components';
import { useWindowDimensions } from 'react-native';
import Store, { checkPolotnoData } from 'helpers/EditorSDK';
import { Button as AntButton } from 'antd';
import { Text } from 'components';
import { useEvent } from 'js-events-listener/react';

interface Props {
  onSave: (data: { url: string, width: number, height: number }) => void;
  image: string;
  width: number;
  height: number;
}

const MICRO_SERVICES_HOST = "https://dev.services.personify.tech";
const editorStore = new Store();

const MakeEditAreaModal = ({ onSave, image, width: w, height: h }: Props) => {
  const { width, height } = useWindowDimensions();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    editorStore.onReady()
  }, []);

  useEvent('EDITOR_UPDATE_ARTWORK_DATA', (data: any) => {
    if (data?.artworkUrl) {
      onSave({
        url: data.artworkUrl,
        width: data.data?.width,
        height: data.data?.height,
      })
    }
  }, []);

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        <iframe
          ref={iframeRef}
          src={`${MICRO_SERVICES_HOST}/editor/make-editable-area?image=${image}&width=${w}&height=${h}`}
          style={{ width: "100%", height: "100%", border: "none" }}
          onLoad={() => {
            editorStore.init(iframeRef.current);
          }}
        />
      </Col>
    </Col>
  );
}

export default MakeEditAreaModal;
