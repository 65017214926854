import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Button, Modal } from 'antd';
import { BgIcon, Col, InputField, Row, showPopupMessage, Text } from 'components';
import { COLORS } from 'const';
import { TCMSOrder } from 'type';
import apiClient from 'store/api-client';
import { ActivityIndicator } from 'react-native';

interface Props {
}

const RequireApprovalModal = (props: Props, ref) => {
  const [show, setShow] = useState(false);
  const order = useRef<TCMSOrder>(null);
  const [emails, setEmails] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [url, setUrl] = useState<string>("");

  const getUrl = async () => {
    try {
      setLoading(true);
      const res = await apiClient.Api.Order.sendConfirmEmail({
        orderId: order.current?.['Order ID'],
        emails: emails,
      });
      setUrl(res.data.url);
      if (order.current?.["Customer Email"]) {
        setEmails([order.current?.["Customer Email"]]);
      }
    } catch (error) {
      showPopupMessage({
        title: "",
        content: typeof error === "string" ? error : JSON.stringify(error),
        buttonOkText: "OK",
        // 
        typeHighlight: "danger",
        contentHighlight: "Notice",
      });
    } finally {
      setLoading(false);
    }
  }

  useImperativeHandle(ref, () => ({
    show: (data) => {
      order.current = data;
      setShow(true);
      setUrl("");
      setTimeout(() => {
        getUrl();
      }, 10)
    },
  }));

  const onSubmit = async () => {
    try {
      setLoadingSend(true);
      const res = await apiClient.Api.Order.sendConfirmEmail({
        orderId: order.current?.['Order ID'],
        emails: emails,
      });
      setUrl(res.data.url);
    } catch (error) {
      showPopupMessage({
        title: "",
        content: typeof error === "string" ? error : JSON.stringify(error),
        buttonOkText: "OK",
        // 
        typeHighlight: "danger",
        contentHighlight: "Notice",
      });
    } finally {
      setLoadingSend(false);
    }
  }

  return (
    <Modal
      open={show}
      title="Send emails"
      closable
      onCancel={() => setShow(false)}
      width={640}
      footer={[
        <Button
          size="large"
          type="primary"
          key="save-candle"
          onClick={onSubmit}
          disabled={loading || loadingSend}
          loading={loadingSend}
          style={{ backgroundColor: COLORS.BLUE }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Send</Text>
        </Button>
      ]}
    >
      <Col>
        <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={12} />
        {loading ? (
          <ActivityIndicator />
        ) : (
          <Col marginBottom={12} round0 borderThin borderColor="#FFB020" pv1 ph2 backgroundColor={'#FFF6DC'}>
            <Text lineHeight={17}>Confirm URL: </Text>
            <Text
              color={COLORS.BLUE} lineHeight={17}
              onClick={() => window.open(url, "_blank")}
              // @ts-ignore
              style={{ cursor: "pointer" }}
            >
              {url}
            </Text>
          </Col>
        )}
        <InputField
          title="Emails"
          value={emails.join(", ")}
          placeholder="Enter emails separated by commas"
          onChangeText={(v) => {
            setEmails(v.split(",").map(e => e.trim()))
          }}
        />
      </Col>
    </Modal>
  );
};

export default forwardRef(RequireApprovalModal);
