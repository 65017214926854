import React from "react";
import { modal, showPopupMessage } from "components";
import CustomerAddressModal from "./ListOrders.CustomerAddress.Modal";
import { TCMSOrder, TShopifyOrder } from "type";
import Store from "store";

import {
  ResellerAcceptOrdersModal,
} from "./ListOrders.Comps";
import { useZustandUserStore } from "store/User.Store";
import { AdminOverrideOrderInfo } from "./components/AdminOverrideOrderInfo";

export const commonFuncs = {
  showCustomerInfo: (order: TShopifyOrder) => {
    console.log("order", order);
    modal.show(
      <CustomerAddressModal
        shipping_address={order.shipping_address}
        customer={order.customer}
      />
    );
  },
  showPreview: () => {
    showPopupMessage({
      title: "",
      content: "Coming soon",
      buttonOkText: "OK",
      // 
      typeHighlight: "warning",
      contentHighlight: "Notice",
    });
    // alert('Coming soon');
  },
  onDelete: async (orders: Array<TCMSOrder>) => {
    // const shouldDelete = confirm(`Are you sure to delete ${orders.length === 1 ? 'this order' : 'these orders'}?`);
    // if (!shouldDelete) return;
    // const deleteOrderByIds = Store.useOrderStore.getState().deleteOrderByIds;
    // try {
    //   await deleteOrderByIds(orders);
    //   return true;
    // } catch(err) {
    //   showPopupMessage({
    //     title: '',
    //     content: String(err),
    //     buttonOkText: 'OK',
    //     
    //     typeHighlight: 'danger',
    //     contentHighlight: 'Error'

    //   });
    //   // alert(String(err));
    // }
    const result = await showPopupMessage({
      title: "",
      content: `Are you sure to delete ${orders.length === 1 ? "this order" : "these orders"
        }?`,
      buttonOkText: "OK",
      typeHighlight: "danger",
      contentHighlight: "This action is not reversible.",
    });
    if (result && result.buttonOKClicked === true) {
      const deleteOrderByIds =
        Store.useOrderStore.getState().deleteOrderByIds;
      const user = useZustandUserStore.getState().user;
      const deletedBy = user?.email;
      try {
        await deleteOrderByIds(orders, deletedBy);
        return true;
      } catch (err) {
        showPopupMessage({
          title: "",
          content: String(err),
          buttonOkText: "OK",
          // 
          typeHighlight: "danger",
          contentHighlight: "Error",
        });
        // alert(String(err));
      }
    }
    // showPopupMessage({
    //   title: "",
    //   content: `Are you sure to delete ${
    //     orders.length === 1 ? "this order" : "these orders"
    //   }?`,
    //   buttonOkText: "OK",
    //   onButtonOkClick: async () => {
    //     const deleteOrderByIds =
    //       Store.useOrderStore.getState().deleteOrderByIds;
    //     const user = useZustandUserStore.getState().user;
    //     const deletedBy = user?.email;
    //     try {
    //       await deleteOrderByIds(orders, deletedBy);
    //       return true;
    //     } catch (err) {
    //       showPopupMessage({
    //         title: "",
    //         content: String(err),
    //         buttonOkText: "OK",
    //         // 
    //         typeHighlight: "danger",
    //         contentHighlight: "Error",
    //       });
    //       // alert(String(err));
    //     }
    //   },
    //   typeHighlight: "danger",
    //   contentHighlight: "This action is not reversible.",
    // });
  },
  restoreOrder: async (orders: Array<TCMSOrder>) => {
    // const shouldDelete = confirm(`Are you sure to restore ${orders.length === 1 ? 'this order' : 'these orders'}?`);
    // if (!shouldDelete) return;
    // const restoreOrderByIds = Store.useOrderStore.getState().restoreOrderByIds;
    // try {
    //   await restoreOrderByIds(orders);
    //   return true;
    // } catch(err) {
    //   showPopupMessage({
    //     title: '',
    //     content: String(err),
    //     buttonOkText: 'OK',
    //     
    //     typeHighlight: 'danger',
    //     contentHighlight: 'Error'

    //   });
    //   // alert(String(err));
    // }
    const result = await showPopupMessage({
      title: "",
      content: `Are you sure to restore ${orders.length === 1 ? "this order" : "these orders"
        }?`,
      buttonOkText: "OK",
      typeHighlight: "danger",
      contentHighlight: "This action is not reversible.",
    });
    if (result && result.buttonOKClicked === true) {
      const restoreOrderByIds =
        Store.useOrderStore.getState().restoreOrderByIds;
      console.log('restore', restoreOrderByIds)
      try {
        await restoreOrderByIds(orders);
        return true;
      } catch (err) {
        showPopupMessage({
          title: "",
          content: String(err),
          buttonOkText: "OK",
          // 
          typeHighlight: "danger",
          contentHighlight: "Error",
        });
      }
    }
    // showPopupMessage({
    //   title: "",
    //   content: `Are you sure to restore ${
    //     orders.length === 1 ? "this order" : "these orders"
    //   }?`,
    //   buttonOkText: "OK",
    //   onButtonOkClick: async () => {
    //     const restoreOrderByIds =
    //       Store.useOrderStore.getState().restoreOrderByIds;
    //     try {
    //       await restoreOrderByIds(orders);
    //       return true;
    //     } catch (err) {
    //       showPopupMessage({
    //         title: "",
    //         content: String(err),
    //         buttonOkText: "OK",
    //         // 
    //         typeHighlight: "danger",
    //         contentHighlight: "Error",
    //       });
    //     }
    //   },
    //   typeHighlight: "danger",
    //   contentHighlight: "This action is not reversible.",
    // });

  },


  reprint: async (order: TCMSOrder, force?: boolean) => {
    // let shouldDelete = force;
    if (!force) {
      const result = await showPopupMessage({
        title: "",
        content: `Are you sure to reprint this order?`,
        buttonOkText: "OK",
        typeHighlight: "danger",
        contentHighlight: "This action is not reversible.",

      });
      if (result && result.buttonOKClicked === true) {
        const { updateOrderStage, updateShareData } =
          Store.useOrderStore.getState();
        try {
          await updateOrderStage([order], {
            Status: "Accepted",
            Stage: "In Production",
            StageStatus: "On Time",
          });
          await updateShareData(
            order,
            force ? { resellerReprint: true } : { reprint: true }
          );
        } catch (err) {
          showPopupMessage({
            title: "",
            content: String(err),
            buttonOkText: "OK",
            typeHighlight: "danger",
            contentHighlight: "Error",
          });
          // alert(String(err));
        }
      }
      // shouldDelete = confirm(`Are you sure to reprint this order?`);
      // showPopupMessage({
      //   title: "",
      //   content: `Are you sure to reprint this order?`,
      //   buttonOkText: "OK",
      //   onButtonOkClick: async () => {
      //     const { updateOrderStatus, updateShareData } =
      //       Store.useOrderStore.getState();
      //     try {
      //       await updateOrderStatus([order], "Accepted");
      //       await updateShareData(
      //         order,
      //         force ? { resellerReprint: true } : { reprint: true }
      //       );
      //     } catch (err) {
      //       showPopupMessage({
      //         title: "",
      //         content: String(err),
      //         buttonOkText: "OK",
      //         // 
      //         typeHighlight: "danger",
      //         contentHighlight: "Error",
      //       });
      //       // alert(String(err));
      //     }
      //   },
      //   typeHighlight: "danger",
      //   contentHighlight: "This action is not reversible.",
      // });
    }
    // if (!shouldDelete) return;
  },
  holdOrders: async (orders: Array<TCMSOrder>, isAdmin?: boolean) => {
    const holdableOrders = orders.filter(i => !i.StageStatus?.includes("Held"));
    const result = await showPopupMessage({
      title: "",
      content: "",
      buttonOkText: "OK",
      typeHighlight: "danger",
      contentHighlight: `Are you sure you want to hold ${holdableOrders.length === 1 ? "this order" : "these orders"}?`,
    });
    if (result && result.buttonOKClicked === true) {
      await Store.useOrderStore.getState().updateOrderStage(holdableOrders, {
        StageStatus: isAdmin ? "Held By Admin" : "Held",
      });
      return true;
    }
  },
  resumeOrders: async (orders: Array<TCMSOrder>, isAdmin?: boolean) => {
    const resumableOrders = orders.filter(i => i.StageStatus?.includes("Held"));
    if (!isAdmin && orders.some(i => i.StageStatus === "Held By Admin")) {
      showPopupMessage({
        title: "Permission denied",
        content: "",
        buttonOkText: "OK",
        typeHighlight: "danger",
        contentHighlight: "This order has been put on hold by admin",
      });
      return false;
    }
    const result = await showPopupMessage({
      title: "",
      content: "",
      buttonOkText: "OK",
      typeHighlight: "danger",
      contentHighlight: `Are you sure you want to resume ${resumableOrders.length === 1 ? "this order" : "these orders"}?`,
    });
    if (result && result.buttonOKClicked === true) {
      await Promise.all(resumableOrders.map(async (order) => {
        const lastPipeline = order.Pipelines?.[order.Pipelines?.length - 1];
        if (!lastPipeline) return;
        const isPaid = lastPipeline.SharedData?.isPaid;
        let resumeStage;
        if (order.Stage === "Pre Production") {
          resumeStage = isPaid ? "Queued For Production" : "Awaiting Payment";
        }
        if (order.Stage === "In Production") {
          resumeStage = "On Time";
        }
        return Store.useOrderStore.getState().updateOrderStage([order], {
          StageStatus: resumeStage,
        })
      }))
      return true;
    }
  },
  resellerPayPendingOrder: async (
    orders: Array<TCMSOrder>,
    navigation: any,
    onSuccess?: () => void
  ) => {
    modal.show(
      <ResellerAcceptOrdersModal
        orders={orders}
        navigation={navigation}
        onSuccess={onSuccess}
      />
    );
  },
  resellerReprintOrder: async (orders: Array<TCMSOrder>, navigation: any) => {
    modal.show(
      <ResellerAcceptOrdersModal
        orders={orders}
        navigation={navigation}
        isReprint
      />
    );
  },
  adminShowOverrideOrderInfoModal: async (order: TCMSOrder) => {
    modal.show(
      <AdminOverrideOrderInfo
        order={order}
        onSuccess={() => {
          modal.hide();
          window.location.reload();
        }}
      />
    );
  },
};
