import React, { useState } from 'react';
import { Col, Row, Text, modal, InputField, ButtonOutline, showPopupMessage } from 'components';
import { useNavigationMiddleWare } from 'navigation/NavigationContext';
import { COLOR, COLORS, SCREEN } from 'const';
import { useWindowDimensions } from 'react-native';
import { Image as ImageAnt, Button as AntButton } from 'antd';
import EditorIframe from 'components/widgets/editor-iframe/EditorIframe';

const VariantDesignModal = ({ variant, designId, onChange, design, product }) => {
  const { width, height } = useWindowDimensions();

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        <EditorIframe
          onClose={() => modal.hide()}
          onVariantSave={(newVariant) => {
            onChange({
              variantDesignId: newVariant.id,
              style: variant?.style || '',
              price: variant?.price || 0,
              image: newVariant.image,
              galleries: newVariant.galleries,
            })
          }}
          design={design}
          product={product}
          variantDesignId={variant.variantDesignId}
        />
      </Col>
    </Col>
  );
}

const Variants = ({ design, setDesign, onRequestSampleOrder, product, onFocusVariant }) => {
  const { updateEditting } = useNavigationMiddleWare();
  const [show, setShow] = useState(true);
  const alreadyHasVariants = (design?.variants || []).length > 0;

  const screenName = "ResellerCreateMyOwnProduct";

  const onNewVariant = () => {
    modal.show(
      <VariantDesignModal
        design={design}
        product={product}
        variant={{
          variantDesignId: 'new',
          style: '',
          price: 0,
          image: '',
          galleries: [],
        }}
        designId={design.id}
        onChange={(newVariant) => {
          const newVariants = [...design.variants];
          newVariants.push(newVariant);
          setDesign({
            ...design,
            variants: newVariants
          })
        }}
      />
    );
    updateEditting(screenName, true);
  }

  const onPressAddVariant = () => {
    if ((design?.galleries || []).length === 0) {
      showPopupMessage({
        title: '',
        content: 'Please click Edit design first',
        buttonOkText: 'OK',
        typeHighlight: 'warning',
        contentHighlight: 'No design detected',
      });
    } else if (alreadyHasVariants) {
      onNewVariant();
    } else {
      setDesign({
        ...design,
        variants: [
          {
            style: "Default",
            image: design.image,
            galleries: design.galleries,
            variantDesignId: design.id,
            price: design.resalePrice || 0,
          }
        ]
      })
    }
  }

  return (
    <Col flex1>
      <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={16} />
      <Row justifyContent="space-between">
        <Text marginBottom={16} fontSize={16} fontWeight="600">Variants</Text>
        <AntButton
          type="text"
          disabled={!design?.id}
          onClick={() => setShow(s => !s)}
        >
          <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
            {show ? "Hide" : "Show"}
          </Text>
        </AntButton>
      </Row>
      {show && (
        <>
          <Text>Use this function to add multiple designs to the same SKU</Text>
          <Text mb2>If you require assistance with this, click here</Text>
          {design?.variants?.map((variant, index) => {
            return (
              <Col key={'variant-' + index} mb2 onPress={() => onFocusVariant(variant)} p1>
                <Row alignItems={'flex-start'}>
                  <Col width={150} mr1>
                    <ImageAnt.PreviewGroup>
                      <Col width={150} height={150}>
                        <ImageAnt
                          src={variant.image}
                          style={{ width: 150, height: 150, objectFit: 'cover' }}
                        />
                        {/* <Image source={{ uri: variant.image }} style={{ width: 150, height: 150 }} resizeMode='cover' /> */}
                      </Col>
                    </ImageAnt.PreviewGroup>
                  </Col>
                  <Col flex1>
                    <InputField
                      title="Style"
                      value={variant.style}
                      onChangeText={(v) => {
                        const newVariants = [...design.variants];
                        newVariants[index].style = v;
                        setDesign({
                          ...design,
                          variants: newVariants
                        })
                        updateEditting(screenName, true);
                      }}
                      marginBottom={16}
                      inputProps={{
                        onFocus: () => onFocusVariant(variant),
                      }}
                    />
                    <InputField
                      title="Price"
                      value={variant.price}
                      onChangeText={(v) => {
                        const newVariants = [...design.variants];
                        newVariants[index].price = v;
                        setDesign({
                          ...design,
                          variants: newVariants
                        })
                        updateEditting(screenName, true);
                      }}
                      marginBottom={16}
                      inputProps={{
                        onFocus: () => onFocusVariant(variant),
                      }}
                    />
                    <Row flexWrap={'wrap'} justifyContent="space-between">
                      <Row>
                        <AntButton
                          type="primary"
                          onClick={() => {
                            modal.show(
                              <VariantDesignModal
                                design={design}
                                product={product}
                                variant={variant}
                                designId={design.id}
                                onChange={(newVariant) => {
                                  const newVariants = [...design.variants];
                                  newVariants[index] = newVariant;
                                  const obj: any = { variants: newVariants };
                                  if (index === 0) {
                                    obj.image = newVariant.image;
                                    obj.galleries = newVariant.galleries;
                                  } else {
                                    onFocusVariant(newVariant);
                                  }
                                  setDesign(s => ({
                                    ...s,
                                    ...obj
                                  }))
                                }}
                              />
                            )
                          }}
                          style={{ marginRight: 16, backgroundColor: COLORS.HEADLINES }}
                        >
                          <Text fontSize={16} fontWeight="600" colorWhite>Edit design</Text>
                        </AntButton>
                        <AntButton
                          type="primary"
                          onClick={() => {
                            onRequestSampleOrder(variant);
                          }}
                          style={{ marginRight: 16, backgroundColor: COLORS.HEADLINES }}
                        >
                          <Text fontSize={16} fontWeight="600" colorWhite>Order a sample</Text>
                        </AntButton>
                      </Row>
                      {/* {index !== 0 && ( */}
                      <AntButton
                        type="primary"
                        onClick={() => {
                          const newVariants = [...design.variants];
                          newVariants.splice(index, 1);
                          setDesign({
                            ...design,
                            variants: newVariants
                          })
                          updateEditting(screenName, true);
                        }}
                        style={{ backgroundColor: COLORS.RED }}
                      >
                        <Text fontSize={16} fontWeight="600" colorWhite>Delete</Text>
                      </AntButton>
                      {/* )} */}
                    </Row>

                  </Col>
                </Row>
              </Col>
            );
          })}
          <Row flexWrap={'wrap'}>
            <ButtonOutline
              icon="add"
              title="Add variant"
              onPress={onPressAddVariant}
            />
          </Row>
        </>
      )}
    </Col>
  )
};

export default Variants;
