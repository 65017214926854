import React, { useEffect, useState } from 'react';
import { IScreen, TDesign, TProduct, TProductInstance } from 'type';
import { CMSLayout, Col, Row, Text, Button, useUIState, Grid, RatioCol, ShimmerLoading, useRefState } from 'components';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { TimeHelper } from 'helpers';
import Store from 'store';
import { Image } from 'react-native';

const ListInstances: IScreen = () => {
  const { navigation } = useNavFunc();

  const UserStore = Store.useUserStore();
  const DesignStore = Store.useDesignStore();
  const InstaceStore = Store.useInstaceStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [designs, getDesigns, setDesigns] = useRefState<Array<TProductInstance>>([])

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      if (getDesigns().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await InstaceStore.getList(p, resellerId);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setDesigns(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  const renderInstances = () => {
    return (
      <>
        <Text caption mv1 marginHorizontal={-20}>* Products are combination of physical product (from product library) and design.</Text>
        <Grid xs='50%' md='25%' marginHorizontal={-30}>
          {designs.map((val, i) => {
            return (
              <Col bgWhite shadow overflow={'hidden'} round1 m1 p1 key={val.id} onPress={() => navigation.navigate(SCREEN.UpsertDesign, { id: val.id })}>
                <RatioCol
                  width={'100%'}
                  ratio={1}
                >
                  <Image source={{ uri: val.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                </RatioCol>
                <Row height={50} mt1>
                  <Text numberOfLines={2} subtitle1>{val.name}</Text>
                </Row>
              </Col>
            )
          })}
        </Grid>
        </>
    );
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >My Products</Text>
        <Button
          text="Create"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            navigation.navigate(SCREEN.UpsertInstance, { id: 'new' });
          }}
        />
      </Row>
      <Col flex1 m2 marginTop={-5} p2>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Row height={200} stretch marginHorizontal={-30}>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
            </Row>
          ) : (
            renderInstances()
          )
        )}
        
      </Col>
    </CMSLayout>
  );
};

ListInstances.routeInfo = {
  title: 'List Product Instances - MSupply',
  path: '/product-instances',
};

export default ListInstances;
