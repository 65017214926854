import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, ResellerPrintJobItem, SwapIconEffect, modal, showPopupMessage } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper, ValHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';

const ResellerDepositCallback: IScreen = () => {
	// const PrintJobStore = Store.usePrintJobStore();
	const { route, reset, navigation } = useNavFunc();
	// @ts-ignore
	const { amount, currency, resellerId, f_amount, payment_intent } = route.params || {};
	const [isCompleted, setIsCompleted] = useState(false);
	const [autoRedirectSeconds, setAutoRedirectSeconds] = useState(3);

	useEffect(() => {
		if (!resellerId) return;
		if (!payment_intent) return;
		(async () => {
			const res = await Store.Api.Payment.markDepositComplete({
				amount: Number(amount),
				fulfillAmount: Number(f_amount),
        currency,
				payment_intent_id: payment_intent,
			});
			if (res.data.success) {
				setIsCompleted(true);
				setTimeout(() => {
					setAutoRedirectSeconds(2);
					setTimeout(() => {
						setAutoRedirectSeconds(1);
						setTimeout(() => {
							setAutoRedirectSeconds(0);
							reset(SCREEN.Settings, { tabId: 'billing' });
						}, 1000);
					}, 1000);
				}, 1000);
			} else {
				showPopupMessage({
          title: '',
          content: 'Something wrong, please try again. ' + String(res.data.error),
          buttonOkText: 'OK',
          
          typeHighlight: 'danger',
          contentHighlight: 'Error'
    
        });
				// alert('Something wrong, please try again. ' + res.data.error);
			}
		})();
	}, [resellerId, payment_intent]);
	
	return (
		<CMSLayout requireAuthen>
			<Row m2 marginBottom={0} justifyContent={'space-between'}>
				<Text h3 >Thank you for your deposit</Text>
				<Button
          text="Back to Billing"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            navigation.reset({ index: 0, routes: [{ name: SCREEN.Settings, params: { tabId: 'billing' } }] });
          }}
        />
			</Row>
			<Col flex1 m2 mv1 p2 round1 bgWhite middle>
				{!isCompleted ? (
					<ActivityIndicator color={COLOR.MAIN} size='large' />
				) : (
					<>
						<Feather name="check-circle" size={40} color="#07A04E" />
						<Text center mt2 fontSize={18}>You have successfully added {ValHelper.formatMoney(amount)} {currency}.</Text>
						<Text center mt2 fontSize={18}>Redirecting back to Billing in {autoRedirectSeconds} seconds.</Text>
					</>
				)}
			</Col>
		</CMSLayout>
	)
};

ResellerDepositCallback.routeInfo = {
	title: 'Deposit Funds response - MSupply',
	path: '/resellers/:resellerId/deposit-funds/complete',
};

export default ResellerDepositCallback;
