
export const bagsScript = `#target photoshop
//
// bags_2.jsx
//

//
// Generated Fri Oct 11 2024 15:12:57 GMT+0700
//

cTID = function(s) { return app.charIDToTypeID(s); };
sTID = function(s) { return app.stringIDToTypeID(s); };

//
//==================== bags-2 ==============
//
function bags_2() {
  // Open
  function step1(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putBoolean(sTID("dontRecord"), false);
    desc1.putBoolean(sTID("forceNotify"), true);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 593);
    desc1.putBoolean(sTID("template"), false);
    executeAction(cTID('Opn '), desc1, dialogMode);
  };

  // Edit Contents
  function step2(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 593);
    desc1.putInteger(cTID('LyrI'), 9);
    executeAction(sTID('placedLayerEditContents'), desc1, dialogMode);
  };

  // Place
  function step3(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('Idnt'), 20);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.ARTWORK_FILE_PATH}}"));
    desc1.putEnumerated(cTID('FTcs'), cTID('QCSt'), sTID("QCSAverage"));
    var desc2 = new ActionDescriptor();
    desc2.putUnitDouble(cTID('Hrzn'), cTID('#Pxl'), 0);
    desc2.putUnitDouble(cTID('Vrtc'), cTID('#Pxl'), 0);
    desc1.putObject(cTID('Ofst'), cTID('Ofst'), desc2);
    var desc3 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putIdentifier(cTID('Lyr '), 19);
    desc3.putReference(cTID('From'), ref1);
    var ref2 = new ActionReference();
    ref2.putIdentifier(cTID('Lyr '), 20);
    desc3.putReference(cTID('T   '), ref2);
    desc1.putObject(sTID("replaceLayer"), cTID('Plc '), desc3);
    executeAction(cTID('Plc '), desc1, dialogMode);
  };

  // Close
  function step4(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putBoolean(sTID("maximizeCompatibility"), false);
    desc1.putObject(cTID('As  '), sTID("largeDocumentFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 598);
    desc1.putBoolean(cTID('LwCs'), true);
    desc1.putEnumerated(cTID('Svng'), cTID('YsN '), cTID('Ys  '));
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  // Save
  function step5(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putEnumerated(cTID('BytO'), cTID('Pltf'), sTID("IBMPC"));
    desc2.putEnumerated(sTID("layerCompression"), cTID('Encd'), sTID("RLE"));
    desc1.putObject(cTID('As  '), sTID("TIFFFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.OUTPUT_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 593);
    desc1.putBoolean(cTID('LwCs'), true);
    executeAction(cTID('save'), desc1, dialogMode);
  };

  // Close
  function step6(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 593);
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  step1();      // Open
  step2();      // Edit Contents
  step3();      // Place
  step4();      // Close
  step5();      // Save
  step6();      // Close
};



//=========================================
//                    bags_2.main
//=========================================
//

bags_2.main = function () {
  bags_2();
};

bags_2.main();

// EOF

"bags_2.jsx"
// EOF
`

export const tshirtScript = `#target photoshop
//
// tshirt3.jsx
//

//
// Generated Sat Oct 12 2024 16:40:56 GMT+0700
//

cTID = function(s) { return app.charIDToTypeID(s); };
sTID = function(s) { return app.stringIDToTypeID(s); };

//
//==================== tshirt3 ==============
//
function tshirt3() {
  // Open
  function step1(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putBoolean(sTID("dontRecord"), false);
    desc1.putBoolean(sTID("forceNotify"), true);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 117);
    desc1.putBoolean(sTID("template"), false);
    executeAction(cTID('Opn '), desc1, dialogMode);
  };

  // Select
  function step2(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putName(cTID('Lyr '), "main");
    desc1.putReference(cTID('null'), ref1);
    desc1.putBoolean(cTID('MkVs'), false);
    var list1 = new ActionList();
    list1.putInteger(9);
    desc1.putList(cTID('LyrI'), list1);
    executeAction(cTID('slct'), desc1, dialogMode);
  };

  // Edit Contents
  function step3(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 117);
    desc1.putInteger(cTID('LyrI'), 9);
    executeAction(sTID('placedLayerEditContents'), desc1, dialogMode);
  };

  // Place
  function step4(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('Idnt'), 13);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.ARTWORK_FILE_PATH}}"));
    desc1.putEnumerated(cTID('FTcs'), cTID('QCSt'), sTID("QCSAverage"));
    var desc2 = new ActionDescriptor();
    desc2.putUnitDouble(cTID('Hrzn'), cTID('#Pxl'), 0);
    desc2.putUnitDouble(cTID('Vrtc'), cTID('#Pxl'), 0);
    desc1.putObject(cTID('Ofst'), cTID('Ofst'), desc2);
    var desc3 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putIdentifier(cTID('Lyr '), 12);
    desc3.putReference(cTID('From'), ref1);
    var ref2 = new ActionReference();
    ref2.putIdentifier(cTID('Lyr '), 13);
    desc3.putReference(cTID('T   '), ref2);
    desc1.putObject(sTID("replaceLayer"), cTID('Plc '), desc3);
    executeAction(cTID('Plc '), desc1, dialogMode);
  };

  // Close
  function step5(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putBoolean(sTID("maximizeCompatibility"), false);
    desc1.putObject(cTID('As  '), sTID("largeDocumentFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 122);
    desc1.putBoolean(cTID('LwCs'), true);
    desc1.putEnumerated(cTID('Svng'), cTID('YsN '), cTID('Ys  '));
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  // Save
  function step6(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putEnumerated(cTID('BytO'), cTID('Pltf'), sTID("IBMPC"));
    desc2.putEnumerated(sTID("layerCompression"), cTID('Encd'), sTID("RLE"));
    desc1.putObject(cTID('As  '), sTID("TIFFFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.OUTPUT_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 117);
    desc1.putBoolean(cTID('LwCs'), true);
    executeAction(cTID('save'), desc1, dialogMode);
  };

  // Close
  function step7(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 117);
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  step1();      // Open
  step2();      // Select
  step3();      // Edit Contents
  step4();      // Place
  step5();      // Close
  step6();      // Save
  step7();      // Close
};



//=========================================
//                    tshirt3.main
//=========================================
//

tshirt3.main = function () {
  tshirt3();
};

tshirt3.main();

// EOF

"tshirt3.jsx"
// EOF
`

export const pantsScript = `#target photoshop
//
// pants.jsx
//

//
// Generated Sun Oct 13 2024 20:39:33 GMT+0700
//

cTID = function(s) { return app.charIDToTypeID(s); };
sTID = function(s) { return app.stringIDToTypeID(s); };

//
//==================== pants ==============
//
function pants() {
  // Open
  function step1(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putBoolean(sTID("dontRecord"), false);
    desc1.putBoolean(sTID("forceNotify"), true);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 332);
    desc1.putBoolean(sTID("template"), false);
    executeAction(cTID('Opn '), desc1, dialogMode);
  };

  // Edit Contents
  function step2(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 332);
    desc1.putInteger(cTID('LyrI'), 7);
    executeAction(sTID('placedLayerEditContents'), desc1, dialogMode);
  };

  // Place
  function step3(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('Idnt'), 11);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.ARTWORK_FILE_PATH_1}}"));
    desc1.putEnumerated(cTID('FTcs'), cTID('QCSt'), sTID("QCSAverage"));
    var desc2 = new ActionDescriptor();
    desc2.putUnitDouble(cTID('Hrzn'), cTID('#Pxl'), 0);
    desc2.putUnitDouble(cTID('Vrtc'), cTID('#Pxl'), 0);
    desc1.putObject(cTID('Ofst'), cTID('Ofst'), desc2);
    var desc3 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putIdentifier(cTID('Lyr '), 9);
    desc3.putReference(cTID('From'), ref1);
    var ref2 = new ActionReference();
    ref2.putIdentifier(cTID('Lyr '), 11);
    desc3.putReference(cTID('T   '), ref2);
    desc1.putObject(sTID("replaceLayer"), cTID('Plc '), desc3);
    executeAction(cTID('Plc '), desc1, dialogMode);
  };

  // Close
  function step4(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putBoolean(sTID("maximizeCompatibility"), false);
    desc1.putObject(cTID('As  '), sTID("largeDocumentFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 337);
    desc1.putBoolean(cTID('LwCs'), true);
    desc1.putEnumerated(cTID('Svng'), cTID('YsN '), cTID('Ys  '));
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  // Select
  function step5(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putName(cTID('Lyr '), "layer_1");
    desc1.putReference(cTID('null'), ref1);
    desc1.putBoolean(cTID('MkVs'), false);
    var list1 = new ActionList();
    list1.putInteger(9);
    desc1.putList(cTID('LyrI'), list1);
    executeAction(cTID('slct'), desc1, dialogMode);
  };

  // Edit Contents
  function step6(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 332);
    desc1.putInteger(cTID('LyrI'), 9);
    executeAction(sTID('placedLayerEditContents'), desc1, dialogMode);
  };

  // Place
  function step7(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('Idnt'), 13);
    desc1.putPath(cTID('null'), new File("~/Desktop/{{.ARTWORK_FILE_PATH_2}}"));
    desc1.putEnumerated(cTID('FTcs'), cTID('QCSt'), sTID("QCSAverage"));
    var desc2 = new ActionDescriptor();
    desc2.putUnitDouble(cTID('Hrzn'), cTID('#Pxl'), 0);
    desc2.putUnitDouble(cTID('Vrtc'), cTID('#Pxl'), 0);
    desc1.putObject(cTID('Ofst'), cTID('Ofst'), desc2);
    var desc3 = new ActionDescriptor();
    var ref1 = new ActionReference();
    ref1.putIdentifier(cTID('Lyr '), 11);
    desc3.putReference(cTID('From'), ref1);
    var ref2 = new ActionReference();
    ref2.putIdentifier(cTID('Lyr '), 13);
    desc3.putReference(cTID('T   '), ref2);
    desc1.putObject(sTID("replaceLayer"), cTID('Plc '), desc3);
    executeAction(cTID('Plc '), desc1, dialogMode);
  };

  // Close
  function step8(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putBoolean(sTID("maximizeCompatibility"), false);
    desc1.putObject(cTID('As  '), sTID("largeDocumentFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.PSD_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 348);
    desc1.putBoolean(cTID('LwCs'), true);
    desc1.putEnumerated(cTID('Svng'), cTID('YsN '), cTID('Ys  '));
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  // Save
  function step9(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    var desc2 = new ActionDescriptor();
    desc2.putEnumerated(cTID('BytO'), cTID('Pltf'), sTID("IBMPC"));
    desc2.putEnumerated(sTID("layerCompression"), cTID('Encd'), sTID("RLE"));
    desc1.putObject(cTID('As  '), sTID("TIFFFormat"), desc2);
    desc1.putPath(cTID('In  '), new File("~/Desktop/{{.OUTPUT_FILE_PATH}}"));
    desc1.putInteger(cTID('DocI'), 332);
    desc1.putBoolean(cTID('LwCs'), true);
    executeAction(cTID('save'), desc1, dialogMode);
  };

  // Close
  function step10(enabled, withDialog) {
    if (enabled != undefined && !enabled)
      return;
    var dialogMode = (withDialog ? DialogModes.ALL : DialogModes.NO);
    var desc1 = new ActionDescriptor();
    desc1.putInteger(cTID('DocI'), 332);
    desc1.putBoolean(sTID("forceNotify"), true);
    executeAction(cTID('Cls '), desc1, dialogMode);
  };

  step1();      // Open
  step2();      // Edit Contents
  step3();      // Place
  step4();      // Close
  step5();      // Select
  step6();      // Edit Contents
  step7();      // Place
  step8();      // Close
  step9();      // Save
  step10();      // Close
};



//=========================================
//                    pants.main
//=========================================
//

pants.main = function () {
  pants();
};

pants.main();

// EOF

"pants.jsx"
// EOF
`
