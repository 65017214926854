import { useEffect } from 'react';
import Client from './api-client';
import { useUserStore } from './User.Store';
import { usePrintJobStore } from './PrintJob.Store';
import { useOrderStore } from './Order.Store';
import { useProductStore } from './Product.Store';
import { useDesignStore } from './Design.Store';
import { useInstaceStore } from './Instance.Store';
import { useShopStore } from './Shop.Store';
import { useStickerStore } from './Sticker.Store';
import { usePaymentStore } from './Payment.Store';
import RequestUtils from './api-client/Request.utils';

// default to 'http://localhost:3000'
// Client.setHost('http://localhost:3000');
Client.setHost('https://msc.personify.tech');

if (process.env.NODE_ENV !== 'development') {
  if (window.location.hostname.includes('dev.msc.personify.tech')) {
    Client.setHost('https://dev.msc.personify.tech');
  } else {
    Client.setHost('https://msc.personify.tech');
  }
}

export const Store = {
  Client,
  Api: Client.Api,
  useUserStore,
  usePrintJobStore,
  useOrderStore,
  useProductStore,
  useDesignStore,
  useInstaceStore,
  useShopStore,
  useStickerStore,
  usePaymentStore,
}

// @ts-ignore
window.PMApi = Client.Api;
export const Api = Client.Api;

export default Store;

export const Request = RequestUtils;
