import { Col, Text, Button, useUIState, Row } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';

const EtsyConnectCallback: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { code }: any = route.params || {};
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue();
  const [{ loading }, setUI] = useUIState();
  const isMobile = rV({ xs: true, md: false });
  const [errorMes, setErrorMes] = useState('');
  const [needLogin, setNeedLogin] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setUI({ loading: true });
    UserStore.onReady().then(async (u) => {
      setUI({ loading: false });
      if (!u) {
        setNeedLogin(true);
      } else if (u.role === 'admin') {
        setNeedLogin(true);
      } else {
        const savedCodeVerifier = localStorage.getItem('BG_EtsyCodeVerifier');
        const etsyShopName = localStorage.getItem('BG_EtsyShopName');
        const res = await Api.Shop.etsyGetToken({
          store_name: etsyShopName,
          code,
          code_verifier: savedCodeVerifier,
          redirect_uri: `${window.location.origin}/etsy-connect-callback`,
        })
        console.log("etsyGetToken", res.data);
        if (res.data.success && res.data.data) {
          setSuccess(true);
          if (!res.data.data?.id) return;
          setTimeout(() => {
            reset(SCREEN.UpsertStore, { id: res.data.data?.id })
          }, 2000);
        } else if (res.data.error) {
          setErrorMes(res.data.error);
        }
      }
    });
  }, [code]);

  return (
    <Col flex1 middle={!isMobile} p2>
      {errorMes ? (
        <>
          <Text center>{errorMes}</Text>
        </>
      ) : needLogin ? (
        <>
          <Text center>Click to login or register and finish store connection process</Text>
          <Row>
            <Button
              solid
              text='Login'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              mr1
              onPress={() => {
                navigate(SCREEN.Login, { redirect: SCREEN.ClaimMyStore })
              }}
            />
            <Button
              solid
              text='Register'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              onPress={() => {
                navigate(SCREEN.ResellerRegister, { redirect: SCREEN.ClaimMyStore })
              }}
            />
          </Row>
        </>
      ) : loading ? (
        <ActivityIndicator size='large' color={COLOR.MAIN} />
      ) : success ? (
        <>
          <Text center mb1>You have successfully connected new store!</Text>
          <Text center>You will be redirected shortly...</Text>
        </>
      ) : null}
    </Col>
  )
};

EtsyConnectCallback.routeInfo = {
  title: 'Connect Etsy store - MSupply - Print Manager',
  path: '/etsy-connect-callback',
};

export default EtsyConnectCallback;
