import { Col, Text, CMSLayout, Grid, Input, Button, useUIState, TouchField, Row } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store, { Api } from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { SVG, ASSETS } from 'assets';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator, Image } from 'react-native';

const ClaimMyStore: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { storeId, resellerId } : any = route.params || {};
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue();
  const [{ loading }, setUI] = useUIState();
  const isMobile = rV({ xs: true, md: false });
  const [errorMes, setErrorMes] = useState('');
  const [needLogin, setNeedLogin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alreadyClaimedByAnotherReseller, setAlreadyClaimedByAnotherReseller] = useState(false);

  useEffect(() => {
    let myStoreId = storeId;
    let storeResellerId = resellerId;
    const saved = localStorage.getItem('ClaimMyStore');
    if ((!myStoreId || myStoreId === 'undefined') && !!saved) {
      myStoreId = saved;
    }
    const savedResellerId = localStorage.getItem('ClaimMyStore_ResellerId');
    if ((!resellerId || resellerId === 'undefined') && !!savedResellerId) {
      storeResellerId = savedResellerId;
    }
    setUI({ loading: true });
    UserStore.onReady().then((u) => {
      setUI({ loading: false });
      if (!u) {
        localStorage.setItem('ClaimMyStore', storeId);
        localStorage.setItem('ClaimMyStore_ResellerId', resellerId);
        setNeedLogin(true);
      } else if (u.role === 'admin') {
        localStorage.clear();
        localStorage.setItem('ClaimMyStore', storeId);
        localStorage.setItem('ClaimMyStore_ResellerId', resellerId);
        setNeedLogin(true);
      } else {

        if (storeResellerId !== 'await-claim' && storeResellerId !== UserStore.getResellerId(u)) {
          setAlreadyClaimedByAnotherReseller(true);
          setErrorMes('This store is already connected and claimed by another reseller. Please connect another store or contact us for more information.');
          return;
        }

        Api.Shop.shopifyAppClaimMyStore({
          storeId: myStoreId,
        })
        .then(res => {
          if (res.data.success && res.data.data) {
            const storeReseller = res.data.data.resellerId;
            if (u.id !== storeReseller) {
              // alreayd claimed and not by me
              localStorage.clear();
              setNeedLogin(true);
              return;
            } else {
              setSuccess(true);
              setTimeout(() => {
                reset(SCREEN.UpsertStore, { id: myStoreId })
              }, 3000);
            }
          } else if (res.data.error) {
            setErrorMes(res.data.error);
          }
        })
        .catch(err => {
          setErrorMes(String(err));
        })
      }
    });
  }, [storeId, resellerId]);

  return (
    <Col flex1 middle={!isMobile} p2>
      {errorMes ? (
        <>
          <Text center>{errorMes}</Text>
          {alreadyClaimedByAnotherReseller && (
            <Row>
              <Button
                solid
                text='Connect new store'
                width={150}
                height={40}
                borderRadius={10}
                mt1
                mr1
                onPress={() => {
                  window.location.href = 'https://apps.shopify.com/bottled-goose-print-on-demand';
                }}
              />
              <Button
                solid
                text='Dashboard Homepage'
                width={150}
                height={40}
                borderRadius={10}
                mt1
                onPress={() => {
                  reset(SCREEN.Home)
                }}
              />
            </Row>
          )}
        </>
      ) : needLogin ? (
        <>
          <Text center>Click to login or register and finish store connection process</Text>
          <Row>
            <Button
              solid
              text='Login'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              mr1
              onPress={() => {
                navigate(SCREEN.Login, { redirect: SCREEN.ClaimMyStore })
              }}
            />
            <Button
              solid
              text='Register'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              onPress={() => {
                navigate(SCREEN.ResellerRegister, { redirect: SCREEN.ClaimMyStore })
              }}
            />
          </Row>
          {/* <Button
            solid
            text='Login'
            width={300}
            height={40}
            borderRadius={10}
            mt1
            onPress={() => {
              navigate(SCREEN.Login, { redirect: SCREEN.ClaimMyStore })
            }}
          /> */}
        </>
      ) : loading ? (
        <ActivityIndicator size='large' color={COLOR.MAIN} />
      ) : success ? (
        <>
          <Text center mb1>You have successfully connected new store!</Text>
          <Text center>You will be redirected shortly...</Text>
        </>
      ) : null}
    </Col>
  )
};

ClaimMyStore.routeInfo = {
  title: 'Claim my store - MSupply - Print Manager',
  path: '/claim-my-stores/:storeId',
};

export default ClaimMyStore;
