import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, ResellerPrintJobItem, SwapIconEffect, modal, showPopupMessage } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';

import PaymentPopup from './PaymentPopup';

const ResellerPrintJobs: IScreen = () => {
  const { navigation } = useNavFunc();
  const UserStore = Store.useUserStore();
  const PrintJobStore = Store.usePrintJobStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobs, setJobs] = useState<Array<TPrintJob>>([]);

  const getData = async (p) => {
    try {
      setUI({ fetching: true, errorMes: '' });
      await TimeHelper.wait(300);
      const { list, hasNext, total, error } = await PrintJobStore.getList(p, null);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPagination({ hasNext, total });
      setJobs(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    return unsubscribe;
  }, []);

  const showPaymentPopup = async (val: TPrintJob) => {
    modal.show(
      <PaymentPopup printJob={val} />
    )
  };

  const renderJobList = () => {
    if (jobs.length === 0) {
      return (
        <Col flex1>
          <Text>No jobs found</Text>
        </Col>
      )
    }
    return (
      <Col flex1>
        <Row
          height={50} stretch
          borderBottomColor={COLOR.GREY_BG}
          borderBottomWidth={1}
        >
          {/* <Col flex1 m1>
            <Text color={COLOR.GREY}>Client</Text>
          </Col> */}
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Product</Text>
          </Col>
          {/* <Col flex1 m1>
            <Text color={COLOR.GREY}>Preview</Text>
          </Col> */}
          <Col width={50} m1>
            <Text color={COLOR.GREY}>QTY</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Date</Text>
          </Col>
          <Col flex1 m1>
            <Text color={COLOR.GREY}>Paid?</Text>
          </Col>
          {/* <Col flex1 m1>
            <Text color={COLOR.GREY}>Production Notes</Text>
          </Col> */}
          {/* <Col flex1 m1>
            <Text color={COLOR.GREY}>PDF</Text>
          </Col> */}
          <Col width={150} m1 />
        </Row>
        <Col>
          {jobs.map((val, i) => {
            return (
              <ResellerPrintJobItem
                key={val.id}
                data={val}
                onPay={() => showPaymentPopup(val)}
                onDataUpdate={(newData) => {
                  const list = jobs.slice();
                  list[i] = newData;
                  setJobs(list);
                }}
                onDelete={async () => {
                  const res = await Store.Api.PrintJob.remove({
                    id: val.id
                  });
                  if (res.data.error) {
                    showPopupMessage({
                      title: '',
                      content: String(res.data.error),
                      buttonOkText: 'OK',
                      
                      typeHighlight: 'danger',
                      contentHighlight: 'Error'
                
                    });
                    // alert(res.data.error)
                  } else {
                    const newJobs = jobs.filter(j => j.id !== val.id);
                    setJobs(newJobs);
                  }
                }}
              />
            )
          })}
        </Col>
      </Col>
    )
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Text h3 >Jobs</Text>
        {/* <Button
          isLoading={btnLoading}
          text="Add dummy job"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            setUI({ loading: true });
            await Store.Api.PrintJob.addNewRandom({ number: 5 });
            await getData(1);
            setUI({ loading: false });
          }}
        /> */}
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Col>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
              <Row height={50} stretch mt2>
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
                <ShimmerLoading round1 flex={1} m1 />
              </Row>
            </Col>
            
          ) : (
            renderJobList()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

ResellerPrintJobs.routeInfo = {
  title: 'My Print Bag - MSupply',
  path: '/my-print-bag',
};

export default ResellerPrintJobs;
