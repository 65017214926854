import RequestUtils from "store/api-client/Request.utils";
import { MICRO_SERVICES_HOST, ENV } from "store/Order.Store";

export const requestMicroApi = async (url: string, data?: { method?: string, body?: any, headers?: any }) => {
  const res = await fetch(`${MICRO_SERVICES_HOST}${url}`, {
    method: data?.method || "get",
    headers: {
      ...(data?.headers || {}),
      "Authorization": RequestUtils.getToken(),
      "Content-Type": "application/json",
      "X-Auth-Token": "P8UeTt92HS98",
      "env": ENV,
    },
    body: data?.body,
  });
  return res.json();
}
