export * from './screens';
export * from './taxes';

export const FONT = {
  defaultFont: 'Inter, sans-serif',
  bold: 'Inter, sans-serif',
  light: 'Inter, sans-serif',
};

export const COLOR = {
  FONT: 'black',
  // MAIN: '#003846',
  MAIN: '#32abe2',
  BORDER: '#707070',
  BLUE: '#005EB8',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#49D009',
  GREY: '#B9B9B9',
  RED: 'red',
  GREY_LIGHT: '#F0F4F6',
  WHITE: '#FFF',
  BLACK: '#000',
}

export const COLORS = {
  BLUE: '#32abe2',
  BLUE_LIGHT: '#3366FF',
  NEUTRAL: '#474D66',
  HEADLINES: '#101840',
  TEXT_MUTED: '#696F8C',
  ICON_MUTED: '#C1C4D6',
  SEPARATORS: '#E6E8F0',
  BG: '#F4F6FA',
  BG_LIGHTER: '#FAFBFF',
  TEXT: '#474D66',
  TEXT_GREY: '#8D8D8D',
  ICON: '#8F95B2',
  BORDER: '#D8DAE5',
  BORDER2: '#E8E8E8',
  BG_DARKER: '#EDEFF5',
  BG_LIGHT: '#F9FAFC',
  BG_WHITE: '#FFFFFF',
  RED: '#D14343',
  GREEN: '#52BD94',
  BG_GREY: '#F2F2F2',
  LIGHT_GREY: 'rgba(232, 232, 232, 0.2)',
}

export const INCH_TO_MM = 25.4;
export * from './countries';
