import Request from '../Request.utils'
export interface ICreateSetupIntentRequestBody {
  createdIfNotExisted?: boolean;
}
export interface IGetChargeAmountFromLineItemsRequestBody {
  orders?: any[];
}
export interface IGetChargeAmountWithoutInvoiceRequestBody {
  address?: any;
  discounted?: boolean;
  products?: any[];
}
export interface IChargeFromWalletRequestBody {
  orders?: {
    line_items?: any[];
    orderId?: string;
    shipping_address?: any;
    slug?: string;
  }[];
  shippingService?: string;
}
export interface IChargeByStripeInvoiceRequestBody {
  invoiceIds?: string[];
  shippingService?: string;
  useSampleToken?: boolean;
}
export interface IRequestWithdrawFundRequestBody {
  amount?: number;
  currency?: string;
}
export interface IStripeCreateDepositRequestBody {
  amount?: number;
  currency?: string;
  paymentMethodId?: string;
}
export interface IMarkDepositCompleteRequestBody {
  amount?: number;
  currency?: string;
  fulfillAmount?: number;
  payment_intent_id?: string;
}
export interface IApproveRefundRequestBody {
  id: string;
}
export interface IGetOrderInvoiceRequestParams {
  orderId: string;
}
export interface IGetBalanceTransactionsRequestQuery {
  starting_after?: string;
}
export interface IGetInvoiceRequestQuery {
  orderNumber: string;
}
export interface IAdminGetBalanceTransactionsOfAResellerRequestParams {
  resellerId?: string;
}
export interface IAdminGetBalanceTransactionsOfAResellerRequestQuery {
  starting_after?: string;
}
export interface IAdminMarkDepositCompleteRequestBody {
  amount?: number;
  currency?: string;
  fulfillAmount?: number;
  payment_intent_id?: string;
  resellerId?: string;
}
export interface IAdminAddBalanceToResellerWalletRequestBody {
  amount?: number;
  currency?: string;
  depositMessage?: string;
  resellerId?: string;
}


class PaymentAPI {
  publicKey = async () => {
    const res = await Request.call('/api/payment/public-key', 'GET', undefined, undefined, undefined, );
    return res;
  }
  createSetupIntent = async (body: ICreateSetupIntentRequestBody) => {
    const res = await Request.call('/api/payment/create-setup-intent', 'POST', undefined, undefined, body, );
    return res;
  }
  listCard = async () => {
    const res = await Request.call('/api/payment/cards', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getBalance = async () => {
    const res = await Request.call('/api/payment/get-balance', 'GET', undefined, undefined, undefined, );
    return res;
  }
  removeCard = async () => {
    const res = await Request.call('/api/payment/remove-card', 'POST', undefined, undefined, undefined, );
    return res;
  }
  listCurrencies = async () => {
    const res = await Request.call('/api/payment/list-currencies', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getChargeAmountFromLineItems = async (body: IGetChargeAmountFromLineItemsRequestBody) => {
    const res = await Request.call('/api/payment/get-charge-amount', 'POST', undefined, undefined, body, );
    return res;
  }
  getChargeAmountWithoutInvoice = async (body: IGetChargeAmountWithoutInvoiceRequestBody) => {
    const res = await Request.call('/api/payment/get-charge-amount-without-invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  chargeFromWallet = async (body: IChargeFromWalletRequestBody) => {
    const res = await Request.call('/api/payment/charge-from-wallet', 'POST', undefined, undefined, body, );
    return res;
  }
  chargeByStripeInvoice = async (body: IChargeByStripeInvoiceRequestBody) => {
    const res = await Request.call('/api/payment/charge-from-wallet-by-stripe-invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  requestWithdrawFund = async (body: IRequestWithdrawFundRequestBody) => {
    const res = await Request.call('/api/payment/request-withdraw-fund', 'POST', undefined, undefined, body, );
    return res;
  }
  stripeCreateDeposit = async (body: IStripeCreateDepositRequestBody) => {
    const res = await Request.call('/api/payment/create-deposit', 'POST', undefined, undefined, body, );
    return res;
  }
  markDepositComplete = async (body: IMarkDepositCompleteRequestBody) => {
    const res = await Request.call('/api/payment/mark-deposit-complete', 'POST', undefined, undefined, body, );
    return res;
  }
  approveRefund = async (body: IApproveRefundRequestBody) => {
    const res = await Request.call('/api/payment/approve-refund', 'POST', undefined, undefined, body, );
    return res;
  }
  getOrderInvoice = async (params: IGetOrderInvoiceRequestParams) => {
    const res = await Request.call('/api/payment/get-order-invoice/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
  getBalanceTransactions = async (query: IGetBalanceTransactionsRequestQuery) => {
    const res = await Request.call('/api/payment/get-balance-transactions', 'GET', undefined, query, undefined, );
    return res;
  }
  getInvoice = async (query: IGetInvoiceRequestQuery) => {
    const res = await Request.call('/api/payment/get-invoice', 'GET', undefined, query, undefined, );
    return res;
  }
  adminGetBalanceTransactionsOfAReseller = async (params: IAdminGetBalanceTransactionsOfAResellerRequestParams, query: IAdminGetBalanceTransactionsOfAResellerRequestQuery) => {
    const res = await Request.call('/api/payment/admin-get-balance-transactions/:resellerId', 'GET', params, query, undefined, );
    return res;
  }
  adminMarkDepositComplete = async (body: IAdminMarkDepositCompleteRequestBody) => {
    const res = await Request.call('/api/payment/admin-mark-deposit-complete', 'POST', undefined, undefined, body, );
    return res;
  }
  adminAddBalanceToResellerWallet = async (body: IAdminAddBalanceToResellerWalletRequestBody) => {
    const res = await Request.call('/api/payment/admin-add-balance-to-reseller-wallet', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new PaymentAPI()