import React, { useRef, useState, useCallback } from "react";
import { Col, Row, Text } from "components/base";
import { IColProps } from "components/base/Col";
import { COLOR, COLORS } from "const";
import { set } from "lodash";
import MakeEditAreaModal from "screens/products/MakeEditAreaModal";
import { Button, InputField, modal, PreviewModal, showPopupMessage, TouchField, UploadField } from "components";
import { EvilIcons } from "@expo/vector-icons";

export interface ITableFormProps extends IColProps {
  onChange?: (data: any[]) => void,
  data?: any[],
}

const ExampleLink = ({ onPress }: { onPress: () => void }) => {
  return (
    <Text
      // @ts-ignore
      style={{ cursor: "pointer", color: COLORS.BLUE, textDecorationLine: "underline" }}
      onClick={onPress}
    >
      Example
    </Text>
  )
}

const TablePrintArea = (props: ITableFormProps) => {
  const { data, onChange, ...restProps } = props;
  const previewModalRef = useRef<any>(null);

  const onAddRow = () => {
    onChange([...data, {}]);
  }

  const onRemove = (i) => {
    const arr = [...data];
    arr.splice(i, 1);
    onChange(arr);
    onChange?.(arr);
  }

  const onChangeRow = (value, key, idx) => {
    const arr = [...data];
    set(arr, `[${idx}].${key}`, value);
    onChange?.(arr);
  }

  const showErrorPopup = (message: string) => {
    showPopupMessage({
      title: '',
      content: message,
      buttonOkText: 'OK',

      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
  }

  const onPressCreateImageWithArea = useCallback((item: any, idx: number) => {
    const editAreaWidth = item?.width;
    const editAreaHeight = item?.height;
    const editorBgImg = item?.image;
    // if (!editAreaWidth || !editAreaHeight) {
    //   return showErrorPopup("Please fill in edit area width and height first");
    // }
    const bothDefined = !!editAreaWidth && !!editAreaHeight;
    if (!editorBgImg) {
      return showErrorPopup("Please upload product image first");
    }
    modal.show(
      <MakeEditAreaModal
        image={editorBgImg}
        width={bothDefined ? editAreaWidth : 1000}
        height={bothDefined ? editAreaHeight : 1000}
        onSave={(data) => {
          modal.hide()
          let finalWidth = data.width;
          let finalHeight = data.height;
          // If width was pre-defined, keep it and adjust height based on ratio
          if (editAreaWidth) {
            finalWidth = Number(editAreaWidth);
            finalHeight = (finalWidth / data.width) * data.height;
          }
          // If height was pre-defined, keep it and adjust width based on ratio 
          else if (editAreaHeight) {
            finalHeight = Number(editAreaHeight);
            finalWidth = (finalHeight / data.height) * data.width;
          }
          onChangeRow(data.url, 'mask', idx)
          onChangeRow(Math.round(finalWidth), 'width', idx)
          onChangeRow(Math.round(finalHeight), 'height', idx)
        }}
      />
    )
  }, [])

  return (
    <Col {...restProps}>
      {data.map((i, idx) => (
        <Col paddingTop={8} marginBottom={8} key={`col${idx}`} borderTopWidth={1} borderColor={COLORS.BORDER}>
          <Row justifyContent="space-between">
            <Text fontWeight="500" fontSize={14} marginBottom={16}>Page {idx + 1}</Text>
            <TouchField width={38} height={38} middle onPress={() => onRemove(idx)}>
              <EvilIcons name="trash" size={20} color={COLOR.RED} />
            </TouchField>
          </Row>
          <InputField
            title="Label"
            value={i?.label}
            onChangeText={(v) => {
              onChangeRow(v, 'label', idx)
            }}
            marginBottom={16}
          />
          <UploadField
            title="Product image"
            isInput
            value={i?.image}
            onChange={(data: string) => {
              onChangeRow(data, 'image', idx);
            }}
            right={(
              <ExampleLink onPress={() => {
                previewModalRef.current?.show("https://print-manager-media.s3.eu-west-1.amazonaws.com/files/1731743388711-Fire_short_background.png");
              }} />
            )}
            marginBottom={16}
          />
          <Row marginBottom={16}>
            <Col flex1>
              <InputField
                title="Edit area width (mm)"
                value={i?.width}
                onChangeText={(v) => {
                  onChangeRow(v, 'width', idx)
                }}
              />
            </Col>
            <Col width={16} />
            <Col flex1>
              <InputField
                title="Edit area height (mm)"
                value={i?.height}
                onChangeText={(v) => {
                  onChangeRow(v, 'height', idx)
                }}
              />
            </Col>
          </Row>
          <UploadField
            title="Product image with editable area"
            isInput
            value={i?.mask}
            onChange={(data: string) => {
              onChangeRow(data, 'mask', idx)
            }}
            right={(
              <ExampleLink onPress={() => {
                previewModalRef.current?.show("https://print-manager-media.s3.eu-west-1.amazonaws.com/files/1731743348236-Fire_short_mask.png");
              }} />
            )}
            extraButton={
              <Button
                mr1
                text="Create"
                height={30}
                borderRadius={15}
                width={100}
                onPress={() => onPressCreateImageWithArea(i, idx)}
              />
            }
          />
        </Col>
      ))}
      <Button
        mt2
        text={"Add page"}
        outline
        bgHovered={COLOR.GREY_BG}
        onPress={onAddRow}
      />
      <PreviewModal ref={previewModalRef} />
    </Col>
  );
};

export default TablePrintArea;
