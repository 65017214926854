import React, { useState } from 'react';
import { Row, Text, Col, Button } from 'components';
import { COLOR } from 'const';

const TABS = [
  { title: 'Client Information', key: 'info' },
  { title: 'Client Design', key: 'designs' },
  // { title: 'Transactions', key: 'transactions' },
];


export const useDesignTabs = () => {
  const [tab, setTab] = useState<'info' | 'designs' | 'transactions'>('info');
  return [tab, setTab];
}

export const UpsertResellerTabs = ({ activeTab, onChangeTab, ...props }) => {
  return (
    <Row {...props}>
      {TABS.map((v, vI) => {
        const isActive = !activeTab ? false : activeTab === v.key || activeTab?.toLowerCase() === v.key?.toLowerCase();
        return (
          <Button
            key={v.key}
            solid={isActive}
            outline={!isActive}
            width={130}
            height={30}
            text={v.title}
            mr1
            onPress={() => {
              if (!isActive) onChangeTab(v.key)
            }}
            {...(!isActive ? {
              backgroundColor: '#D1D1D1',
              borderColor: '#D1D1D1',
              bgHovered: COLOR.GREY,
            } : {})}
          />
        )
      })}
    </Row>
  )
};