import React, { useEffect, useRef, useState } from "react";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  useUIState,
  Grid,
  useRefState,
  UploadFile,
  showPopupMessage,
  Select,
} from "components";
import { useNavFunc } from "navigation";
import { COLOR, SCREEN } from "const";
import Store from "store";
import ProfileSettings from "./Settings.Profile";
import BrandsSettings from "./Settings.Brands";
import BillingSettings from "./Settings.Billing";
import UsersSettings from "./Settings.Users";
import EmailsSettings from "./Settings.Emails";
import StickersSettings from "./Settings.Stickers";
import { AntDesign } from "@expo/vector-icons";
import InviteUsers from "../InviteUsers";
import { SettingsFormProvider, useSettingsForm } from "./FormContext";
import { modalConfirm } from "helpers";
import { useDynamicResponsiveValue } from "quickly-react";
import SettingsBillingNoPayment from "./Settings.BillingNoPayment";

const TABS = [
  "Profile",
  "Stores",
  "Billing information",
  // "Packing Slips",
  "Users",
  "Emails",
  // "Stickers",
];

const Settings = () => {
  const { navigation, route } = useNavFunc();
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false });
  const { tabId }: any = route.params || {};
  const billingRef = useRef<{ submit: Function, shouldCreateStripeCustomer: () => boolean }>(null);
  const stickerRef = useRef<{ getData: Function }>(null);
  const userRef = useRef<{ getData: Function }>(null);
  const UserStore = Store.useUserStore();
  const { user } = UserStore.useUser(
    UserStore.user?.id,
    false
  );
  const [resellerId, getResellerId, setResellerId] = useRefState('');
  useEffect(() => {
    setResellerId(UserStore.getResellerId(user))
  }, [user]);
  const [headerKey, setHeaderKey] = useState("");
  const [headerValue, setHeaderValue] = useState("");
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [imageId, getImageId, setImageId] = useRefState<
    "main" | "galleries" | "blueprint"
  >("main");
  const uploadRef = useRef<{ showDialog: Function }>(null);

  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();

  const [reloadSticker, setReloadSticker] = useState(false);
  const { setShowUpsertUserUI, checkIsChanged, onSaved } = useSettingsForm();

  const onImageUploaded = (urls) => {
    Store.Api.Sticker.upsert({ urls: urls });
    if (urls.length === 0) return setImageUI({ loading: false });
    stickerRef.current.getData()
    setImageUI({ loading: false });
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadListUser();
    });
    return unsubscribe;
  }, []);

  const loadListUser = () => {
    let tabIdx = 0;
    switch (tabId) {
      case "user":
        tabIdx = 4;
        break;
      case "store":
        tabIdx = 1;
        break;
      case "billing":
        tabIdx = 2;
        break;
      default:
        break;
    }
    setCurrentTab(tabIdx);
    onChangeTabs(tabIdx);
    userRef.current && userRef.current?.getData();
  }
  const submit = async () => {
    setSubmitUI({ loading: true });
    try {
      console.log(user);
      const res = await Store.Api.User.upsertReseller({
        id: user.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        password: user?.password,
        addressLine1: user?.addressLine1 || "",
        addressLine2: user?.addressLine2 || "",
        town: user?.town || "",
        country: user?.country || "",
        currency: user?.currency || "",
        postCode: user?.postCode || "",
        otherData: !!headerKey
          ? {
            ...user?.otherData,
            bgServices: {
              ...user?.otherData?.bgServices,
              authenHeaders: {
                [headerKey]: headerValue,
              },
            },
          }
          : user?.otherData,
      });
      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',

          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(res.data.error);
      } else if (res.data.data) {
        showPopupMessage({
          title: '',
          content: 'Reseller information successfully submitted.',
          buttonOkText: 'OK',

          typeHighlight: 'success',
          contentHighlight: 'Success'

        });
        // alert("Reseller information successfully submitted");
        // navigation.reset(SCREEN.UpsertReseller, { id: res.data.data.id });
        navigation.reset({
          index: 0,
          routes: [
            { name: SCREEN.UpsertReseller, params: { id: res.data.data.id } },
          ],
        });
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(String(err));
    }
    setSubmitUI({ loading: false });
  };

  const ButtonSave = React.memo(() => (
    <Button
      isLoading={submitting}
      text="Save"
      borderRadius={20}
      paddingVertical={6}
      paddingHorizontal={10}
      onPress={submit}
    />
  ));

  const ButtonSaveBilling = React.memo(() => {
    const { billingInformation, onSaved } = useSettingsForm();
    const [{ loading }, setSubmitUI] = useUIState();

    const saveBilling = async () => {
      if (
        !billingInformation.firstName ||
        !billingInformation.lastName ||
        !billingInformation.city ||
        !billingInformation.postcode ||
        !billingInformation.country
      ) {
        return showPopupMessage({
          title: '',
          content: 'Please fill in all required fields',
          buttonOkText: 'OK',
          typeHighlight: 'danger',
          contentHighlight: 'Error'
        });
      }
      try {
        setSubmitUI({ loading: true });
        await billingRef?.current?.submit();
        console.log('=>>> billing information', billingInformation);
        const { addressLine1, addressLine2, city, postcode, country, currency, accountName, firstName, lastName } = billingInformation;
        const res = await Store.Api.User.upsertReseller({
          id: getResellerId(),
          addressLine1,
          addressLine2,
          town: city,
          postCode: postcode,
          country,
          currency,
          accountName,
          firstName,
          lastName,
        });
        if (res.data.error) {
          showPopupMessage({
            title: '',
            content: String(res.data.error),
            buttonOkText: 'OK',

            typeHighlight: 'danger',
            contentHighlight: 'Error'

          });
          // alert(res.data.error);
        } else if (res.data.data) {
          onSaved("billing");
          showPopupMessage({
            title: '',
            content: 'Billing info successfully updated.',
            buttonOkText: 'OK',

            typeHighlight: 'success',
            contentHighlight: 'Success'

          });
          // alert("Billing info successfully updated");
        }
      } catch (e) {
      } finally {
        setSubmitUI({ loading: false });
      }
    }

    return (
      <Button
        isLoading={loading}
        text="Save"
        borderRadius={20}
        paddingVertical={6}
        paddingHorizontal={10}
        onPress={saveBilling}
      />
    )
  });

  const ButtonSaveProfile = React.memo(() => {
    const { profile, onSaved } = useSettingsForm();
    const [{ loading }, setSubmitUI] = useUIState();

    const saveProfile = async () => {
      setSubmitUI({ loading: true });
      try {
        const res = await Store.Api.User.updateProfile(profile);
        if (res.data.error) {
          showPopupMessage({
            title: '',
            content: String(res.data.error),
            buttonOkText: 'OK',

            typeHighlight: 'danger',
            contentHighlight: 'Error'

          });
          // alert(res.data.error);
        } else if (res.data.data) {
          onSaved("profile");
          UserStore.set({ user: res.data.data });
          showPopupMessage({
            title: '',
            content: 'User profile successfully updated.',
            buttonOkText: 'OK',

            typeHighlight: 'success',
            contentHighlight: 'Success'

          });
          // alert("User profile successfully updated");
        }
      } catch (err) {
        showPopupMessage({
          title: '',
          content: String(err),
          buttonOkText: 'OK',

          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(String(err));
      }
      setSubmitUI({ loading: false });
    }

    return (
      <Button
        isLoading={loading}
        text="Save"
        borderRadius={20}
        paddingVertical={6}
        paddingHorizontal={10}
        onPress={saveProfile}
      />
    )
  });

  const ButtonConnect = React.memo(() => (
    <Button
      iconLeft={<AntDesign name="link" size={15} color={COLOR.WHITE} />}
      isLoading={submitting}
      text="Connect more stores"
      borderRadius={20}
      paddingVertical={6}
      paddingHorizontal={10}
      onPress={onPressConnectMore}
      touchWidth={190}
      numberOfLineTitle={1}
    />
  ));

  const ButtonAddUser = React.memo(() => (
    <Row>
      {/* <Button
        isLoading={submitting}
        text="Add more users"
        borderRadius={20}
        paddingVertical={6}
        paddingHorizontal={10}
        onPress={onPressAddUsers}
        mr1
      /> */}
      <InviteUsers />
    </Row>
  ));

  const ButtonAddSticker = React.memo(() => (
    <Button
      isLoading={submitting}
      text="Upload new sticker"
      borderRadius={20}
      paddingVertical={6}
      paddingHorizontal={10}
      onPress={onPressAddSticker}
    />
  ));

  const [button, setButton] = useState(<ButtonSaveProfile />);
  const [content, setContent] = useState(<ProfileSettings />);

  useEffect(() => {
    if (!user) return;
    if (!user.otherData?.bgServices?.authenHeaders) return;
    const keys = Object.keys(user.otherData?.bgServices?.authenHeaders);
    setHeaderKey(keys[0]);
    setHeaderValue(user.otherData?.bgServices?.authenHeaders[keys[0]]);
  }, [user]);

  const onPressAddUsers = () => {
    // navigation.navigate(SCREEN.UpsertUsers, { id: "new" });
    setShowUpsertUserUI(true);
  };

  const onPressConnectMore = () => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: SCREEN.UpsertStore,
          params: { id: "new", storetype: "shopify" },
        },
      ],
    });
  };

  const onPressAddSticker = () => {
    setImageId("main");
    setImageUI({ loading: true });
    uploadRef.current.showDialog();
    setReloadSticker(!reloadSticker);
  };

  const checkChangedBeforeSwitch = () => new Promise((resolve) => {
    let currentType;
    switch (currentTab) {
      case 0:
        currentType = "profile";
        break;
      case 2:
        currentType = "billing";
        break;
      case 3:
        currentType = "packing";
        break;
      default:
        break;
    }
    if (!currentType) return resolve(true);
    if (checkIsChanged(currentType)) {
      modalConfirm({
        title: 'Warning',
        content: 'Are you sure you want to exit without saving?',
        onOk: () => {
          resolve(true);
          onSaved(currentType);
        },
        onCancel: () => {
          resolve(false);
        },
      })
    } else {
      resolve(true);
    }
  })

  const onChangeTabs = async (index: number) => {
    const canChange = await checkChangedBeforeSwitch();
    if (!canChange) return;

    setCurrentTab(index);

    switch (index) {
      case 0:
        setContent(<ProfileSettings />);
        setButton(<ButtonSaveProfile />);
        return;
      case 1:
        setContent(<BrandsSettings />);
        setButton(<ButtonConnect />);
        navigation.setParams({ tabId: 'store' });
        return;
      case 2:
        if (UserStore.shouldSkipPayment()) {
          setContent(<SettingsBillingNoPayment ref={billingRef} />);
        } else {
          setContent(<BillingSettings ref={billingRef} />);
        }
        setButton(<ButtonSaveBilling />);
        navigation.setParams({ tabId: 'billing' });
        return;
      case 3:
        setContent(<UsersSettings ref={userRef} />);
        setButton(<ButtonAddUser />);
        navigation.setParams({ tabId: 'user' });
        return;
      case 4:
        setContent(<EmailsSettings />);
        setButton(<ButtonSave />);
        return;
      case 5:
        setContent(<StickersSettings ref={stickerRef} />);
        setButton(<ButtonAddSticker />);
        return;
      default:
        setContent(<BrandsSettings />);
        setButton(<ButtonSave />);
        return;
    }
  };

  const tabOptions = TABS.map((item, index) => ({ label: item, value: index }));

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Row>
          <Text h3 marginRight={12}>
            Settings
          </Text>
          <>
            {!isMobile ? (
              TABS.map((item, index) => (
                <Col
                  onPress={() => onChangeTabs(index)}
                  marginLeft={8}
                  paddingHorizontal={12}
                  paddingVertical={6}
                  borderRadius={5}
                  backgroundColor={index === currentTab ? COLOR.MAIN : COLOR.GREY}
                >
                  <Text
                    fontSize={12}
                    color={index === currentTab ? COLOR.WHITE : COLOR.BLACK}
                  >
                    {item}
                  </Text>
                </Col>
              ))
            ) : null}
          </>
        </Row>
        <Row>{button}</Row>
      </Row>
      {isMobile && (
        <Col m2>
          <Select
            bgWhite noBorder
            round0
            options={tabOptions}
            value={tabOptions.find((v) => v.value === currentTab)}
            onChange={(data) => onChangeTabs(data.value)}
            placeholder="Select settings"
          />
        </Col>
      )}
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={"scroll"}>
        <Grid xs="100%" md="100%" alignItems={"flex-start"} mb2>
          <Col m1>{content}</Col>
        </Grid>
      </Col>
      <UploadFile ref={uploadRef} onUploaded={onImageUploaded} isMulti />
    </CMSLayout>
  );
};

const SettingsWithContext = (props) => {
  return (
    <SettingsFormProvider>
      <Settings {...props} />
    </SettingsFormProvider>
  )
}

SettingsWithContext.routeInfo = {
  title: "Settings",
  path: "/settings",
};

export default SettingsWithContext;
