import React from 'react';
import { Popover } from 'antd';
import { Col, Text, Row } from 'components';
import { Pressable, StyleSheet } from 'react-native';
import { ASSETS } from 'assets';
import { COLOR, COLORS } from 'const';
import { useDynamicResponsiveValue } from 'quickly-react';

const SupportContent = () => (
  <Col bgWhite borderWidth={1} borderColor={COLORS.BORDER2} borderRadius={4} margin={12} marginTop={20}>
    <Col padding={24}>
      <Text selectable fontSize={18} fontWeight={700} marginBottom={30}>
        Any trouble?
      </Text>
      <Text selectable marginBottom={30}>
        Don’t hesitate to ask for support!
      </Text>
      <Row marginBottom={30}>
        <img src={ASSETS.PHONE} style={{ width: 20, height: 20 }} />
        <Pressable
          //@ts-ignore
          style={{ outline: 'none' }}
          onPress={() => {
            window.open('tel:020 8941 3481');
          }}>
          <Text selectable style={styles.linkText}>
            020 8941 3481
          </Text>
        </Pressable>
      </Row>
      <Row>
        <img src={ASSETS.MAIL} style={{ width: 24, height: 24 }} />
        <Pressable
          //@ts-ignore
          style={{ outline: 'none' }}
          onPress={() => {
            window.open('mailto:hello@msupply.co.uk', '_blank');
          }}>
          <Text selectable style={styles.linkText}>
            hello@msupply.co.uk
          </Text>
        </Pressable>
      </Row>
    </Col>
  </Col>
);
const SupportContentSm = () => (
  <Col bgWhite borderRadius={4} margin={12}>
    <Text selectable fontSize={18} fontWeight={700} marginBottom={15}>
      Any trouble?
    </Text>
    <Text selectable marginBottom={15}>
      Don’t hesitate to ask for support!
    </Text>
    <Row marginBottom={15}>
      <img src={ASSETS.PHONE} style={{ width: 20, height: 20 }} />
      <Pressable
        //@ts-ignore
        style={{ outline: 'none' }}
        onPress={() => {
          window.open('tel:020 8941 3481');
        }}>
        <Text selectable style={styles.linkText}>
          020 8941 3481
        </Text>
      </Pressable>
    </Row>
    <Row>
      <img src={ASSETS.MAIL} style={{ width: 24, height: 24 }} />
      <Pressable

        //@ts-ignore
        style={{ outline: 'none' }}
        onPress={() => {
          window.open('mailto:hello@msupply.co.uk', '_blank');
        }}>
        <Text selectable style={styles.linkText}>
          hello@msupply.co.uk
        </Text>
      </Pressable>
    </Row>
  </Col>
);

const SupportContact = () => {
  const rV = useDynamicResponsiveValue();
  const hideContent = rV({ xs: true, xl: false });
  return (
    <>
      {!hideContent && <SupportContent />}
      {hideContent && (
        <div style={{ position: 'fixed', right: '20px', bottom: '20px', zIndex: 1000 }} >
          <Popover
            trigger='click'
            content={<SupportContentSm />}
          >
            <img src={ASSETS.QUESTIONMARK} style={{ width: 68, height: 68, cursor: 'pointer' }} />
          </Popover>
        </div>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  linkText: {
    color: COLOR.MAIN,
    marginLeft: 10,
  },
});

export default SupportContact;
