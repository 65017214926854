import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import { IScreen, TSyncedProduct } from 'type';
import { CMSLayout, Col, Row, Text, ShimmerLoading, useUIState, Grid, showPopupMessage, ImageGallery, InputField, SelectField, TextAreaField, useRefState, TagsField, UploadField, TableForm, ButtonOutline, Button, PreviewModal, modal } from 'components';
import { useNavFunc } from 'navigation';
import { COLORS, SCREEN } from 'const';
import Store from 'store';
import { ScrollView, useWindowDimensions } from 'react-native';
import { Button as AntButton, Switch } from 'antd';
import { useNavigationMiddleWare } from 'navigation/NavigationContext';
import apiClient from 'store/api-client';
import { uniq } from 'lodash';
import MakeEditAreaModal from './MakeEditAreaModal';
import TablePrintArea from './TablePrintArea';

const EDITOR_TYPES = [
  { label: 'Empty artboard', value: 'empty' },
  { label: 'On product Image', value: 'basic' },
  { label: 'Edit Area in Image', value: 'masked' },
  { label: 'Edit Area multiple pages', value: 'masked-multi' },
  { label: 'Thumbnails in bottom', value: 'thumbnails' },
]

const PREVIEW_TYPES = [
  { label: 'None', value: 'none' },
  { label: 'Flat', value: 'flat' },
  { label: 'Remove Edit Area', value: 'remove-edit-area' },
  { label: 'Photoshop script', value: 'pts' },
  { label: 'Blender render', value: 'blender' },
  { label: '3D render', value: '3d' },
]

const ExampleLink = ({ onPress }: { onPress: () => void }) => {
  return (
    <Text
      // @ts-ignore
      style={{ cursor: "pointer", color: COLORS.BLUE, textDecorationLine: "underline" }}
      onClick={onPress}
    >
      Example
    </Text>
  )
}

const SyncedProductDetail: IScreen = () => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { productId } = route.params || {};
  const previewModalRef = useRef<any>(null);
  const [product, getProduct, setProduct] = useRefState<TSyncedProduct>(null);
  const [error, setError] = useState("");
  const { updateEditting } = useNavigationMiddleWare();
  const [{ loading: saving }, setSavingUI] = useUIState();
  const { height } = useWindowDimensions();
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    if (productId) {
      setError("");
      apiClient.Api.SyncProduct.detailSyncedProducts({ id: productId }).then((res) => {
        if (res.data.success) {
          setProduct(res.data.data);
        } else {
          setError(res.data.message || "Something went wrong");
        }
      }).catch((err) => {
        setError(String(err));
      })
    }
  }, [productId])

  const updateProduct = (obj: any) => {
    setProduct(p => ({ ...p, ...obj }));
  }

  const updateProductData = (obj: any) => {
    setProduct(p => ({ ...p, data: { ...p?.data, ...obj } }));
  }

  const showErrorPopup = (message: string) => {
    showPopupMessage({
      title: '',
      content: message,
      buttonOkText: 'OK',

      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
  }

  const onSubmit = async () => {
    let hasError = false;
    const newProduct = getProduct();
    const editorType = newProduct?.data?.editorType;
    const previewType = newProduct?.data?.previewType;
    const isCustomizable = newProduct?.tags?.includes(`sp-${productId}`);

    if (isCustomizable) {
      if (!editorType) {
        return showErrorPopup("Please select editor type for customizable product");
      }
      if (editorType === 'empty' && (!newProduct?.data?.editorWidth || !newProduct?.data?.editorHeight)) {
        return showErrorPopup("Please fill in editor width and height");
      }
      if (editorType === 'masked' && (!newProduct?.data?.editorBgImg || !newProduct?.data?.editorMaskImg)) {
        return showErrorPopup("Please upload product image and product image with editable area");
      }
      if (editorType === 'thumbnails' && !newProduct?.data?.editorParts?.length) {
        return showErrorPopup("Please add at least one part for editor");
      }
      if (previewType === 'pts' && !newProduct?.data?.previewPsdUrl) {
        return showErrorPopup("Please upload PSD file for previewer");
      }
      if (previewType === '3d' && !newProduct?.data?.previewGlbUrl) {
        return showErrorPopup("Please upload 3D model file for previewer");
      }
    }
    setSavingUI({ loading: true });
    try {
      const params: any = { ...newProduct };
      delete params.createdAt;
      delete params.updatedAt;
      const res = await apiClient.Api.SyncProduct.upsertSyncedProducts(params);
      if (res.data?.error) {
        showErrorPopup(res.data.error);
      } else {
        showPopupMessage({
          title: '',
          content: "Update product successfully",
          buttonOkText: 'OK',
          // 
          typeHighlight: 'success',
          contentHighlight: 'Success'
        });
      }
      // updateEditting(screenName, false);
    } catch (err) {
      showErrorPopup(String(err))
      hasError = true;
    } finally {
      setSavingUI({ loading: false });
    }

    return hasError;
  }

  const showPublishProductModal = async () => {
    // const missingResalePrice = !design.resalePrice || design.resalePrice == 0;
    // const missingVariantPrice = design.variants?.some(i => !i.price || i?.price == 0);

    // if (missingResalePrice && !alreadyHasVariants) {
    //   modalConfirm({
    //     title: 'Warning',
    //     content: 'Please fill in Resale price',
    //     onOk: () => { },
    //     onCancel: () => { },
    //   });
    //   return;
    // }
    // if (alreadyHasVariants && missingVariantPrice) {
    //   modalConfirm({
    //     title: 'Warning',
    //     content: 'Please fill in price of all variants',
    //     onOk: () => { },
    //     onCancel: () => { },
    //   });
    //   return;
    // }
    // const hasError = await onSubmit(design);
    // if (hasError) return;
    // modal.show(
    //   <PublishProductModal
    //     design={design}
    //     setDesign={setDesign}
    //     cost={product?.price}
    //     beforePublish={async () => {
    //       await Store.Api.Design.upsert({
    //         id: designId,
    //         name: design.name,
    //         resalePrice: +design.resalePrice,
    //         data: design.data,
    //         brands: design.brands,
    //         description: design.description,
    //       });
    //     }}
    //     navigation={navigation}
    //   />
    // );
  }

  const showEditDesignModal = async () => {
    // const hasError = await onSubmit(design);
    // if (hasError) return;
    // modal.show(
    //   <EditDesignModal
    //     product={product}
    //     design={design}
    //     onDesignSave={async () => {
    //       const res = await Store.Api.Design.detail({ id: design.id });
    //       if (res.data.success) {
    //         setDesign(res.data.data);
    //         setGalleries({
    //           mainImage: res.data.data?.image,
    //           images: res.data.data?.galleries || [],
    //         })
    //       }
    //     }}
    //   />
    // );
  }

  const onPressTestEditor = async () => {
    setSavingUI({ loading: true });
    try {
      await apiClient.Api.SyncProduct.updateSyncedProductData({
        id: getProduct()?.id,
        data: getProduct()?.data,
      })
      window.open(`/shopify-editor/${productId}`, "_blank");
    } catch (error) {
      showErrorPopup(String(error));
    } finally {
      setSavingUI({ loading: false });
    }
  }

  const isValidEditorConfig = useMemo(() => {
    const editorType = product?.data?.editorType;
    const previewType = product?.data?.previewType;
    const isCustomizable = product?.tags?.includes(`sp-${productId}`);

    if (isCustomizable) {
      if (!editorType) {
        return false
      }
      if (editorType === 'empty' && (!product?.data?.editorWidth || !product?.data?.editorHeight)) {
        return false;
      }
      if (editorType === 'masked' && (!product?.data?.editorBgImg || !product?.data?.editorMaskImg)) {
        return false;
      }
      if (editorType === 'thumbnails' && !product?.data?.editorParts?.length) {
        return false;
      }
      if (previewType === 'pts' && !product?.data?.previewPsdUrl) {
        return false;
      }
      if (previewType === '3d' && !product?.data?.previewGlbUrl) {
        return false;
      }
      return true;
    }
    return false;
  }, [product])

  const onPressCreateImageWithArea = useCallback(() => {
    const editAreaWidth = product?.data?.editAreaWidth;
    const editAreaHeight = product?.data?.editAreaHeight;
    const editorBgImg = product?.data?.editorBgImg;
    // if (!editAreaWidth || !editAreaHeight) {
    //   return showErrorPopup("Please fill in edit area width and height first");
    // }
    const bothDefined = !!editAreaWidth && !!editAreaHeight;
    if (!editorBgImg) {
      return showErrorPopup("Please upload product image first");
    }
    modal.show(
      <MakeEditAreaModal
        image={editorBgImg}
        width={bothDefined ? editAreaWidth : 1000}
        height={bothDefined ? editAreaHeight : 1000}
        onSave={(data) => {
          modal.hide()
          let finalWidth = data.width;
          let finalHeight = data.height;
          // If width was pre-defined, keep it and adjust height based on ratio
          if (editAreaWidth) {
            finalWidth = Number(editAreaWidth);
            finalHeight = (finalWidth / data.width) * data.height;
          }
          // If height was pre-defined, keep it and adjust width based on ratio 
          else if (editAreaHeight) {
            finalHeight = Number(editAreaHeight);
            finalWidth = (finalHeight / data.height) * data.width;
          }
          updateProductData({
            editorMaskImg: data.url,
            editAreaWidth: Math.round(finalWidth),
            editAreaHeight: Math.round(finalHeight),
          })
        }}
      />
    )
  }, [product])

  const editorConfigs = useMemo(() => {
    if (product?.data?.editorType === 'masked') {
      return (
        <>
          <UploadField
            title="Product image"
            isInput
            value={product?.data?.editorBgImg}
            onChange={(data: string) => {
              updateProductData({
                editorBgImg: data,
              });
            }}
            right={(
              <ExampleLink onPress={() => {
                previewModalRef.current?.show("https://print-manager-media.s3.eu-west-1.amazonaws.com/files/1731743388711-Fire_short_background.png");
              }} />
            )}
            marginBottom={16}
          />
          <Row marginBottom={16}>
            <Col flex1>
              <InputField
                title="Edit area width (mm)"
                value={product?.data?.editAreaWidth}
                onChangeText={(v) => {
                  updateProductData({ editAreaWidth: v })
                }}
              />
            </Col>
            <Col width={16} />
            <Col flex1>
              <InputField
                title="Edit area height (mm)"
                value={product?.data?.editAreaHeight}
                onChangeText={(v) => {
                  updateProductData({ editAreaHeight: v })
                }}
              />
            </Col>
          </Row>
          <UploadField
            title="Product image with editable area"
            isInput
            value={product?.data?.editorMaskImg}
            onChange={(data: string) => {
              updateProductData({
                editorMaskImg: data,
              });
            }}
            right={(
              <ExampleLink onPress={() => {
                previewModalRef.current?.show("https://print-manager-media.s3.eu-west-1.amazonaws.com/files/1731743348236-Fire_short_mask.png");
              }} />
            )}
            extraButton={
              <Button
                mr1
                text="Create"
                height={30}
                borderRadius={15}
                width={100}
                onPress={onPressCreateImageWithArea}
              />
            }
          />
        </>
      )
    }
    if (product?.data?.editorType === 'masked-multi') {
      return (
        <TablePrintArea
          data={product?.data?.editorParts || [{}]}
          onChange={(data: any[]) => {
            updateProductData({ editorParts: data })
          }}
        />
      )
    }
    if (product?.data?.editorType === 'thumbnails') {
      return (
        <>
          <Row marginBottom={8}>
            <Text fontWeight="500" fontSize={14}>Parts</Text>
            <Col marginLeft={12}>
              <ExampleLink onPress={() => {
                previewModalRef.current?.show("https://print-manager-media.s3.eu-west-1.amazonaws.com/files/1730984521524-Fire_coat.png");
              }} />
            </Col>
          </Row>
          <TableForm
            addBtnTitle="Add Part"
            data={product?.data?.editorParts || []}
            columns={[
              { key: 'label', label: 'Label', flex: 1 },
              { key: 'mask', label: 'Mask', isUpload: true, flex: 1, isInputUpload: true },
            ]}
            editable
            onChange={(data: any) => {
              updateProductData({
                editorParts: data,
              });
            }}
            isBottomBtn
            marginBottom={16}
          />
        </>
      )
    }
    if (product?.data?.editorType === 'empty' || product?.data?.editorType === 'basic') {
      return (
        <>
          <InputField
            title="Editor width"
            value={product?.data?.editorWidth}
            onChangeText={(v) => {
              updateProductData({ editorWidth: v })
            }}
            marginBottom={16}
          />
          <InputField
            title="Editor height"
            value={product?.data?.editorHeight}
            onChangeText={(v) => {
              updateProductData({ editorHeight: v })
            }}
            marginBottom={16}
          />
          {product?.data?.editorType === 'basic' && (
            <>
              <UploadField
                title="Product image front"
                isInput
                value={product?.data?.editorBackground}
                onChange={(data: string) => {
                  updateProductData({
                    editorBackground: data,
                  });
                }}
                marginBottom={16}
              />
              <UploadField
                title="Product image back"
                isInput
                value={product?.data?.editorBackgroundBack}
                onChange={(data: string) => {
                  updateProductData({
                    editorBackgroundBack: data,
                  });
                }}
                marginBottom={16}
              />
            </>
          )}
        </>
      );
    }
    return null;
  }, [product, onPressCreateImageWithArea])

  const previewConfigs = useMemo(() => {
    if (product?.data?.previewType === 'pts') {
      return (
        <UploadField
          title="PSD File"
          isInput
          value={product?.data?.previewPsdUrl}
          onChange={(data: string) => {
            updateProductData({
              previewPsdUrl: data,
            });
          }}
          marginBottom={16}
        />
      )
    }
    if (product?.data?.previewType === '3d') {
      return (
        <UploadField
          title="3D Model File"
          isInput
          value={product?.data?.previewGlbUrl}
          onChange={(data: string) => {
            updateProductData({
              previewGlbUrl: data,
            });
          }}
          marginBottom={16}
        />
      )
    }
    if (product?.data?.previewType === 'blender') {
      return (
        <>
          <UploadField
            title="Blender File"
            isInput
            value={product?.data?.blenderUrl}
            onChange={(data: string) => {
              updateProductData({
                blenderUrl: data,
              });
            }}
            marginBottom={16}
          />
          <UploadField
            title="Support File"
            isInput
            value={product?.data?.blenderSupportUrl}
            onChange={(data: string) => {
              updateProductData({
                blenderSupportUrl: data,
              });
            }}
            marginBottom={16}
          />
        </>
      )
    }
    return null;
  }, [product])

  const isCustomizable = product?.tags?.includes(`sp-${productId}`);

  const mainContent = () => {
    return (
      <>
        <ScrollView
          style={{ height: containerHeight - 56 }}
        >
          <Grid xs='100%' md='100%' lg='50%' marginHorizontal={8} marginTop={16} alignItems="flex-start" flex1>
            <Col marginHorizontal={8} marginTop={8}>
              <ImageGallery
                mainImage={product?.images?.[0]?.url}
                images={product?.images?.slice(1)?.map(i => i.url) || []}
              />
            </Col>
            <Col marginHorizontal={8} marginTop={8}>
              <InputField
                title="Product name"
                value={product?.title}
                onChangeText={(v) => {
                  updateProduct({ title: v })
                }}
                marginBottom={16}
              />
              <TextAreaField
                title="Description"
                value={product?.description || ''}
                onChangeText={(v) => updateProduct({ description: v })}
                marginBottom={16}
              />
              <TagsField
                title="Tags"
                value={product?.tags}
                onChangeText={(v) => {
                  updateProduct({ tags: v })
                }}
                marginBottom={16}
              />
              <Row marginTop={8} marginBottom={16} alignItems="center">
                <Text subtitle1 mr2>
                  Customisable
                </Text>
                <Switch
                  checked={isCustomizable}
                  onChange={(v) => {
                    if (v) {
                      updateProduct({ tags: uniq([...(product?.tags || []), `sp-${productId}`, 'web2print']) })
                    } else {
                      updateProduct({ tags: product?.tags?.filter(i => i !== `sp-${productId}` && i !== 'web2print') })
                    }
                  }}
                />
              </Row>
              {isCustomizable && (
                <>
                  <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={16} />
                  <Text marginBottom={16} fontSize={16} fontWeight="600">Editor</Text>
                  <SelectField
                    title="Editor type"
                    options={EDITOR_TYPES}
                    value={product?.data?.editorType}
                    onChange={(val: string) => updateProductData({ editorType: val })}
                    marginBottom={16}
                  />
                  {editorConfigs}
                  <Col height={1} backgroundColor={COLORS.BORDER} marginVertical={16} />
                  <Text marginBottom={16} fontSize={16} fontWeight="600">Previewer</Text>
                  <SelectField
                    title="Preview type"
                    options={PREVIEW_TYPES}
                    value={product?.data?.previewType}
                    onChange={(val: string) => updateProductData({ previewType: val })}
                    marginBottom={16}
                  />
                  {previewConfigs}
                  <Button
                    text="Test editor"
                    disabled={!isValidEditorConfig}
                    onPress={onPressTestEditor}
                    isLoading={saving}
                  />
                </>
              )}

              {/* <Row marginTop={16}>
                <Col flex1>
                  <ButtonOutline
                    icon="media"
                    title="View artboard"
                    onPress={() => {
                      window.open(product?.artboardUrl || "/artboards", "_blank");
                    }}
                  />
                </Col>
                <Col width={16} />
                <Col flex1>
                  <ButtonOutline
                    icon="book"
                    title="Artwork guidelines"
                    onPress={() => {
                      window.open("https://print-manager-media.s3.eu-west-1.amazonaws.com/bg/Artwork-Guide-Customiser.pdf", "_blank");
                    }}
                  />
                </Col>
              </Row> */}
            </Col>
          </Grid>
          <Col height={84} />
        </ScrollView >
      </>
    )
  }

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `Products`, screen: SCREEN.ListSyncedProducts },
        { title: product?.title || `Product details` },
      ]}
    >
      {!!error ? (
        <Col flex1 middle>
          <Text color="red" subtitle1>{error}</Text>
        </Col>
      ) : (
        !product ? (
          <Row height={50} stretch>
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
          </Row>
        ) : (
          <Col borderWidth={1} borderColor={COLORS.BORDER} bgWhite
            flex1
            style={{
              overflow: 'hidden',
            }}
            onLayout={(e) => {
              const height = e.nativeEvent.layout.height;
              setContainerHeight(height);
            }}
          >
            {mainContent()}
            <Col absolute bottom={0} left={0} right={0}>
              <Row
                borderTopColor={COLORS.BORDER} bgWhite
                borderTopWidth={1} paddingHorizontal={16} paddingVertical={12}
                justifyContent="flex-end"
              >
                <ButtonOutline
                  title="Open in Shopify"
                  onPress={() => {
                    window.open(product?.onlineStoreUrl || `https://shopify.msupply.co.uk/products/${product?.handle}`, "_blank");
                  }}
                  style={{ marginLeft: 16 }}
                />
                <AntButton
                  size="large"
                  loading={saving}
                  type="primary"
                  onClick={() => onSubmit()}
                  style={{ marginLeft: 16, backgroundColor: COLORS.GREEN }}
                >
                  <Text fontSize={16} fontWeight="600" colorWhite>Save changes</Text>
                </AntButton>
              </Row>
            </Col>
          </Col>
        )
      )}
      <PreviewModal ref={previewModalRef} />
    </CMSLayout>
  );
};

SyncedProductDetail.routeInfo = {
  title: 'Product detail - MSupply',
  path: '/products/:productId',
};

export default SyncedProductDetail;
