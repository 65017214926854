import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, showPopupMessage } from 'components';
import { COLOR } from 'const';
import Store from 'store';
import { Entypo, Feather, MaterialIcons, AntDesign, EvilIcons, Ionicons, FontAwesome5 } from '@expo/vector-icons';
import { ActivityIndicator } from 'react-native';
import { TimeHelper } from 'helpers';
import { TGeneralData, TPrintJob } from 'type';

const CustomerAddressModal = ({ printJob, orderId }: any) => {

  const [fullOrder, setFullOrder] = useState<any>();
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    (async () => {
      const res = await Store.Api.Shop.getOrderInfoFromShopifyId({
        orderId
      });
      if (res.data.success) {
        setFullOrder(res.data.data);
        setSelectedItems(res.data.data.line_items);
        // if (res.data.data.line_items.length === 1) {
          
        // }
      } else {
        // alert(res.data.error);
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'
  
        });
      }
    })();
  }, []);

  const billing_address = fullOrder?.billing_address;
  const shipping_address = fullOrder?.shipping_address;
  const customer = fullOrder?.customer;

  const subtotal = useMemo(() => {
    if (!fullOrder) return 0;
    if (fullOrder.line_items.length === selectedItems.length) return +fullOrder?.current_subtotal_price;
    return selectedItems.length === 0 ? 0 : selectedItems.length === 1 ? +selectedItems[0].price * selectedItems[0].quantity :
      selectedItems.map(s => ({ sub: +s.price * s.quantity })).reduce((a,b) => ({ sub: a.sub + b.sub })).sub;
  }, [fullOrder, selectedItems]);

  const shippingCostCharged = useMemo(() => {
    if (!fullOrder) return 0;
    const shipping_lines = fullOrder?.shipping_lines || [];
    return shipping_lines.length === 0 ? 0 : shipping_lines.length === 1 ? +shipping_lines[0].price :
      shipping_lines.reduce((a,b) => ({ price: +a.price + +b.price })).price;
  }, [fullOrder, selectedItems]);

  const total = useMemo(() => {
    if (!fullOrder) return 0;
    if (fullOrder.line_items.length === selectedItems.length)
      return +fullOrder?.current_total_price;
    return subtotal + shippingCostCharged;
  }, [fullOrder, selectedItems, subtotal, shippingCostCharged]);

  const dispatch = async () => {
    if (success) {
      modal.hide();
      return;
    }
    const getAddress = (obj) => {
      const address = {
        fullName: `${obj.first_name} ${obj.last_name || ''}`,
        addressLine1: obj.address1,
        addressLine2: obj.address2 || undefined,
        city: obj.city,
        county: obj.country,
        postcode: obj.zip,
        countryCode: obj.country_code,
      };
      for (let key in address) {
        if (!address[key]) {
          delete address[key];
        }
      }
      return address;
    }
    try {
      
      const res = await Store.Api.Shop.dispatchToRoyalMail({
        orderReference: '#' + String(fullOrder.order_number),
        recipient: {
          address: getAddress(shipping_address),
          phoneNumber: shipping_address.phone || '',
          emailAddress: customer.email,
        },
        // sender: {
        //   tradingName: 'MSupply',
        //   phoneNumber: '01277200520',
        //   emailAddress: 'hello@msupply.co.uk',
        // },
        billing: {
          address: getAddress(billing_address),
          phoneNumber: billing_address.phone || '',
          emailAddress: customer.email,
        },
        packages: selectedItems.map(item => ({
          packageFormatIdentifier: `parcel`,
          contents: [
            {
              name: item.name,
              SKU: item.sku,
              quantity: item.quantity,
              unitWeightInGrams: (item.grams || 100) / item.quantity,
              unitValue: +item.price,
            }
          ],
          weightInGrams: (item.grams || 100)
        })),
        orderDate: fullOrder?.created_at,
        subtotal,
        shippingCostCharged,
        total,
      });

      if (res.data.data.successCount > 0) {
        const orderIdentifier = res.data.data.createdOrders[0].orderIdentifier;
        setMessage(`Successfully created order. Order Identifier: ${orderIdentifier}`);
        setSuccess(true);
      } else {
        setMessage(`An error has occurred`);
      }
    } catch (err) {
      // alert(String(err));
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
  };

  return (
    <Col round1 bgWhite shadow p2>
      <Text fontSize={16} mb2>Create new order in RoyalMail</Text>
      <Col backgroundColor={'rgba(0,0,0,0.1)'} p2 round1>
        {!billing_address ? (
          <Text>Loading..</Text>
        ) : (
          <>
            <Text mb2 bold>Billing Info</Text>
            <Text mb1>Name: {billing_address.first_name} {billing_address.last_name}</Text>
            <Text mb1>Address:</Text>
            <Text mb0>{billing_address.address1}</Text>
            <Text mb0>{billing_address.address2}</Text>
            <Text mb2>{billing_address.city} {billing_address.zip} {billing_address.country}</Text>
            <Text mb2>{billing_address.phone}</Text>

            <Text mb2 bold>Shipping/Recipient Info</Text>
            <Text mb1>Name: {shipping_address.first_name} {shipping_address.last_name}</Text>
            <Text mb1>Address:</Text>
            <Text mb0>{shipping_address.address1}</Text>
            <Text mb0>{shipping_address.address2}</Text>
            <Text mb2>{shipping_address.city} {shipping_address.zip} {shipping_address.country}</Text>
            <Text mb2>{shipping_address.phone}</Text>

            <Text mb2 bold>Packages</Text>
            {(fullOrder?.line_items || []).map((item, i) => {
              const isSelected = !!selectedItems.find(si => si.id === item.id);
              const onToggle = () => {
                if (isSelected) setSelectedItems(v => v.filter(vI => vI.id !== item.id));
                else {
                  const newSelectedItems = selectedItems.slice();
                  newSelectedItems.push(item);
                  setSelectedItems(newSelectedItems);
                }
              }
              return (
                <Row mb1 key={'v'+item.id}>
                  <TouchField cirle middle onPress={onToggle}>
                    {isSelected ? (
                      <FontAwesome5 name="check-circle" size={18} color="black" />
                    ) : (
                      <FontAwesome5 name="circle" size={18} color="black" />
                    )}
                  </TouchField>
                  <Col flex1 ml0 onPress={onToggle}>
                    <Text bold={isSelected}>{item.quantity} x {item.name}</Text>
                    <Text caption>sku: {item.sku}</Text>
                  </Col>
                </Row>
              )
            })}
            <Text mt1 mb2 bold>Pricing</Text>
            {!!fullOrder?.line_items && selectedItems.length !== fullOrder.line_items.length && (
              <Text caption>Note: if not all of the items from Shopify Order are selected. The total price will be different from what customer see when placing order.</Text>
            )}
            <Text mb1>Subtotal: <Text bold>{subtotal}</Text></Text>
            <Text mb1>Shipping Cost Charged: <Text bold>{shippingCostCharged}</Text></Text>
            <Text mb1>Total: <Text bold>{total}</Text></Text>
          </>
        )}
      </Col>

      {message && (
        <Col mt2>
          <Text bold>{message}</Text>
        </Col>
      )}

      <Row justifyContent={'space-between'} mt2>
        {selectedItems.length === 0 ? (
          <Col />
        ) : (
          <TouchField
            height={30}
            width={170}
            middle
            borderColor={COLOR.MAIN}
            backgroundColor={COLOR.MAIN}
            borderRadius={15}
            borderWidth={1}
            m0
            ph2
            onPress={dispatch}
          >
            {success ? (
              <Entypo name="check" size={24} color="white" />
            ) : (
              <Text colorWhite>Dispatch order</Text>
            )}
          </TouchField>
        )}
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.GREY}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            return modal.hide();
          }}
        >
          <Text color={COLOR.GREY}>Cancel</Text>
        </TouchField>
      </Row>
    </Col>
  );
};

export default CustomerAddressModal;
