import { Col, Text, Button, Input, useUIState, showPopupMessage } from 'components';
import { IScreen } from 'type';
import React, { useState, useRef, useEffect } from 'react';
// import './LoginMobile.css';
import { COLOR, SCREEN } from 'const';
import { SVG } from 'assets';
import Store, { Api } from 'store';
import { reset, useNavFunc } from 'navigation';
import {
  Animated,
  useWindowDimensions,
} from 'react-native';
import { ASSETS } from 'assets';

interface ILoginAnimationProps {
  redirect: string,
}



const LoginMobile: IScreen = () => {
  const { reset } = useNavFunc();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const UserStore = Store.useUserStore();

  const { navigate } = useNavFunc();
  const onPressRegister = () => {
    navigate(SCREEN.RegisterMobile);
  }
  const onLoginSuccess = () => {
    reset(SCREEN.MessageMobile);
  }
  const onPressLogin = async (e) => {
    e.preventDefault();
    try {
      if (!email || !password) return showPopupMessage({
        title: '',
        content: 'Please fill in all fields',
        typeHighlight: 'error',
        contentHighlight: 'Forms validation',
        buttonOkText: 'OK',
      })
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });
      if (res.data.success && res.data.data?.token) {
        console.log(res.data.error)
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        onLoginSuccess();
      } else {
        showPopupMessage({
          title: '',
          content: res.data.error === 'Error: Not existed' ? 'User does not exist' : res.data.error,
          typeHighlight: 'error',
          contentHighlight: 'Error',
          buttonOkText: 'OK',
        })
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        typeHighlight: 'error',
        contentHighlight: 'Error',
        buttonOkText: 'OK',
      })
    }
  }
  return (
  <div className="login-container">
    <div className="logo">
      <img src={ASSETS.LOGO_MOBILE} />
    </div>
    <div className="login_title">
      <p className="login_heading">Welcome to MSupply</p>
      <p className="login_note">
        Please input your email and password to login
      </p>
    </div>
    <form onSubmit={onPressLogin} className="login_form" autoComplete="off">
      <div className="form_group">
        <p className="input_name">Email</p>
        <div className="input email">
          <input
            type="email"
            className="form_input email"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="error_message" />
        </div>
      </div>
      <div className="form_group">
        <p className="input_name">Password</p>
        <div className="input">
          <input
            type="password"
            className="form_input password"
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="toggle-eye" onClick={()=>{
            const password = document.querySelector('.password')
            const togglePassword = document.querySelector('.toggle-eye')
                if(password.getAttribute('type') === 'password'){
                    password.setAttribute('type', 'text');
                    togglePassword.classList.add('hide');
                }
                else {
                    password.setAttribute('type', 'password');
                    togglePassword.classList.remove('hide');
                }
          }}/>
          <div className="error_message" />
        </div>
      </div>
      <div className="form_group right">
        <a href="#" className="forgot-password">
          Forgot password?
        </a>
      </div>
      <div className="form_group">
        <button type="submit" className="form_submit" onClick={onPressLogin}>
          <span className="form_submit-text">Log In</span>
        </button>
      </div>
    </form>
    <p className="line">Or</p>
    <p className="login_signup">
      Need an account?
      <a className="signup_link" onClick={onPressRegister}>
        Reseller register
      </a>
    </p>
    <script>


      
    </script>
  </div>

  )
}

LoginMobile.routeInfo = {
  title: 'LoginMobile',
  path: '/mobile/login',
}

export default LoginMobile;
