import { Col, Text, CMSLayout, useShopifyEmbedAppFlow } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

import Report from '../reports/Reports';
import ReportsOneClient from '../reports/ReportsOneClient';
import { RolesHelper } from 'helpers';
import apiClient from 'store/api-client';

const Home : IScreen = ( ) => {
  const { navigate, reset } = useNavFunc();
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const { user, isResellerCompleteSetup, didCheckGetStarted } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const { isEmbedAppFlow, shopify_embed_app_url, merchant_name } = useShopifyEmbedAppFlow();

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login);
      } 
      // else if (window.innerWidth <= 480) {
      //   reset(SCREEN.MessageMobile);
      // } 
      else if (u.role !== 'admin' && isEmbedAppFlow) {
        apiClient.Api.Shop.createNotExistShopifyEmbedApp({
          name: merchant_name,
          url: shopify_embed_app_url,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (!user) return;
    if (!didCheckGetStarted) {
      UserStore.checkIfResellerCompleteSetup();
    } else if (!isResellerCompleteSetup) {
      reset(SCREEN.HomeGetStarted);
    }
  }, [user, isResellerCompleteSetup, didCheckGetStarted]);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
    if (!user && isLoggedIn) {
      setIsLoggedIn(false);
    }
    if (!!user && isLoggedIn && user.role === 'reseller') {
      ShopStore.resellerHealthCheck();
    }
  }, [user, isLoggedIn]);

  const userName = !user ? '' : `${user.firstName} ${user.lastName}`;
  const resellerId = user?.role === 'reseller' ? user?.id : user?.role === 'admin' ? 'all'
    : user?.resellerId;

  const renderLoggedIn = () => {
    if (user?.role === 'admin' || isResellerCompleteSetup)  return <Report resellerId={resellerId} />;
    return null;
  }

  return !isLoggedIn || !didCheckGetStarted ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : renderLoggedIn();
};

Home.routeInfo = {
  title: 'MSupply - Print Manager',
  path: '/',
};

export default Home;
