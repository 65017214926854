import create from 'zustand';
import { useEffect, useState, Dispatch } from 'react';
import { useUIState, IStateUI, showPopupMessage } from 'components';
import { TOnlineStore } from 'type';
import Client from './api-client';

interface IShopStore {
  shops: {
    [ShopId: string]: TOnlineStore,
  },
  needAcceptUpdate?: boolean,
  getList(p: number, resellerId?: string): Promise<{ hasNext: boolean, list: Array<TOnlineStore>, total: number, error?: string, }>,
  getAll(resellerId?: string): Promise<Array<TOnlineStore>>,
  deleteStore(id?: string): Promise<any>,
  resellerHealthCheck(): void,
  useShop(id: string, useCache?: boolean): {
    shop: TOnlineStore | undefined,
    uiState: IStateUI,
    setShop: Dispatch<TOnlineStore>,
  },
  [otherKey: string]: any,
}

export const useShopStore = create<IShopStore>((set, get) => ({
  shops: {},
  needAcceptUpdate: false,
  resellerHealthCheck: () => {
    if (process.env.NODE_ENV !== "development") {
      Client.Api.Shop.resellerHealthCheck().then(res => {
        console.log("resellerHealthCheck", res);
        if (res.data?.needAcceptUpdate) {
          set({ needAcceptUpdate: true });
        }
      });
    }
  },
  getList: async (page = 1, resellerId) => {
    const res = await Client.Api.Shop.list({ page, resellerId });
    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj: IShopStore['products'] = {}
      res.data.data.list.forEach((j: TOnlineStore) => {
        obj[j.id] = j;
      });
      set({ shops: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  getAll: async (resellerId) => {
    const res = await Client.Api.Shop.all({ resellerId });

    if (!res.data.success) {
      return {
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data && Array.isArray(res.data.data)) {
      const obj: IShopStore['products'] = {}
      res.data.data.forEach((j: TOnlineStore) => {
        obj[j.id] = j;
      });
      set({ shops: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
  useShop: (id: string, useCache = true) => {

    const [uiState, setUI] = useUIState();
    const [shop, setShop] = useState<TOnlineStore>();

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().shops[id]) {
        setShop(get().shops[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Client.Api.Shop.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setShop(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { shop, setShop, uiState };
  },
  deleteStore: async (id: string) => {
    const res = await Client.Api.Shop.archive({ id });
    if (res?.data?.error) {
      showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',
        
        typeHighlight: 'danger',
        contentHighlight: 'Error'
  
      });
      // alert(res.data.error);
      return;
    }
    return res?.data;
  }
}));
