import React, { useState } from 'react';
import { Col, Grid, Input, Text, Button, modal, showPopupMessage } from 'components';
import Store from 'store';
import { useDynamicResponsiveValue } from 'quickly-react';
import { useWindowDimensions } from 'react-native';
import { useSettingsForm } from './FormContext';

const ChangePasswordModal = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { width } = useWindowDimensions();

  const submit = async () => {
    if (password !== confirmPassword) {
      showPopupMessage({
        title: '',
        content: 'Password and confirm password must be the same',
        buttonOkText: 'OK',
        
        typeHighlight: 'danger',
        contentHighlight: 'Error'
  
      });
      // alert('Password and confirm password must be the same');
      return;
    }
    const res = await Store.Api.User.updateProfile({ password });
    if (res.data && res.data.success) {
      showPopupMessage({
        title: '',
        content: 'Password changed.',
        buttonOkText: 'OK',
        
        typeHighlight: 'success',
        contentHighlight: 'Success'
  
      });
      // alert('Password changed');
      modal.hide();
    }
  }
  return (
    <Col round1 bgWhite shadow p3 width={Math.max(300, width * 0.4)}>
      <Text subtitle1 mb2>Change password</Text>
      <Input
        placeholder="Password"
        value={password}
        mb1
        onChange={setPassword}
        password
      />
      <Input
        placeholder="Confirm password"
        value={confirmPassword}
        mb1
        password
        onChange={setConfirmPassword}
      />
      <Button
        text='Change password'
        solid
        borderRadius={20}
        width100p
        paddingVertical={6}
        paddingHorizontal={10}
        onPress={submit}
      />
    </Col>
  )
}

const ProfileSettings = () => {
  const { profile, updateProfile } = useSettingsForm();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false });

  const onChangeField = (key) => (value) => {
    const isInit = key === "password"; // temp fix wrong edit status
    updateProfile(s => ({
      ...s,
      [key]: value,
    }), isInit)
  }

  const showModalChangePassword = () => {
    modal.show(
      <ChangePasswordModal />
    )
  }

  const containerStyle = isMobile ? {

  } : {
    m2: true, mv1: true, p1: true,
  }

  return (
    <Col flex1 {...containerStyle} round1 bgWhite overflow={"scroll"}>
      <Grid xs="100%" md="50%" alignItems={"flex-start"} mb2>
        <Col m1={isMobile ? false : true}>
          <Text subtitle1 mb2>
            Personal details
          </Text>
          <Input
            placeholder="First Name"
            value={profile?.firstName}
            mb1
            onChange={onChangeField("firstName")}
          />
          <Input
            placeholder="Last Name"
            value={profile?.lastName}
            mb1
            onChange={onChangeField("lastName")}
          />
          <Input
            editable={false}
            placeholder="Email"
            value={user?.email}
            mb1
          />
          <Button
            text='Change password'
            solid
            borderRadius={20}
            paddingVertical={6}
            paddingHorizontal={10}
            onPress={showModalChangePassword}
          />
          {/* <Input
            editable={user?.role === "reseller"}
            placeholder="Password"
            value={profile?.password}
            mb1
            password
            onChange={onChangeField("password")}
          /> */}
          {/* <Text subtitle1>
            Do you want MSupply to auto accept your order? If not selected it will be a manual process
          </Text>
          {user?.role === 'reseller' && (
            <Switch size={80} height={60} isOn={isSwitchOn} toggleSwitch={onToggleSwitch} />
          )} */}
        </Col>
      </Grid>
    </Col>
  );
};

export default ProfileSettings;
