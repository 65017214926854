import React, { useEffect, useRef, useState } from 'react';
import { IScreen, TDesign, TProduct } from 'type';
import { CMSLayout, Col, Row, Text, Button, useUIState, Grid, RatioCol, ShimmerLoading, useRefState, showPopupMessage } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { TimeHelper } from 'helpers';
import Store from 'store';
import { Image } from 'react-native';
import { ListDesignsTabs, useDesignTabs } from './ListDesigns.Tabs';

const ListDesigns: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const status = route.params?.status;
  const UserStore = Store.useUserStore();
  const DesignStore = Store.useDesignStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [designs, getDesigns, setDesigns] = useRefState<Array<TDesign>>([])

  // const [tab, setTab] = useDesignTabs();

  const [{ loading: isCreating}, setCreatingUI] = useUIState();
  const [{ loading: isCreatingOnDemand }, setCreatingOnDemandUI] = useUIState();

  const getData = async (p) => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      if (getDesigns().length === 0) {
        setUI({ fetching: true, errorMes: '' });
      }
      await TimeHelper.wait(500);
      const { list, hasNext, total, error } = await DesignStore.getList(p, status, resellerId);
      console.log({ list, hasNext, total, error });
      if (error) return setUI({ fetching: false, errorMes: error });
      setPagination({ hasNext, total });
      setDesigns(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    if (!status) navigation.navigate(SCREEN.ListDesigns, { status: 'custom-product' });
  }, [status]);

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    getData(1);
    return unsubscribe;
  }, [status]);

  const renderDesigns = () => {
    if (designs.length === 0) return (
      <Col>
        <Text>No design found</Text>
      </Col>
    )
    return (
      <Grid xs='50%' md='25%' marginHorizontal={-30}>
          {designs.map((val, i) => {
            const showPublishedStatus = UserStore.user?.role === 'reseller';
            const isPublished = val.products.length > 0;
            return (
              <Col paddingTop={40} bgWhite shadow overflow={'hidden'} round1 m1 p1 key={val.id} onPress={() => navigation.navigate(SCREEN.UpsertDesign, { id: val.id })}>
                <RatioCol
                  width={'100%'}
                  ratio={1}
                >
                  <Image source={{ uri: val.image }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                </RatioCol>
                <Row mb0 height={25}>
                  {val.brands.length > 0 && (
                    <Text caption numberOfLines={2}>
                      Brands:
                      <Text bold ml0 backgroundColor={'rgba(0,0,0,0.05)'} p0>
                        {val.brands.map(v => v.name).join(', ')}
                      </Text>
                    </Text>
                  )}
                </Row>
                <Col height={1} backgroundColor={'rgba(0,0,0,0.05)'} />
                <Row height={30} mt0>
                  <Text numberOfLines={2} subtitle1>{val.name}</Text>
                </Row>
                <Text>Resale Price: <Text bold fontSize={20}>£{val.resalePrice || 0}</Text></Text>
                <Col
                  absolute top={0} right={0} width={115}
                >
                  {showPublishedStatus && (
                    <Col borderBottomLeftRadius={10}
                      backgroundColor={isPublished ? '#2E8304' : '#FF9A01'}
                      p0 middle
                    >
                      <Text color='white' caption>{isPublished ? 'Published' : 'Not published'}</Text>
                    </Col>
                  )}
                  <Col
                    p0 backgroundColor={COLOR.MAIN} marginTop={2} middle
                  >
                    <Text color='white' caption>{val.isCustomizable ? 'Customisable' : 'Print on Demand'}</Text>
                  </Col>
                </Col>
              </Col>
            )
          })}
        </Grid>
    );
  }

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `Product Designs` },
      ]}
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          {/* <Text h3 >Product Designs</Text> */}
          <ListDesignsTabs
            activeTab={status}
            ml1
            onChangeTab={(newKey) => {
              navigation.navigate(SCREEN.ListDesigns, { status: newKey });
            }}
          />
        </Row>
        <Row>
          <Button
            text="Create"
            isLoading={isCreating}
            width={100} height={40} borderRadius={20}
            onPress={async () => {
              setCreatingUI({ loading: true });
              const res = await Store.Api.Design.upsert({
                name: 'Untitled design',
                isCustomizable: true,
              });
              if (res.data.error) {
                setCreatingUI({ loading: false });
                return showPopupMessage({
                  title: '',
                  content: String(res.data.error),
                  buttonOkText: 'OK',
                  
                  typeHighlight: 'danger',
                  contentHighlight: 'Error'
            
                }); 
                // alert(res.data.error);
              } else if (res.data.data.id) {
                setCreatingUI({ loading: false });
                navigation.reset({
                  index: 0,
                  routes: [{name: SCREEN.UpsertDesign, params: { id: res.data.data.id }}],
                });
              }
            }}
          />
        </Row>
      </Row>
      <Col flex1 m2 marginTop={-5} p2>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <Row height={200} stretch marginHorizontal={-30}>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
              <Col round1 bgWhite flex={1} m1 p1>
                <ShimmerLoading flex1 />
              </Col>
            </Row>
          ) : (
            renderDesigns()
          )
        )}
        
      </Col>
    </CMSLayout>
  );
};

ListDesigns.routeInfo = {
  title: 'List Products - Designs - MSupply',
  path: '/designs/:status',
};

export default ListDesigns;
