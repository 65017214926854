import { Col, Row, Text, Button, WalletBalanceRenderer, Table, showPopupMessage } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { TCMSOrder } from 'type';
import Store, { Api } from 'store';
import { SCREEN, COLOR, shippingPrice, EUROPEAN_COUNTRIES, shippingPriceEurope, shippingPriceTheRestOfTheWorld, TAX_ONLY_RATE, DISCOUNT_RATE, SAMPLE_DISCOUNT } from 'const';
import { ActivityIndicator } from 'react-native';
import { usePaymentStore } from "store/Payment.Store";
import { Elements } from "@stripe/react-stripe-js";
import { TimeHelper, ValHelper } from 'helpers';
import { ColumnsType } from 'antd/es/table';
import { useOrderStore } from 'store/Order.Store';
import { useNavFunc } from 'navigation';
import { notification } from 'antd';
import OrderTable from 'components/elements/table/OrderTablePro';


const PaySampleOrder = ({ order, onClose, sampleToken, onSuccess }: { order: TCMSOrder, onClose?: () => void, onSuccess?: () => void, sampleToken?: number }) => {
  const OrderStore = useOrderStore();
  const { navigation } = useNavFunc();
  const { stripePromise, getStripeConfig, refreshBalance } = usePaymentStore();
  const [stripeInvoiceItems, setStripeInvoiceItems] = useState<any[]>([]);
  const [stripeInvoices, setStripeInvoices] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);
  const [shipping, setShipping] = useState("RM48");
  const [orderData, setOrderData] = useState<any>();

  const getPipelineOrder = async () => {
    const orderId = order["Order ID"] ? String(order["Order ID"]) : undefined;
    if (!orderId) return;
    const findClient = OrderStore.clients.find(v => v['Client ID'] === order["Client ID"]);
    const fetchPipelines = await OrderStore.getPipelinesByOrderId(findClient?.slug || "bg", orderId);
    setOrderData({
      ...order,
      Pipelines: fetchPipelines,
    });
  }

  useEffect(() => {
    getStripeConfig();
    refreshBalance();
  }, []);

  useEffect(() => {
    (async () => {
      if (!order) return;
      let tryTime = 0;
      let stripeInvoices = [];
      let data;
      await getPipelineOrder();
      while (tryTime < 5 && !stripeInvoices?.length) {
        tryTime++;
        data = await OrderStore.getChargeAmountOrderSample([order]);
        stripeInvoices = data?.stripeInvoices;
        if (!stripeInvoices.length) await new Promise(resolve => setTimeout(resolve, 3000));
      }

      const useSampleToken = sampleToken > 0;
      if (stripeInvoices?.length) {
        const arr = [];
        stripeInvoices.map(item => {
          const orderNo = item.metadata?.orderNumber;
          const dbInvoice = data?.invoices?.find(i => i.orderNumber === orderNo);

          const shipItem = item.lines.data?.find(i => i.description?.includes("Shipping fee"));
          const taxItem = item.lines.data?.find(i => i.description?.includes("VAT "));

          let productsOnly = 0;
          if (!shipItem && !taxItem) {
            const discountedAmount = useSampleToken ? Math.round(Number(item.total) * DISCOUNT_RATE) : item.total;
            arr.push({ orderNo, item: "Products", value: discountedAmount });
          } else {
            productsOnly = item.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
            const discountedAmount = useSampleToken ? Math.round(productsOnly * DISCOUNT_RATE) : productsOnly;
            arr.push({ orderNo, item: "Products", value: discountedAmount });
          }
          if (shipItem) {
            arr.push({ orderNo, item: "Shipping fee", value: shipItem?.amount });
          }
          if (taxItem) {
            let discountedTax = taxItem?.amount;
            if (useSampleToken) discountedTax = Math.round(taxItem.amount - productsOnly * SAMPLE_DISCOUNT * TAX_ONLY_RATE);

            arr.push({ orderNo, item: "VAT (20%)", value: discountedTax });
          }
          if (!shipItem) {
            const shippingFee = (() => {
              if (!dbInvoice?.shippingAddress) return shippingPrice.RM48;
              if (dbInvoice?.shippingAddress?.country === 'United Kingdom') {
                return shippingPrice["RM48"] || 0;
              }
              if (EUROPEAN_COUNTRIES.includes(dbInvoice?.shippingAddress?.country)) {
                return shippingPriceEurope["RM48"] || 0;
              }
              return shippingPriceTheRestOfTheWorld["RM48"] || 0;
            })();
            arr.push({ orderNo, item: "Shipping fee", value: shippingFee * 100 });

            if (dbInvoice?.taxes) {
              const shippingFeeTax = shippingFee * TAX_ONLY_RATE * 100;
              const totalTax = Math.round(Number(item?.total) * TAX_ONLY_RATE + shippingFeeTax)
              arr.push({ orderNo, item: "VAT (20%)", value: totalTax });
            }
          }
        });
        setStripeInvoiceItems(arr);
      }
      setStripeInvoices(stripeInvoices);
      setLoading(false);
    })();
  }, []);

  const totalPrice = useMemo(() => {
    let totalOfStripeInvoices = 0;
    if (stripeInvoiceItems?.length) {
      totalOfStripeInvoices = stripeInvoiceItems.reduce((total, item) => {
        return total + (item?.value || 0);
      }, 0) / 100;
    };
    return totalOfStripeInvoices;
  }, [stripeInvoiceItems]);

  const lineItemColumns: ColumnsType<any> = [
    {
      title: 'Order No',
      key: 'orderNo',
      dataIndex: 'orderNo',
      render: (value) => `#${value}`,
      align: 'center'
    },

    {
      title: 'Product Price',
      dataIndex: 'products',
      key: 'products',
      render: (products) => "£" + (Number(products) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'Shipping',
      dataIndex: 'shippingfee',
      key: 'shippingfee',
      render: (shippingfee) => "£" + (Number(shippingfee) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'VAT 20%',
      dataIndex: 'vat',
      key: 'vat',
      render: (vat) => "£" + (Number(vat || 0) / 100).toFixed(2),
      align: 'center'
    },
  ]

  if (!stripePromise) return null;
  // console.log("stripeInvoiceItems", stripeInvoiceItems)
  const mergeItems = (items) => {
    const mergedItems = {};

    for (const item of items) {
      const orderNo = item.orderNo;
      if (!mergedItems[orderNo]) {
        mergedItems[orderNo] = { orderNo }; // Initialize object for current orderNo
      }

      const transformedItem = {
        [item.item.toLowerCase().replace(/\s+/g, '').replace(/\(.*\)/g, '')]: item.value // Lowercase, remove spaces, camelCase, remove parentheses
      };
      mergedItems[orderNo] = { ...mergedItems[orderNo], ...transformedItem }; // Add transformed item
    }

    return Object.values(mergedItems); // Convert object back to an array of merged objects
  }

  // Filter data based on specific orderNo (if provided) or merge all
  const filteredData = () => {
    return mergeItems(stripeInvoiceItems); // Merge all items if no filter provided
  };


  // To merge all orders:
  const allMergedData = filteredData();

  const onAcceptPayment = async (walletBalance) => {
    if (walletBalance < totalPrice) return;
    try {
      if (isPaying) return;
      setIsPaying(true);
      await OrderStore.chargeFromWalletByStripeInvoice(
        [orderData],
        stripeInvoices.map(i => i.id),
        shipping,
        sampleToken > 0,
      )
      onClose?.();
      notification.success({
        message: 'Order sample success',
        description: 'You have successfully paid the order. Please await for admin approval.',
      });
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(err);
      console.log(err);
    }
  }

  if (!stripePromise) return null;

  return (
    <Elements stripe={stripePromise} >
      <WalletBalanceRenderer>
        {({ balance }) => {

          const walletBalance = (balance?.balance * -1 || 0) / 100

          const renderBalance = () => {
            return (
              <Text bold>£{ValHelper.formatBalance(walletBalance)} {String(balance?.currency || "GBP").toUpperCase()}</Text>
            )
          }

          return !order ? (
            <Col flex1 p2 middle>
              <Text>You have already paid these orders. Please wait for admin approval.</Text>
            </Col>
          ) : loading ? (
            <Col flex1 p2 middle>
              <ActivityIndicator color={COLOR.MAIN} size='small' />
            </Col>
          ) : (
            <Col flex1 mv2>
              <Text mh2 bold mb1 fontSize={18} textAlign={"center"}>Pay and proceed</Text>
              <Text mh2 bold mb2 fontSize={18} textAlign={"center"}>Balance: {renderBalance()}</Text>
              {/* DEFAULT TO RM48 FOR NOW */}
              {/* <SelectShippingService onChange={setShipping} value={shipping} /> */}
              {!!shipping && (
                <Col>
                  <Text mh2 mv0>
                    Accepting these jobs will take funds from your wallet. Once paid, these orders cannot be amended. MSupply will review these orders before they go into production.
                  </Text>
                  {!!stripeInvoiceItems?.length && (
                    <Col mv1>
                      <OrderTable
                        style={{
                          maxHeight: 320,
                          overflowY: "auto",
                        }}
                        data={allMergedData}
                        className="payList"
                        rowClassName="stripeInvoiceTable"
                        columns={lineItemColumns}
                        rowKey={record => `${record.id}`}
                        sticky={true}

                      />
                    </Col>
                  )}
                  <Text mv0 center>Total: <Text bold fontSize={20}>£{ValHelper.formatMoney(totalPrice)} GBP</Text></Text>
                  <Text mv0 center bold>Do you wish to continue?</Text>
                </Col>
              )}
              <Row mh2>
                <Col flex1 marginRight={10}>
                  <Col>
                    <Button
                      outline
                      height={30}
                      width='100%'
                      text='Cancel'
                      onPress={onClose}
                    />
                  </Col>
                </Col>
                {(walletBalance < totalPrice || walletBalance === 0) && (
                  <Col flex1 marginRight={10}>
                    <Col>
                      <Button
                        outline
                        height={30}
                        width='100%'
                        text='Add balance'
                        onPress={async () => {
                          onClose?.();
                          navigation.navigate(SCREEN.Settings, { tabId: 'billing' });
                        }}
                      />
                    </Col>
                  </Col>
                )}
                <Col flex1>
                  <Col>
                    <Button
                      opacity={walletBalance < totalPrice ? 0.5 : 1}
                      solid
                      isLoading={isPaying}
                      height={30}
                      width='100%'
                      text='Accept'
                      onPress={TimeHelper.throttle(() => onAcceptPayment(walletBalance))}
                    />
                  </Col>
                </Col>
              </Row>
            </Col>
          )
        }}
      </WalletBalanceRenderer>
    </Elements>

  )
}

export default PaySampleOrder;
