import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IScreen } from 'type';
import { ActivityIndicator } from 'react-native';
import { ButtonOutline, Col, Row, Text } from 'components';
import { useNavFunc } from 'navigation';
import apiClient, { TDesign, TPrintJob, TProduct, TSyncedProduct } from 'store/api-client';
// import { MICRO_SERVICES_HOST } from 'store/Order.Store';
const MICRO_SERVICES_HOST = "https://dev.services.personify.tech";
import { Button as AntButton, ConfigProvider } from 'antd';
import Store, { checkPolotnoData } from 'helpers/EditorSDK';
import { getPreviewPTSActionNew, uploadFileToS3 } from 'components/widgets/editor-iframe/utils';

const ShopifyEditor: IScreen = () => {
  const { route } = useNavFunc();
  const { productId, customArtwork, ...restParams }: any = route.params || {};
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframePreviewRef = useRef<HTMLIFrameElement>(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [editorStoreReady, setEditorStoreReady] = useState(false);
  const [previewStoreReady, setPreviewStoreReady] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productData, setProductData] = useState<TSyncedProduct | null>(null);
  const editorStore = useRef(new Store());
  const previewStore = useRef(new Store());
  const storeReadyRef = useRef(false);
  const savedCanvasRef = useRef('');
  const previewType = productData?.data?.previewType;
  const [previewImageUrl, setPreviewImageUrl] = useState('');

  useEffect(() => {
    editorStore.current?.onReady().then(() => {
      if (storeReadyRef.current) return;
      storeReadyRef.current = true;
      setEditorStoreReady(true);
      if (savedCanvasRef.current) {
        editorStore.current?.loadJSON(savedCanvasRef.current);
      }
    });
    return () => {
      setEditorStoreReady(false);
      setPreviewStoreReady(false);
      storeReadyRef.current = false;
      setPreviewUrl('');
    }
  }, [customArtwork]);

  const getData = async () => {
    let savedCanvas = '';
    try {
      const res = await apiClient.Api.SyncProduct.detailSyncedProducts({
        id: productId,
      });
      const data = res.data?.data as TSyncedProduct;
      if (data?.data?.canvas && checkPolotnoData(data.data.canvas)) {
        savedCanvas = data.data.canvas;
      }
      setProductData(data);
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    }
    if (savedCanvas) savedCanvasRef.current = savedCanvas;
  }

  useEffect(() => {
    if (productId) {
      getData();
    }
  }, [productId, customArtwork]);

  const onPressPreviewPTSAction = async ({ isPreviewOnly = false }: { isPreviewOnly?: boolean }) => {
    const editorRes: any = await editorStore.current?.generateArtwork(true)
    if (editorRes?.artworkUrl) {
      let artworkUrl = editorRes?.artworkUrl;
      // if (!isPreviewOnly) {
      // Check if base64 and > 500kb
      if (artworkUrl.startsWith('data:') && artworkUrl.length > 500 * 1024 * 1.37) { // 1.37 factor for base64 overhead
        artworkUrl = await uploadFileToS3(editorRes?.artworkUrl);
      }
      // }
      const previewUrl = await getPreviewPTSActionNew({
        artworkUrl,
        psdUrl: productData?.data?.previewPsdUrl,
      })
      return {
        previewUrl,
        artworkUrls: [artworkUrl],
      };
    }
    if (editorRes?.artworkUrls?.length) {
      let artworkUrls = editorRes?.artworkUrls;
      // if (!isPreviewOnly) {
      artworkUrls = await Promise.all(editorRes?.artworkUrls.map(async (url: string) => {
        // Check if base64 and > 500kb
        if (url.startsWith('data:') && url.length > 500 * 1024 * 1.37) {
          return await uploadFileToS3(url);
        }
        return url;
      }))
      // }
      const previewUrl = await getPreviewPTSActionNew({
        artworkUrls,
        psdUrl: productData?.data?.previewPsdUrl,
      });
      return {
        previewUrl,
        artworkUrls,
      };
    }
    return {
      previewUrl: '',
      artworkUrls: [],
    };
  }

  const onPressPreview = async () => {
    if (previewType === "remove-edit-area") {
      const data: any = await editorStore.current?.generateArtwork(true)
      if (data?.previewUrl) {
        setPreviewImageUrl(data?.previewUrl);
        setPreviewUrl(`${MICRO_SERVICES_HOST}/preview/basic`);
        setPreviewStoreReady(true);
      }
    } else if (previewType === "pts") {
      setIsLoadingPreview(true);
      const previewRes = await onPressPreviewPTSAction({ isPreviewOnly: true });
      const previewImage = previewRes?.previewUrl;
      if (previewImage) {
        setPreviewImageUrl(previewImage);
        setPreviewUrl(`${MICRO_SERVICES_HOST}/preview/basic?image=${previewImage}`);
        // previewStore.current = new Store();
        // setTimeout(() => {
        //   previewStore.current?.onReady().then(() => {
        setPreviewStoreReady(true);
        //   });
        // }, 1000);
      }
      setIsLoadingPreview(false);
    } else if (previewType === "blender") {
      const data: any = await editorStore.current?.generateArtwork()
      if (data?.artworkUrl) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/preview/blender?image=${data.artworkUrl}&blend=${productData?.data?.blenderUrl}&blend_support=${productData?.data?.blenderSupportUrl}`);
      }
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
    } else if (previewType === "3d") {
      const data: any = await editorStore.current?.generateArtwork()
      if (data?.artworkUrl) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/preview/3d?image=${data.artworkUrl}&model=${productData?.data?.previewGlbUrl}`);
      }
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
    } else {
      setIsLoadingPreview(true);
      const artworkBase64 = await editorStore.current?.toDataURL({
        pixelRatio: 1,
        pageId: editorStore.current?.activePage.id,
      }) as string;
      let artworkUrl = await uploadFileToS3(artworkBase64)
      setPreviewUrl(`${MICRO_SERVICES_HOST}/preview/basic?image=${artworkUrl}`);
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
      setIsLoadingPreview(false);
    }
  }

  const onPressEditor = async () => {
    setPreviewUrl('');
    setPreviewImageUrl('');
  }

  const onCheckout = async () => {
    setIsCheckingOut(true);
    try {
      const canvas = editorStore.current?.toJSON();
      let artworkUrls = [];
      let previewUrl = '';
      // if (previewType === "pts") {
      //   const previewRes = await onPressPreviewPTSAction({});
      //   previewUrl = previewRes?.previewUrl;
      //   artworkUrls = previewRes?.artworkUrls;
      // } else {
      const data: any = await editorStore.current?.generateArtwork(true)
      previewUrl = data?.previewUrl || data?.artworkUrl || data?.artworkUrls?.[0]
      if (previewUrl && !previewUrl.startsWith("https")) {
        previewUrl = await uploadFileToS3(previewUrl)
      }
      artworkUrls = data?.artworkUrls || [data?.artworkUrl]
      // }

      artworkUrls = await Promise.all(artworkUrls.map(async (url: string) => {
        if (url.startsWith("http")) return url;
        return await uploadFileToS3(url);
      }))

      const textWithColorNames = []
      const canvasDeepClone = JSON.parse(JSON.stringify(canvas));
      canvasDeepClone.pages.forEach((page: any) => {
        page.children.forEach((element: any) => {
          if (element.custom?.s3Url) {
            element.src = element.custom.s3Url;
            delete element.custom.s3Url;
          }
          if (element.type === "text" && element.custom?.colorName) {
            textWithColorNames.push({
              text: element.text.length > 10 ? element.text.slice(0, 7) + '...' + element.text.slice(-3) : element.text,
              colorName: element.custom.colorName,
            })
          }
        });
      });

      const { data: final } = await apiClient.Api.PrintJob.upsert({
        artworkUrls,
        previewUrl,
        productName: productData?.title,
        quantity: 1,
        data: {
          storeProductId: productData?.id?.replace('gid://shopify/Product/', ''),
          design: {
            canvas,
          },
        },
      });
      const _printJobId = final.data.id;

      apiClient.editor.sendToParent({
        event: "CUSTOMIZATION_DONE",
        payload: {
          printJobId: _printJobId,
          thumbnail: previewUrl,
          properties: {
            "Color Name": textWithColorNames?.map(i => i.colorName).join(", "),
          }
        }
      })
    } catch (error) {
      console.log("error", error)
      alert(error?.message || JSON.stringify(error));
    } finally {
      setIsCheckingOut(false);
    }
  }


  const editorUrl = useMemo(() => {
    let queryObj: any = {}
    let editorType = productData?.data?.editorType || "empty";
    if (editorType === "thumbnails" && productData?.data?.editorParts?.length) {
      const printAreas = productData?.data?.editorParts
      queryObj.masks = printAreas.map(i => i.mask).join("|")
      queryObj.maskNames = printAreas.map((i, idx) => i.label || `Part ${idx + 1}`).join("|")
    }
    if (editorType === "masked-multi" && productData?.data?.editorParts?.length) {
      queryObj.images = productData?.data?.editorParts.map(i => i.image).join("|")
      queryObj.masks = productData?.data?.editorParts.map(i => i.mask).join("|")
      queryObj.maskNames = productData?.data?.editorParts.map((i, idx) => i.label || `Page ${idx + 1}`).join("|")
    }
    if (editorType === "empty" || editorType === "basic") {
      queryObj.width = productData?.data?.editorWidth
      queryObj.height = productData?.data?.editorHeight
      queryObj.image = productData?.data?.editorBackground
      if (productData?.data?.editorBackgroundBack) {
        editorType = "basic-multi"
        delete queryObj.image
        queryObj.masks = `${productData?.data?.editorBackground}|${productData?.data?.editorBackgroundBack}`
        queryObj.maskNames = "Front|Back"
      }
    }
    if (editorType === "masked") {
      queryObj.bgImg = productData?.data?.editorBgImg
      queryObj.maskImg = productData?.data?.editorMaskImg
    }
    if (customArtwork) {
      queryObj.image = customArtwork
    }
    if (Object.keys(restParams).length > 0) {
      queryObj = {
        ...queryObj,
        ...restParams
      }
    }
    let query = Object.entries(queryObj).map(([key, value]) => {
      if (!!value && String(value).trim()) {
        return `${key}=${value}`
      }
      return null;
    }).filter(i => !!i).join("&")
    return `${MICRO_SERVICES_HOST}/editor/${editorType}${query ? `?${query}` : ""}`;
  }, [productData, customArtwork, restParams]);

  const previewComp = useMemo(() => {
    if (previewImageUrl) return (
      <Col absoluteFill backgroundColor="#ccc" flex1>
        <img
          src={previewImageUrl}
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      </Col>
    )
    if (!previewUrl) return null;
    return (
      <Col absoluteFill backgroundColor="#ccc" flex1>
        <Col flex1>
          <iframe
            ref={iframePreviewRef}
            src={previewUrl}
            style={{ width: "100%", height: "100%", border: "none", backgroundColor: "#fff" }}
            onLoad={() => {
              previewStore.current?.init(iframePreviewRef.current);
            }}
          />
        </Col>
      </Col>
    )
  }, [previewUrl, previewImageUrl])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#000000",
          colorBorder: "#000000",
        },
        components: {
          Button: {
            defaultBorderColor: "#000000",
          }
        }
      }}
    >
      <Col flex1>
        <iframe
          ref={iframeRef}
          src={editorUrl}
          style={{ width: "100%", height: "100%", border: "none" }}
          onLoad={() => {
            editorStore.current?.init(iframeRef.current);
          }}
        />
        {previewComp}
        <Row absolute right={12} top={previewUrl ? 12 : 62}>
          {editorStoreReady && !previewUrl && !!editorStore.current?.activePage && !!previewType && previewType !== "none" && (
            <AntButton
              size="large"
              loading={isLoadingPreview}
              type="primary"
              onClick={onPressPreview}
            >
              <Text fontSize={14} fontWeight="600" colorWhite>Preview</Text>
            </AntButton>
          )}
          {previewStoreReady && previewUrl && (
            <ButtonOutline
              title="Editor"
              onPress={onPressEditor}
              textProps={{ fontWeight: 600 }}
            />
          )}
          {editorStoreReady && (
            <AntButton
              size="large"
              loading={isCheckingOut}
              type="default"
              onClick={onCheckout}
              style={{ marginLeft: 8 }}
            >
              <Text fontSize={14} fontWeight="600" color="#000">Checkout</Text>
            </AntButton>
          )}
        </Row>
      </Col>
    </ConfigProvider>
  );
};

ShopifyEditor.routeInfo = {
  title: 'MSupply Editor',
  path: '/shopify-editor/:productId',
};

export default ShopifyEditor;
