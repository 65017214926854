import { Col, Row, Text, CMSLayout, useUIState, ShimmerLoading, TouchField, Button, ResellerPrintJobItem, SwapIconEffect, modal, showPopupMessage } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen, TPrintJob, TUser } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import moment from 'moment';
import { SVG } from 'assets';
import { SimpleLineIcons, Foundation } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TimeHelper } from 'helpers';
import { Entypo, Feather, Ionicons } from '@expo/vector-icons';

const OrderPrintJobComplete: IScreen = () => {
	// const PrintJobStore = Store.usePrintJobStore();
	const { route, reset } = useNavFunc();
	// @ts-ignore
	const { amount, price, id, f_amount } = route.params || {};
	const [isCompleted, setIsCompleted] = useState(false);

	useEffect(() => {
		if (!id) return;
		(async () => {
			const res = await Store.Api.Order.markOrderComplete({
				printJobId: id,
				amount: Number(amount),
				price: Number(price),
				fulfillAmount: Number(f_amount),
			});
			if (res.data.success) {
				setIsCompleted(true);
			} else {
				showPopupMessage({
          title: '',
          content: 'Something wrong, please try again. ' + String(res.data.error),
          buttonOkText: 'OK',
          
          typeHighlight: 'danger',
          contentHighlight: 'Error'
    
        });
				// alert('Something wrong, please try again. ' + res.data.error);
				reset(SCREEN.ResellerPrintJobs);
			}
		})();
	}, [id]);
	
	return (
		<CMSLayout requireAuthen>
			<Row m2 marginBottom={0} justifyContent={'space-between'}>
				<Text h3 >Thank you for your order</Text>
				<Button
          text="Back to Job Bag"
          width={200} height={40} borderRadius={20}
          onPress={async () => {
            reset(SCREEN.ResellerPrintJobs);
          }}
        />
			</Row>
			<Col flex1 m2 mv1 p2 round1 bgWhite middle>
				{!isCompleted ? (
					<ActivityIndicator color={COLOR.MAIN} size='large' />
				) : (
					<>
						<Feather name="check-circle" size={40} color="#07A04E" />
						<Text center mt2 fontSize={18}>The print job has been paid and ready to be printed.</Text>
					</>
				)}
			</Col>
		</CMSLayout>
	)
};

OrderPrintJobComplete.routeInfo = {
	title: 'Order Completed - MSupply',
	path: '/order/print-job/:id/complete',
};

export default OrderPrintJobComplete;
