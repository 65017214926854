import React, { Fragment } from "react";
import { Col, InputField, Row, SelectField, Text } from "components";
import { COUNTRIES } from "const";

const FIELDS = [
  [
    { id: "firstName", label: "First name", require: true },
    { id: "lastName", label: "Last name", require: true },
  ],
  { id: "addressLine1", label: "Street address", placeholder: "House number and street name", require: true },
  { id: "addressLine2", placeholder: "Apartment, suite, unit etc. (optional)", label: "" },
  [
    { id: "city", label: "Town / City", require: true },
    { id: "postcode", label: "Post Code / ZIP", require: true },
  ],
  // { id: "country", label: "Country", require: true },
];

export const BillingProfile = ({ billingInformation, onChangeField }: any) => {

  const listCountries = COUNTRIES.map(v => ({ label: v.name, value: v.name }));

  return (
    <Col m1>
      <Text subtitle1 mb2>
        Billing address
      </Text>
      {FIELDS.map((field: any, idx: number) => {
        if (!field["id"]) {
          return (
            <Row marginBottom={12} marginRight={-12} key={`${field.id}${idx}`}>
              {field.map((item, jdx) => (
                <Fragment key={`${item.id}${jdx}`}>
                  <Col flex1>
                    <InputField
                      title={item.label}
                      value={billingInformation[item.id]}
                      onChangeText={(val) => {
                        onChangeField(item.id)(val);
                      }}
                      isRequired={item.require}
                      // error={formErrors[field.id]}
                      inputProps={{
                        placeholder: item.placeholder || item.label,
                      }}
                    />
                  </Col>
                  <Col width={12} />
                </Fragment>
              ))}
            </Row>
          )
        }
        return (
          <Col marginBottom={12} key={`${field.id}${idx}`}>
            <InputField
              title={field.label}
              value={billingInformation[field.id]}
              onChangeText={(val) => {
                onChangeField(field.id)(val);
              }}
              isRequired={field.require}
              // error={formErrors[field.id]}
              inputProps={{
                placeholder: field.placeholder || field.label,
              }}
            />
          </Col>
        )
      })}
      <SelectField
        title="Country"
        value={billingInformation.country}
        options={listCountries}
        isRequired
        selectProps={{ showSearch: true }}
        onChange={(value: string) => {
          onChangeField("country")(value);
        }}
      />
    </Col>
  );
};
