import { Col, Text } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

const SampleEditor : IScreen = ( ) => {
  const { navigate } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.Login, { redirect: SCREEN.SampleEditor });
      }
    });
  }, []);

  useEffect(() => {
    if (!isLoggedIn && !!user) {
      setIsLoggedIn(true);
    }
  }, [user, isLoggedIn]);

  return !isLoggedIn ? (
    <Col flex1 middle>
      <ActivityIndicator color="black" size="large" />
      <Text mt2>Please wait...</Text>
    </Col>
  ) : (
    <Col flex1>
      <iframe
        src={'https://sample-editor.msc.personify.tech/sample-editor-embed'}
        style={{
          flex: 1,
          border: 'none',
        }}
        id={'the-editor'}
        onLoad={Store.Client.editor.initOnLoad}
      />
    </Col>
  )
};

SampleEditor.routeInfo = {
  title: 'Sample Editor',
  path: '/sample-editor',
};

export default SampleEditor;
