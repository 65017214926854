import { Col, Text, Button, Input, useUIState, showPopupMessage } from 'components';
import { IScreen } from 'type';
import React, { useState, useRef, useEffect } from 'react';
// import './LoginMobile.css';
import { COLOR, SCREEN } from 'const';
import { SVG } from 'assets';
import Store from 'store';
import { useNavFunc } from 'navigation';
import {
  Animated,
  useWindowDimensions,
} from 'react-native';
import { ASSETS } from 'assets';
interface ILoginAnimationProps {
  redirect: string,
}

const RegisterMobile: IScreen = () => {
  const { reset } = useNavFunc();
  const UserStore = Store.useUserStore();
  const [email, setEmail] = useState('');
  const [agreedTOS, setAgreedTOS] = useState(true);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [fullName, setFullName] = useState('');
  const { navigate } = useNavFunc();
  const onPressLogin = () => {
    navigate(SCREEN.LoginMobile);
  }

  const onPressRegister = async (e) => {
    e.preventDefault();
    if (!agreedTOS) {
      // alert('Please tick the checkbox to agree to our terms and conditions');
      showPopupMessage({
        title: '',
        content: 'Please tick the checkbox to agree to our terms and conditions',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'warning',
        contentHighlight: 'Terms and conditions'

      });
      return;
    }
    if (!fullName|| !password || !confirmPassword) {
      // alert('Please fill in at least the following info: Full Name, Email, Password & Confirm password');
      showPopupMessage({
        title: '',
        content: 'Please fill in at least the following info: Full Name, Email, Password & Confirm password',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'error',
        contentHighlight: 'Forms validation'

      });
      return;
    }
    if (password !== confirmPassword) {
      // alert('Passwords not match');
      showPopupMessage({
        title: '',
        content: 'Passwords not match',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Forms validation'

      });
      return;
    }
    // split full name into first name and last name
    const names = fullName.split(' ');
    const firstName = names[0];
    const lastName = names.slice(1).join(' ');
    try {
      const res = await Store.Api.User.register({
        firstName,
        lastName,
        email,
        password,
        role: 'reseller',
      });
      if (res.data.error) {
        return showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',
          // 
          typeHighlight: 'danger',
          contentHighlight: 'Error'
  
        });
      }
      showPopupMessage({
        title: '',
        content: 'Register successfully.',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'
      });
      // @ts-ignore
      if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'registration',
          eventCategory: 'User',
          eventAction: 'Register',
          eventLabel: 'Successful Registration',
        });
      }
      const token = res.data.data?.token;
      Store.Client.setToken(token);
      UserStore.set({
        token,
        user: res.data.data?.publicInfo,
      });
      reset(SCREEN.Home);
    } catch(err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
    
  };

  return (
    <div className="signup">
    <div className="logo">
      <img src={ASSETS.LOGO_MOBILE} />
    </div>
    <div className="signup_title">
      <p className="signup_heading">Welcome to MSupply</p>
      <p className="signup_note">
        Kindly fill in your details below to create an account
      </p>
    </div>
    <form onSubmit={onPressRegister} className="signup_form" autoComplete="off">
      <div className="form_group_signup">
        <p className="input_name_signup">Full name</p>
        <div className="input_signup">
          <input
            type="text"
            className="form_input_signup name_signup"
            placeholder="Enter your full name"
            name="name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <div className="error_message_signup" />
        </div>
      </div>
      <div className="form_group_signup">
        <p className="input_name_signup">Email</p>
        <div className="input_signup email_signup">
          <input
            type="email"
            className="form_input_signup email_signup"
            placeholder="Enter your email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="error_message_signup" />
        </div>
      </div>
      <div className="form_group_signup">
        <p className="input_name_signup">Password</p>
        <div className="input_signup">
          <input
            type="password"
            className="form_input_signup password_signup"
            placeholder="Enter your password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className="toggle_eye_signup" onClick={()=>{
            const passwordSignup = document.querySelector('.password_signup')
            const togglePasswordSignup = document.querySelector('.toggle_eye_signup')
                if(passwordSignup.getAttribute('type') === 'password'){
                    passwordSignup.setAttribute('type', 'text');
                    togglePasswordSignup.classList.add('hide');
                }
                else {
                    passwordSignup.setAttribute('type', 'password');
                    togglePasswordSignup.classList.remove('hide');
                }
          }}/>
          <div className="error_message_signup" />
        </div>
      </div>
      <div className="form_group_signup">
        <p className="input_name_signup">Confirm password</p>
        <div className="input_signup">
          <input
            itemID="password_signup"
            type="password"
            className="form_input_signup confirm_password_signup"
            placeholder="Confirm your password"
            name="confirm_password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <div className="toggle_eye_confirm_signup" onClick={()=>{
            const confirmPasswordSignup = document.querySelector('.confirm_password_signup')
            const toggleConfirmPasswordSignup = document.querySelector('.toggle_eye_confirm_signup')
                if(confirmPasswordSignup.getAttribute('type') === 'password'){
                    confirmPasswordSignup.setAttribute('type', 'text');
                    toggleConfirmPasswordSignup.classList.add('hide');
                }
                else {
                    confirmPasswordSignup.setAttribute('type', 'password');
                    toggleConfirmPasswordSignup.classList.remove('hide');
                }
          }}/>
          <div className="error_message_signup" />
        </div>
      </div>
      <div className="form_tos_signup">
        <input type="checkbox" name="" id="tos_signup" checked={agreedTOS} onChange={e => setAgreedTOS(e.target.checked)} />
        <label htmlFor="tos">
          By ticking, you are confirming that you have read, understood and agree
          to&nbsp;
          <a href="/pages/policies" className="signup_link_signup">
             our terms and conditions
          </a>
        </label>
      </div>
      <div className="form_group_signup">
        <button type="submit" className="form_submit_signup" onClick={onPressRegister}>
          <span className="form_submit_text_signup">Register Account</span>
        </button>
      </div>
    </form>
    <p className="line">Or</p>
    <p className="signup_already">
      Already have an account?
      <a className="signup_link signup_link--underline" onClick={onPressLogin}>
        Log in
      </a>
    </p>
  </div>
  

  )
}

RegisterMobile.routeInfo = {
  title: 'RegisterMobile',
  path: '/mobile/register',
}

export default RegisterMobile;
