import React, { useEffect, useState } from 'react';
import { IScreen } from 'type';
import { CMSLayout, Col, Row, Text, useUIState, useRefState, Table, TouchField, Grid, RatioCol, Input, InputField, } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { modalConfirm } from 'helpers';
import Store from 'store';
import { Image, StyleSheet } from 'react-native';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Ionicons } from '@expo/vector-icons';
import apiClient from 'store/api-client';
import { TSyncedProduct } from 'store/api-client/type/TypeSyncedProduct';
import { omitBy } from 'lodash';
import { Select } from 'antd';

const statusFilterOpts = [
  { label: "All", value: "all" },
  { label: "Published", value: "published" },
  { label: "Not Published", value: "not_published" },
  { label: "With Images", value: "has_image" },
  { label: "Without Images", value: "no_image" },
]

const statusFilterOpts2 = [
  { label: "All status", value: "all" },
  { label: "ACTIVE", value: "ACTIVE" },
  { label: "DRAFT", value: "DRAFT" },
]

const ListSyncedProducts: IScreen = () => {
  const { navigation } = useNavFunc();
  const DesignStore = Store.useDesignStore();
  const [search, getSearch, setSearch] = useRefState("");
  const [isGrid, setGridView] = useState(window.innerWidth <= 768 ? true : false);
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, getPagination, setPagination] = useRefState({ hasNext: false, total: 1, page: 1, pageSize: 20 });
  const [products, getProducts, setProducts] = useRefState<Array<TSyncedProduct>>([])
  const [filter, getFilter, setFilter] = useRefState<string>('all');
  const [filterStatus, getFilterStatus, setFilterStatus] = useRefState<string>('all');

  const getData = async (p: number) => {
    try {
      setUI({ fetching: true, errorMes: '' });
      const _filter = getFilter();
      const _status = getFilterStatus();
      const isPublished = _filter && _filter !== 'all' ? _filter === 'published' : '';
      const hasImage = _filter && ['has_image', 'no_image'].includes(_filter) ? _filter === 'has_image' : '';
      const res = await apiClient.Api.SyncProduct.listSyncedProducts(omitBy({
        page: p || 1,
        pageSize: getPagination().pageSize || 20,
        search: getSearch(),
        isPublished,
        hasImage,
        status: _status === 'all' ? undefined : _status,
      }, (s) => s === ''))
      const { list, hasNext, total } = res.data.data;
      setPagination(s => ({
        ...s,
        hasNext, total, page: p
      }));
      setProducts(list);
      setUI({ fetching: false, errorMes: '' });
    } catch (err) {
      setUI({ fetching: false, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    getData(1);
    return unsubscribe;
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getData(1);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [search, filter, filterStatus])

  // const statusFilterOpts = useMemo(() => [
  //   { label: "Published", value: "published" },
  //   { label: "Not Published", value: "not_published" },
  // ], [])

  const confirmDelete = (record) => {
    modalConfirm({
      title: 'Delete',
      content: `${record.products.length > 0 ? 'Deleting this product will also unpublish products from stores. ' : ''}Once deleted this product cannot be recovered. Do you wish to continue?`,
      onOk: async () => {
        const res = await DesignStore.deleteDesign(record.id);
        if (res) {
          // setDesigns(s => s.filter(i => i.id !== record.id));
          // for (let i = 0; i < record.products.length; i++) {
          //   const p = record.products[i];
          //   await Store.Api.Shop.unpublishProduct({
          //     designId: record.id,
          //     productId: +p.productId,
          //     storeId: p.storeId,
          //   });
          // }
        }
      },
    })
  }

  const columns: ColumnsType<TSyncedProduct> = [
    {
      title: 'Product Image',
      key: 'productImage',
      render: (record: TSyncedProduct) => (
        <Image source={{ uri: record.images?.[0]?.url }} style={styles.productImg} resizeMode='contain' />
      )
    },
    {
      title: 'Product Name',
      dataIndex: 'title',
      key: 'name',
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.createdAt).format('DD/MM/YYYY')}</Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (record: TSyncedProduct) => (
        <Text>{record.shopifyData?.status}</Text>
      ),
    },
    // {
    //   title: '',
    //   key: 'actions',
    //   width: 160,
    //   render: (record) => {
    //     return (
    //       <Row justifyContent="flex-end">
    //         <Tooltip
    //           title={"Delete this product. Your product will be unpublished when deleted"}
    //           trigger="mouseenter"
    //           position="top"
    //         >
    //           <TouchField width={40} height={40} middle onPress={() => confirmDelete(record)}>
    //             <MaterialCommunityIcons name="trash-can" size={26} color={COLOR.FONT} />
    //           </TouchField>
    //         </Tooltip>
    //       </Row>
    //     )
    //   },
    // },
  ];

  const openProductDetail = (product: TSyncedProduct) => {
    navigation.navigate(SCREEN.SyncedProductDetail, {
      productId: product.id.replace('gid://shopify/Product/', ''),
    });
  }

  const mainContent = () => {
    if (isGrid) {
      return (
        <Col p2>
          <Grid xs='50%' md='25%' marginHorizontal={-25}>
            {products.map((val, i) => {
              return (
                <Col paddingTop={40} bgWhite shadow overflow={'hidden'} round0 m0 p0 key={val.id}
                  onPress={() => openProductDetail(val)}
                >
                  <RatioCol
                    width={'100%'}
                    ratio={1}
                  >
                    <Image source={{ uri: val.images?.[0]?.url }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
                  </RatioCol>
                  <Col height={1} backgroundColor={'rgba(0,0,0,0.05)'} />
                  <Row height={30} mt0>
                    <Text numberOfLines={2} bold>{val.title}</Text>
                  </Row>
                </Col>
              )
            })}
          </Grid>
        </Col>
      )
    }
    return (
      <Table
        data={products}
        columns={columns}
        rowKey={record => `${record.id}`}
        minWidth={800}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              openProductDetail(record);
            },
          }
        }}
        loading={fetching}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
          current: pagination.page,
          onChange(page, pageSize) {
            setPagination(s => ({
              ...s,
              page, pageSize
            }));
            getData(page);
          },
        }}
      />
    )
  };

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `List Products` },
      ]}
    >
      <Row m2 marginBottom={0} justifyContent={'space-between'}>
        <Row>
          <Text h3 >List Products</Text>
          <InputField
            width={300}
            marginLeft={24}
            marginRight={8}
            value={search}
            onChangeText={setSearch}
            inputProps={{
              placeholder: "Product name or SKU",
            }}
          />
          <Select
            size="large"
            placeholder="Filter"
            value={filter}
            style={{ width: 160 }}
            onChange={setFilter}
            options={statusFilterOpts}
            allowClear
          />
          {/* <Select
            size="large"
            placeholder="Status"
            value={filterStatus}
            style={{ width: 160 }}
            onChange={setFilterStatus}
            options={statusFilterOpts2}
            allowClear
          /> */}
        </Row>
        <Row>
          <TouchField padding={8} onPress={() => setGridView(s => !s)}>
            <Ionicons name={isGrid ? "list" : "grid"} size={22} color={COLOR.MAIN} />
          </TouchField>
        </Row>
      </Row>
      <Col flex1 m2>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : mainContent()}
      </Col>
    </CMSLayout>
  );
};

const styles = StyleSheet.create({
  productImg: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: COLOR.GREY,
  }
})

ListSyncedProducts.routeInfo = {
  title: 'List Products - MSupply',
  path: '/products',
};

export default ListSyncedProducts;
