import { Col, Row, Text, CMSLayout, useUIState, Button, useRefState, showPopupMessage, useMobileScreen } from 'components';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';

import { commonFuncs } from './ListOrders.funcs';
import { ListOrderLoadMore, ListOrderLoading } from './ListOrders.Comps';
import OrderTable from './OrderTable2';

const DeletedOrders: IScreen = () => {

  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const device = rV({ xs: 'mobile', lg: 'desktop' });
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const status = route.params?.status;
  const statusRef = useRef('');
  const sorter = useRef();
  const filter = useRef();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const resellerId = useMemo(() => {
    if (!user) return 'awaiting';
    return user?.role === 'admin' ? 'all' : UserStore.getResellerId(user);
  }, [user]);
  const OrderStore = Store.useOrderStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: false });
  const [pagination, setPagination] = useState({ hasNext: false, total: 1 });
  const [jobs, getJobs, setJobs] = useRefState<Array<TCMSOrder>>([]);
  const [selectedJobs, setSelectedJobs] = useState<{ [id: string]: boolean }>({});
  const isMobile = useMobileScreen();
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(1);

  const atLeastOneSelected = useMemo(() => {
    return Object.keys(selectedJobs).filter(id => !!selectedJobs[id]).length > 0;
  }, [selectedJobs]);

  const getData = async (p) => {
    if (resellerId === 'awaiting') return;
    try {
      const fetchingStatus = getJobs().length === 0 || statusRef.current !== status ? true : false;
      statusRef.current = status;
      setUI({ fetching: fetchingStatus, errorMes: '' });
      if (p !== 1) setIsLoadingMore(true);
      const { list, hasNext, total, error } = await OrderStore.getDeletedOrders(p, resellerId, {
        filter: filter.current,
        sorter: sorter.current,
      });
      if (error) return setUI({ fetching: true, errorMes: error });
      setPage(p);
      setPagination({ hasNext, total });
      setJobs(p === 1 ? list : [
        ...getJobs(),
        ...list,
      ]);
      setUI({ fetching: false, errorMes: '' });
      setIsLoadingMore(false);
    } catch (err) {
      setUI({ fetching: true, errorMes: String(err) });
    }
  };

  useEffect(() => {
    // will getData when screen is focused
    const unsubscribe = navigation.addListener('focus', () => {
      getData(1);
    });
    getData(1);
    return unsubscribe;
  }, [status, resellerId]);

  const listSelected = useMemo(() => {
    return Object.keys(selectedJobs).map(stringId => {
      if (!selectedJobs[stringId]) return undefined;
      const findJob = jobs.find(val => `${val.Id}_${val['Order ID']}` === stringId);
      return findJob;
    }).filter(Boolean);
  }, [selectedJobs, jobs]);

  const removeListSelected = () => {
    setJobs(l => l.filter(val => !selectedJobs[`${val.Id}_${val['Order ID']}`]));
    setSelectedJobs({});
  }

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0}>
        <Row flexWrap={'wrap'} flex1>
          <Text h3 mr1>Recycling Bin</Text>
        </Row>
        <Row>
          {atLeastOneSelected && (
            <Button
              text='Restore'
              height={30} borderRadius={15}
              onPress={async () => {
                const success = await commonFuncs.restoreOrder(listSelected);
                // console.log("success", success)
                if (Boolean(success)) {
                  const result = await showPopupMessage({
                    title: "",
                    content: `Restore success.`,
                    buttonOkText: "OK",
                    typeHighlight: "success",
                    contentHighlight: "Success.",
                  });
                  if (result && result.buttonOKClicked === true) {
                    window.location.reload();
                  }
                };
              }}
            />
          )}
        </Row>
      </Row>
      <Col flex1 round1 {...isMobile ? {
          marginHorizontal: 8,
          marginTop: 10,
        } : {
          bgWhite: true,
          m2: true,
          mv1: true,
          p2: true,
        }}>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <ListOrderLoading />
          ) : (
            <Col flex1>
              <OrderTable
                isLoading={fetching}
                jobs={jobs}
                setJobs={setJobs}
                onRefresh={() => getData(1)}
                stage="all"
                onChangeFilter={(_, _filter, _sorter) => {
                  sorter.current = _sorter;
                  filter.current = _filter;
                  getData(1);
                }}
                selectedJobs={selectedJobs}
                setSelectedJobs={setSelectedJobs}
              />
              <ListOrderLoadMore
                hasNext={pagination.hasNext}
                getData={getData}
                page={page}
                isLoadingMore={isLoadingMore}
              />
            </Col>
          )
        )}
      </Col>
    </CMSLayout>
  )
};

DeletedOrders.routeInfo = {
  title: 'Deleted Orders - MSupply',
  path: '/deleted-orders',
};

export default DeletedOrders;
