import React from 'react';
import { TDesign, TProduct } from 'type';
import { Col, modal } from 'components';
import { useWindowDimensions } from 'react-native';
import EditorIframe from 'components/widgets/editor-iframe/EditorIframe';

interface Props {
  design: TDesign;
  onDesignSave: (designId: string) => void;
  product: TProduct;
}

const EditDesignModal = ({ design, onDesignSave, product }: Props) => {
  const { width, height } = useWindowDimensions();

  return (
    <Col width={width * 0.95} height={height * 0.95} round1 bgWhite shadow overflow={'hidden'}>
      <Col flex1>
        <EditorIframe
          onClose={() => modal.hide()}
          onDesignSave={() => onDesignSave?.(design?.id)}
          design={design}
          product={product}
        />
      </Col>
    </Col>
  );
}

export default EditDesignModal;
