import { Col, Text, Grid, Row } from 'components';
import React from 'react';
import { useNavFunc } from 'navigation';
import { COLOR, COLORS, SCREEN } from 'const';
import { Pressable, StyleSheet, useWindowDimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import StepContainer from './StepContainer';
import { useDynamicResponsiveValue } from 'quickly-react';
import { ASSETS } from 'assets';
import { View } from 'react-native-animatable';
import SupportContact from './SupportContact';

const GetStarted = () => {
  const rV = useDynamicResponsiveValue();
  const { height } = useWindowDimensions();
  const hideArrow = rV({ xs: true, xl: false });
  const { navigate } = useNavFunc();
  return (
    <View>
      <Col backgroundColor={COLORS.BG_GREY}>
        <Grid xs='100%' xl='7:3' alignItems='flex-start'>
          <ScrollView style={{ height: height - 55 }} showsHorizontalScrollIndicator={false}>
            <Col padding={20} marginHorizontal={-12}>
              <StepContainer
                step="Step 1"
                title="Design a product"
                content={(
                  <Grid xs="50%" xl="0.9:1:1:1" alignItems="stretch" marginHorizontal={-10}>
                    <Row margin={10} flexGrow={1} alignItems="stretch">
                      <Col style={styles.step1Col}>
                        <img src={ASSETS.GET_STARTED_STEP_1_1} style={styles.tutorialImg} />
                        <Text
                          //@ts-ignore
                          selectable style={[styles.step1Text, { paddingHorizontal: 20 }]} >
                          1. Select a Product
                        </Text>
                        <Pressable
                          onPress={() => {
                            navigate(SCREEN.ListProducts);
                          }}
                          //@ts-ignore
                          style={{ outline: 'none' }}
                        >
                          <Text selectable style={[styles.step1Link, { marginTop: 5 }]}>
                            Go to product page
                          </Text>
                        </Pressable>
                      </Col>
                      <Col middle>
                        {!hideArrow && (
                          <View style={styles.arrowBox} >
                            <img src={ASSETS.ARROW} style={styles.arrow} />
                          </View>
                        )}
                      </Col>
                    </Row>
                    <Row margin={10} flexGrow={1} alignItems="stretch">
                      <Col style={styles.step1Col}>
                        <img src={ASSETS.GET_STARTED_STEP_1_2} style={styles.tutorialImg} />
                        <Text
                          //@ts-ignore
                          selectable style={styles.step1Text} >
                          2. Download the Artboard
                        </Text>
                      </Col>
                      <Col middle>
                        {!hideArrow && (
                          <View style={styles.arrowBox} >
                            <img src={ASSETS.ARROW} style={styles.arrow} />
                          </View>
                        )}
                      </Col>
                    </Row>
                    <Row margin={10} flexGrow={1} alignItems="stretch">
                      <Col style={styles.step1Col}>
                        <img src={ASSETS.GET_STARTED_STEP_1_3} style={styles.tutorialImg} />
                        <Text
                          //@ts-ignore
                          selectable style={styles.step1Text} >
                          3. Add your artwork to the artboard
                        </Text>
                        <Pressable
                          onPress={() => {
                            window.open("/artboards", "_blank");
                          }}
                          //@ts-ignore
                          style={{ outline: 'none' }}
                        >
                          <Text selectable style={[styles.step1Link, { marginTop: 5 }]}>
                            View artwork guide
                          </Text>
                        </Pressable>
                      </Col>
                      <Col middle>
                        {!hideArrow && (
                          <Col style={styles.arrowBox}>
                            <img src={ASSETS.ARROW} style={styles.arrow} />
                          </Col>
                        )}
                      </Col>
                    </Row>
                    <Row margin={10} flexGrow={1} alignItems="stretch" zIndex={8}>
                      <Col style={styles.step1Col}>
                        <img src={ASSETS.GET_STARTED_STEP_1_4} style={styles.tutorialImg} />
                        <Text
                          //@ts-ignore
                          selectable style={styles.step1Text} >
                          4. Add product information
                        </Text>
                      </Col>
                    </Row>
                  </Grid>
                )}
              />
              <StepContainer step="Step 2" title="Connect a store" marginTop={16} marginBottom={16}
                content={
                  <Col marginTop={10}>
                    <Grid xs='100%' xl='50%' marginHorizontal={-10}>
                      <Pressable
                        onPress={() => {
                          window.open("https://apps.shopify.com/bottled-goose-print-on-demand", "_blank")
                        }}>
                        <Row backgroundColor="rgba(150, 191, 71, 0.1)" style={styles.linkApp}>
                          <Col marginVertical={15} marginLeft={16} >
                            <img src={ASSETS.SHOPIFY} style={{ width: 31, height: 34 }} />
                            <Text selectable fontWeight={700} color="rgba(94, 143, 62, 1)" marginTop={8} >Install shopify App</Text>
                          </Col>
                          <img src={ASSETS.STEP_2_ARROW} style={{ height: 24, width: 24, marginRight: 16 }} />
                        </Row>
                      </Pressable>
                      <Pressable
                        onPress={() => {
                          navigate(SCREEN.UpsertStore, { id: "new", storetype: "etsy" })
                        }}>
                        <Row backgroundColor="rgba(244, 88, 0, 0.1)" style={styles.linkApp}>
                          <Col marginVertical={15} marginLeft={16}>
                            <img src={ASSETS.ETSY_LOGO} style={{ width: 67, height: 34 }} />
                            <Text selectable fontSize={14} fontWeight={700} color="rgba(244, 88, 0, 1)" marginTop={8}>Install Etsy App</Text>
                          </Col>
                          <img src={ASSETS.STEP_2_ARROW} style={{ height: 24, width: 24, marginRight: 16 }} />
                        </Row>
                      </Pressable>
                    </Grid>
                  </Col>
                } />
              <StepContainer step="Step 3" title="Upload your product" marginBottom={16}
                content={
                  <Grid xs='2:8' xl='3:7' marginTop={20} marginHorizontal={-10} alignItems="stretch">
                    <Col style={styles.step34ImageBorder}>
                      <img src={ASSETS.GET_STARTED_STEP_3} style={styles.step34Image} />
                      <Pressable
                        onPress={() => {
                          navigate(SCREEN.ListDessignsReseller);
                        }}
                      >
                        <Text
                          //@ts-ignore
                          selectable style={styles.step34Link}>Public a product</Text>
                      </Pressable>
                    </Col>
                    <Col style={styles.note}>
                      <Col>
                        <Row marginTop={20} marginBottom={20} >
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable>Choose a product you want to publish
                          </Text>
                        </Row>
                        <Row marginBottom={20} >
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable>Select a store you have connected
                          </Text>
                        </Row>
                        <Row marginBottom={36} >
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable>Press publish
                          </Text>
                        </Row>
                      </Col>
                    </Col>
                  </Grid>
                } />
              <StepContainer step='Step 4' title='Billing Information'
                content={
                  <Grid xs='2:8' xl='3:7' marginTop={20} alignItems="stretch" marginHorizontal={-10}>
                    <Col style={styles.step34ImageBorder}>
                      <img src={ASSETS.GET_STARTED_STEP_4} style={styles.step34Image} />
                      <Pressable
                        onPress={() => {
                          navigate(SCREEN.MyWallet);
                        }}>
                        <Text
                          //@ts-ignore
                          selectable style={styles.step34Link}>Add Billing Address</Text>
                      </Pressable>
                    </Col>
                    <Col style={styles.note}>
                      <Col>
                        <Row marginVertical={20}>
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable fontSize={14}  >Enter the amount of money you wish to add</Text>
                        </Row>
                        <Row marginBottom={20}>
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable>Add your Card
                          </Text>
                        </Row>
                        <Row marginBottom={36}  >
                          <img src={ASSETS.DOT} style={styles.dotStyle} />
                          <Text selectable>Use this to pay for orders and Samples</Text>
                        </Row>
                      </Col>
                    </Col>
                  </Grid>}
              />
            </Col >
          </ScrollView>
          <SupportContact />
        </Grid>
      </Col>
    </View >
  )
}

const styles = StyleSheet.create({
  arrowBox: {
    marginLeft: -8,
    marginRight: -28,
    width: 32,
    height: 32,
    borderRadius: 16,
    borderWidth: 1,
    justifyContent: 'center',
    backgroundColor: COLORS.BG_GREY,
    borderColor: COLOR.MAIN
  },
  step1Col: {
    backgroundColor: COLORS.LIGHT_GREY,
    flex: 1,
    alignItems: "center",
    borderWidth: 1,
    borderColor: COLORS.BORDER2,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 10
  },
  arrow: {
    width: 20,
    height: 20,
    alignSelf: 'center',
    zIndex: 10,
  },
  tutorialImg: {
    width: 109,
    height: 109,
    marginTop: 24
  },
  linkText: {
    color: COLOR.MAIN,
    fontFamily: 'sans-serif',
    marginLeft: 10
  },
  step1Text: {
    fontWeight: 700,
    textAlign: "center",
    marginVertical: 18,
    paddingHorizontal: 10
  },
  step1Link: {
    fontFamily: 'sans-serif',
    color: COLOR.MAIN,
    textAlign: 'center',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  linkApp: {
    borderColor: COLORS.BORDER2,
    borderWidth: 1,
    margin: 10,
    justifyContent: 'space-between',
    borderRadius: 8
  },
  dotStyle: {
    width: 16,
    height: 16,
    marginRight: 8
  },
  note: {
    flexGrow: 1,
    backgroundColor: COLORS.LIGHT_GREY,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLORS.BORDER2,
    padding: 24,
    margin: 10,
    alignItems: 'center'
  },
  step34ImageBorder: {
    flexGrow: 1,
    backgroundColor: COLORS.BG_GREY,
    alignItems: 'center',
    borderRadius: 8,
    borderWidth: 1,
    borderColor: COLORS.BORDER2,
    margin: 10,
    paddingHorizontal: 10
  },
  step34Image: {
    width: 109,
    height: 109,
    marginTop: 24,
    marginLeft: 36,
    marginRight: 36,
  },
  step34Link: {
    fontWeight: 700,
    color: COLOR.MAIN,
    marginTop: 20,
    marginBottom: 24,
    textAlign: 'center'
  }
})

export default GetStarted;
