import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from 'antd';
import { Col, InputField, Text } from 'components';
import { COLORS } from 'const';
import { TCMSOrder } from 'type';
import { requestMicroApi } from 'helpers/RequestHelper';

interface Props {
  order: TCMSOrder;
  onUpdate: () => void;
}

const FIELDS = [
  {
    title: 'Full name',
    key: 'name',
  },
  {
    title: 'Address line 1',
    key: 'address1',
  },
  {
    title: 'Address line 2',
    key: 'address2',
  },
  {
    title: 'Country',
    key: 'country',
  },
  {
    title: 'Town',
    key: 'town',
  },
  {
    title: 'City',
    key: 'city',
  },
  {
    title: 'Post Code / Zip Code',
    key: 'zip',
  },
]

const UpdateAddressModal = (props: Props, ref) => {
  const { order, onUpdate } = props;
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const onSaveCandleInfo = async () => {
    setIsLoading(true);
    try {
      await requestMicroApi(`/api/bg/updateOrderData?id=${order.Id}`, {
        method: 'post',
        body: JSON.stringify({
          data: {
            ...order["Raw Data"],
            shipping_address: {
              ...data,
              first_name: data.name?.split(' ')[0],
              last_name: data.name?.split(' ').slice(1).join(' '),
            },
          }
        }),
      })
      setData(null);
      setShow(false);
      onUpdate();
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    } finally {
      setIsLoading(false);
    }
  }

  useImperativeHandle(ref, () => ({
    show: (data) => {
      setData(data);
      setShow(true);
    },
  }));

  return (
    <Modal
      open={show}
      title="Shipping address"
      closable
      onCancel={() => setShow(false)}
      width={640}
      footer={[
        <Button
          size="large"
          type="primary"
          key="save-candle"
          onClick={onSaveCandleInfo}
          loading={isLoading}
          style={{ backgroundColor: COLORS.HEADLINES }}
        >
          <Text fontSize={16} fontWeight="600" colorWhite>Save</Text>
        </Button>
      ]}
    >
      <Col paddingTop={16}>
        {data && Object.keys(data)?.length > 0 && (
          <>
            {FIELDS.map(field => (
              <InputField
                key={field.key}
                title={field.title}
                value={data[field.key]}
                onChangeText={(v) => {
                  setData(s => ({
                    ...s,
                    [field.key]: v,
                  }))
                }}
                marginBottom={12}
              />
            ))}
          </>
        )}
      </Col>
    </Modal>
  );
};

export default forwardRef(UpdateAddressModal);
