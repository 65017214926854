import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, useRefState, Button, useShopifyEmbedAppFlow, showPopupMessage } from 'components';
import { COLOR, FONT, SCREEN } from 'const';
import Store, { Api } from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper, modalConfirm } from 'helpers';
import { TDesign, TGeneralData, TOnlineStore } from 'type';
import { Ionicons, EvilIcons } from '@expo/vector-icons';
import { set } from 'react-native-reanimated';

interface Props {
  design: TDesign,
  setDesign: any,
  beforePublish?: () => Promise<any>,
  cost: number,
  navigation: any,
}

const isLiveCustomizableComingSoon = true; // window.location.hostname.indexOf('bg-production') === 0;
const initialProductType = (() => {
  if (isLiveCustomizableComingSoon) return 'finished';
  return 'customizable';
})();


const PublishProductModal = ({ design: d, setDesign: setD, beforePublish, cost, navigation }: Props) => {
  const [design, updateDesign] = useState(d);
  const setDesign = (v: any) => {
    updateDesign(v);
    setD(v);
  }
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const [shops, getShops, setShops] = useRefState<Array<TOnlineStore>>([])
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  const [singleBrandUI, setSingleBrandUI] = useState({
    isLoading: false,
    brandId: '',
    pType: ''
  })
  const [productType, setProductType] = useState<'finished' | 'customizable'>(initialProductType);
  const [shouldUpdateExisting, setShouldUpdateExisting] = useState(false);
  const [needEditDesignBeforePublishedAsFinishedProduct, setNeedEditDesignBeforePublishedAsFinishedProduct] = useState(false);

  useEffect(() => {
    if (productType === 'finished' && !design.data.artworkUrls) {
      setNeedEditDesignBeforePublishedAsFinishedProduct(true);
    } else if (productType === 'customizable') {
      setNeedEditDesignBeforePublishedAsFinishedProduct(false);
    }
  }, [productType, design])

  const { isEmbedAppFlow, merchant_name, shopify_embed_app_url } = useShopifyEmbedAppFlow();

  useEffect(() => {
    if (!UserStore.user || !design?.brands?.length) return;
    (async () => {
      const { list } = await ShopStore.getList(1, UserStore.getResellerId(UserStore.user));
      if (list.length) {
        setShops(list.filter(i => design.brands.some(j => j.storeId === i.id)));
      }
    })();
  }, [UserStore.user, design?.brands]);

  useEffect(() => {
    if (!shops?.length) return;
    (async () => {
      const errors = {};
      await Promise.all(shops.map(async shop => {
        if (shop.type === 'etsy') return;
        const res = await Api.Shop.getShopifyStoreInfo({
          cms_store_id: shop.id,
        })
        if (!res.data?.success || !res.data?.data?.id) {
          errors[shop.id] = shop.name;
        }
      }))
      let errorMes = "";
      if (Object.keys(errors).length) {
        errorMes = Object.keys(errors).map(i => errorMes += `Can not access to "${errors[i]}" store`).join('\n');
      }
      setUI({ errorMes });
    })();
  }, [shops]);

  const storeWithoutProducts = useMemo(() => {
    return shops.filter(shop => !design?.products?.some(p => p.storeId === shop.id));
  }, [shops, design?.products]);

  console.log('storeWithoutProducts', storeWithoutProducts);

  const getBrand = id => {
    const find = getShops().find(v => v.id === id);
    return find?.name || id;
  }

  const publishToShopifyAppUsingIframe = () => {
    setUI({ loading: true });
    const listenerOnce = async (event) => {
      if (event?.data?.event === 'SHOPIFY_APP_CREATE_PRODUCT_RESPONSE') {
        window.removeEventListener('message', listenerOnce);
        const res = await Api.Shop.addPublishedProductToDesign({
          designId: design.id,
          storeUrl: shopify_embed_app_url,
          product: event.data.payload,
        });
        if (res.data.success && res.data.data) {
          setDesign(res.data.data);
          setUI({ loading: false });
        }
      }
    };
    window.addEventListener('message', listenerOnce);
    window.parent.postMessage({
      event: 'SHOPIFY_APP_CREATE_PRODUCT_REQUEST',
      payload: {
        designId: design.id,
        name: design.name,
        type: productType,
        price: design.resalePrice,
        description: design.description,
      }
    }, "*");
  }

  const publish = async () => {

    if (isEmbedAppFlow) return publishToShopifyAppUsingIframe();

    const selectedStoreIds = design?.brands?.map(i => i.storeId);
    if (!selectedStoreIds?.length) return showPopupMessage({
      title: '',
      content: 'Please choose at least one store',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
    //  alert('Please choose at least one store');
    setUI({ loading: true });
    if (beforePublish) await beforePublish?.();

    const packingSlips = await Promise.all(selectedStoreIds.map(async storeId => {
      try {
        const res = await Store.Api.PackingSlip.getPackingSlip({ storeId });
        return res?.data?.data;
      } catch (error) {
        return undefined;
      }
    }))
    if (packingSlips.some(i => !i?.companyName || !i.address || !i.phone)) {
      modal.hide();
      modalConfirm({
        title: 'Warning',
        content: 'Please update packing slip info before publishing product to store',
        onOk: () => navigation.navigate(SCREEN.Settings, { tabId: 'store' }),
        onCancel: () => { },
      })
      setUI({ loading: false });
      return;
    }

    const res = await Store.Api.Shop.publishProduct({
      designId: design.id,
      name: design.name,
      type: productType,
      price: design.resalePrice,
      // updateExisting: shouldUpdateExisting,
      updateExisting: design?.products?.length > 0,
      storeIds: selectedStoreIds,
      cost: cost || 0,
      variants: design.variants,
    })
    if (res.data.error) {
      setUI({ errorMes: res.data.error, loading: false });
      return;
    }
    if (res.data.data.inDraft?.length) {
      const inDraft = res.data.data.inDraft;
      console.log('inDraft', inDraft);
      const inDraftStoreNames = inDraft.map(i => i.storeName).join(', ');
      showPopupMessage({
        title: '',
        content: `Product is published, but for store(s): ${inDraftStoreNames} it is in draft state. Please go to the admin url and change the status to active manually.`,
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'
      });
    } else {
      showPopupMessage({
        title: '',
        content: 'Publish product Successfully.',
        buttonOkText: 'OK',
        // 
        typeHighlight: 'success',
        contentHighlight: 'Success'
      });
    }
    
    // alert('Publish product Successfully');

    setUI({ loading: false });
    setDesign(res.data.data);
  }

  const publishSingleBrand = async (shop: TOnlineStore, pType: 'finished' | 'customizable') => {
    setSingleBrandUI({ isLoading: true, brandId: shop.id, pType });
    const selectedStoreIds = [shop.id];
    if (beforePublish) await beforePublish?.();

    const packingSlips = await Promise.all(selectedStoreIds.map(async storeId => {
      try {
        const res = await Store.Api.PackingSlip.getPackingSlip({ storeId });
        return res?.data?.data;
      } catch (error) {
        return undefined;
      }
    }))
    if (packingSlips.some(i => !i?.companyName || !i.address || !i.phone)) {
      modal.hide();
      modalConfirm({
        title: 'Warning',
        content: 'Please update packing slip info before publishing product to store',
        onOk: () => navigation.navigate(SCREEN.Settings, { tabId: 'store' }),
        onCancel: () => { },
      })
      setSingleBrandUI({ isLoading: false, brandId: shop.id, pType });
      return;
    }

    const res = await Store.Api.Shop.publishProduct({
      designId: design.id,
      name: design.name,
      type: pType,
      price: design.resalePrice,
      // updateExisting: shouldUpdateExisting,
      updateExisting: design?.products?.filter(v => v.storeId === shop.id)?.length > 0,
      storeIds: selectedStoreIds,
      cost: cost || 0,
      variants: [],
    })
    if (res.data.error) {
      // setUI({ errorMes: res.data.error, loading: false });
      showPopupMessage({
        title: '',
        content: String(res.data.error),
        buttonOkText: 'OK',
        // 
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(res.data.error)
      return;
    }
    showPopupMessage({
      title: '',
      content: 'Publish product Successfully.',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'success',
      contentHighlight: 'Success'

    });
    // alert('Publish product Successfully');
    setSingleBrandUI({ isLoading: false, brandId: shop.id, pType });
    setDesign(res.data.data);
  }

  const unpublish = async (p) => {
    // const shouldDelete = confirm(
    //   "Are you sure you want to unpublish this product?"
    // );
    // if (!shouldDelete) return;
    showPopupMessage({
      title: "",
      content: "Are you sure you want to unpublish this product?",
      buttonOkText: "OK",
      onButtonOkClick: async () => {
        await Store.Api.Shop.unpublishProduct({
          designId: design.id,
          productId: +p.productId,
          storeId: p.storeId,
        });
        // update UI
        const newProducts = design.products.filter(
          (item) => item.productId !== p.productId
        );
        if (!newProducts?.length) modal.hide();
        setDesign({
          ...design,
          products: newProducts,
        });
      },
      typeHighlight: "danger",
      contentHighlight: "This action is not reversible.",
    });

  }

  return (
    <Col round1 bgWhite shadow p1>
      {!!design?.products && design?.products?.length > 0 ? (
        <Col mt2 p2>
          <Text bold>List of products on my stores</Text>
          <Text caption mt0>
            * Note that if the product is in draft state in the stores, the
            urls wont be accessible. Try using product admin url instead
          </Text>
          <Row
            mt2
            height={50}
            stretch
            borderBottomColor={COLOR.GREY_BG}
            borderBottomWidth={1}
          >
            <Col width={200} m1>
              <Text color={COLOR.GREY}>Brand</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Product URLs</Text>
            </Col>
            <Col width={100} m1>
              <Text color={COLOR.GREY}></Text>
            </Col>
          </Row>
          <Col>
            {design?.products.map((p, i) => {
              return (
                <Row minHeight={50} stretch key={p.productId} borderBottomColor={COLOR.GREY_BG} borderBottomWidth={0.5} mb1>
                  <Col width={200} m1>
                    <Text>{getBrand(p.storeId)}</Text>
                  </Col>
                  <Col flex1 m1>
                    <a href={p.url} target="_blank" style={{ marginBottom: 10 }}>
                      <Row>
                        <Text bold caption>Public:</Text>
                        <Text ml1 caption>{p.url}</Text>
                      </Row>
                    </a>
                    <a href={p.productAdminUrl} target="_blank">
                      <Row>
                        <Text bold caption>Admin:</Text>
                        <Text ml1 caption>{p.productAdminUrl}</Text>
                      </Row>
                    </a>
                  </Col>
                  <Col width={100} m1>
                    <Button
                      backgroundColor={'#ff0000'}
                      height={30}
                      width={100}
                      borderRadius={15}
                      text='Unpublish'
                      onPress={() => unpublish(p)}
                    />
                    {/* <TouchField
                        height={30}
                        borderRadius={15}
                        middle
                        onPress={async () => {
                          const shouldDelete = confirm(
                            "Are you sure you want to unpublish this product?"
                          );
                          if (!shouldDelete) return;
                          await Store.Api.Shop.unpublishProduct({
                            designId: design.id,
                            productId: p.productId,
                            storeId: p.storeId,
                          });
                          // update UI
                          const newProducts = design.products.filter(
                            (item) => item.productId !== p.productId
                          );
                          setDesign({
                            ...design,
                            products: newProducts,
                          });
                        }}
                      >
                        <EvilIcons name="trash" size={24} color={COLOR.FONT} />
                      </TouchField> */}
                  </Col>
                </Row>
              );
            })}
            {storeWithoutProducts.map((s, i) => {
              return (
                <Row minHeight={50} stretch key={s.id} borderBottomColor={COLOR.GREY_BG} borderBottomWidth={0.5} mb1>
                  <Col width={200} m1>
                    <Text>{s.name}</Text>
                  </Col>
                  <Col flex1 m1>
                    <Text mb1>Not published yet. Click to publish as:</Text>
                    <Row>
                      <Button
                        outline
                        bgHovered={COLOR.GREY_LIGHT}
                        height={30}
                        flex1
                        borderRadius={15}
                        text='a Finished product'
                        mr1
                        isLoading={singleBrandUI.isLoading && singleBrandUI.brandId === s.id && singleBrandUI.pType === 'finished'}
                        onPress={() => publishSingleBrand(s, 'finished')}
                      />
                      {!isLiveCustomizableComingSoon && (
                        <Button
                          outline
                          bgHovered={COLOR.GREY_LIGHT}
                          height={30}
                          flex1
                          borderRadius={15}
                          text='a Customisable product'
                          opacity={isLiveCustomizableComingSoon ? 0.5 : 1}
                          onPress={isLiveCustomizableComingSoon ? undefined : () => {
                            publishSingleBrand(s, 'customizable')
                          }}
                          isLoading={singleBrandUI.isLoading && singleBrandUI.brandId === s.id && singleBrandUI.pType === 'customizable'}
                        />
                      )}
                    </Row>

                  </Col>
                  {/* <Col width={100} m1>
                    <Button
                      backgroundColor={'#ff0000'}
                      height={30}
                      width={100}
                      borderRadius={15}
                      text='Publish'
                      // onPress={() => publish()}
                    />
                  </Col> */}
                </Row>
              );
            })}
          </Col>
        </Col>
      ) : (
        <>
          <Text m2 body1 bold center>Are you sure you want to publish this product?</Text>
          {!isLiveCustomizableComingSoon && (
            <Row middle>
              <Col width={150} m1 p1 borderThin round1 height={100}
                borderColor={productType === 'finished' ? COLOR.MAIN : COLOR.GREY}
                onPress={() => setProductType('finished')}
                backgroundColor={productType === 'finished' ? COLOR.GREY_LIGHT : 'white'}
              >
                <Text caption bold>Finished product</Text>
                <Text mt1 caption>* Customer can only buy without any customization</Text>
              </Col>
              <Col
                opacity={isLiveCustomizableComingSoon ? 0.5 : 1}
                width={150} m1 p1 borderThin round1 height={100}
                borderColor={productType === 'customizable' ? COLOR.MAIN : COLOR.GREY}
                onPress={() => {
                  if (isLiveCustomizableComingSoon) return;
                  setProductType('customizable');
                }}
                backgroundColor={productType === 'customizable' ? COLOR.GREY_LIGHT : 'white'}
              >
                <Text caption bold>Customisable{isLiveCustomizableComingSoon ? '(Coming soon)' : ''}</Text>
                <Text mt1 caption>* Customer can open the Editor to custom the design</Text>
              </Col>
            </Row>
          )}
          {!!errorMes && (
            <Col mh2>
              <Text mb2 color="red" subtitle1>{errorMes}</Text>
            </Col>
          )}

          {/* <Col mt1 p1>
          <Text subtitle1>Choose stores</Text>
          {shops.map((val, i) => {
            const isSelected = selectedShopIds.includes(val.id);
            return (
              <Col p0 key={val.id} onPress={() => {
                if (isSelected) setSelectedShopIds(v => v.filter(s => s !== val.id))
                else setSelectedShopIds(v => [...v, val.id])
              }}>
                <Row>
                  <Ionicons name={isSelected ? "radio-button-on" : "radio-button-off"} size={24} color={COLOR.FONT} />
                  <Col ml1 flex1>
                    <Text>{val.name}</Text>
                  </Col>
                </Row>
              </Col>
            )
          })}
        </Col> */}

          {/* {design?.products?.length > 0 && (
          <Col mt1 p1>
            <Text subtitle1>Settings</Text>
            <Col p0 onPress={() => setShouldUpdateExisting(v => !v)}>
              <Row>
                <Col width30 height30 middle mr0 >
                  <Ionicons name={ shouldUpdateExisting ? "radio-button-on" : "radio-button-off"} size={24} color={COLOR.FONT} />
                </Col>
                <Text caption>Update to existing products</Text>
              </Row>
            </Col>
          </Col>
        )} */}

        {needEditDesignBeforePublishedAsFinishedProduct ? (
          <Col mt2 alignSelf="center" width='60%'>
            <Text mb0>In order to publish product as a Finished product. Please Edit design and Save first</Text>
            <TouchField
              height={30}
              ph2
              middle
              borderColor={COLOR.FONT}
              borderRadius={15}
              borderWidth={1}
              backgroundColor={COLOR.FONT}
              m0
              disabled={!!errorMes}
              opacity={!!errorMes ? 0.7 : 1}
              onPress={() => {
                modal.hide();
              }}
            >
              <Row>
                {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
                <Text color={'white'}>Edit design</Text>
              </Row>
            </TouchField>
          </Col>
        ) : (
          <Row mt2 alignSelf="center">
            <TouchField
              height={30}
              width={70}
              middle
              borderColor={COLOR.FONT}
              borderRadius={15}
              borderWidth={1}
              m0
              ph2
              onPress={() => {
                if (errorMes) setUI({ errorMes: '' });
                return modal.hide();
              }}
            >
              <Text color={COLOR.FONT}>Cancel</Text>
            </TouchField>
            <TouchField
              height={30}
              ph2
              middle
              borderColor={COLOR.FONT}
              borderRadius={15}
              borderWidth={1}
              backgroundColor={COLOR.FONT}
              m0
              disabled={!!errorMes}
              opacity={!!errorMes ? 0.7 : 1}
              onPress={publish}
            >
              <Row>
                {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
                <Text color={'white'}>Publish</Text>
              </Row>
            </TouchField>
          </Row>
        )}
        </>
      )}
    </Col>
  );
};

export default PublishProductModal;
