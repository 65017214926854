import React, { useState, useEffect, useRef } from 'react';
import { Col, Text, Input, TouchField, Row, modal, useUIState, useRefState, showPopupMessage } from 'components';
import { COLOR, FONT, SCREEN } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TimeHelper, modalConfirm } from 'helpers';
import { TDesign, TGeneralData, TOnlineStore } from 'type';
import { Ionicons } from '@expo/vector-icons';

const PublishProductModal = ({ design, onPublishDone, navigation }: { design: TDesign, onPublishDone: Function, navigation: any }) => {
  const UserStore = Store.useUserStore();
  const ShopStore = Store.useShopStore();
  const [shops, getShops, setShops] = useRefState<Array<TOnlineStore>>([])
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  const [productType, setProductType] = useState<'finished' | 'customizable'>('finished');
  const [shouldUpdateExisting, setShouldUpdateExisting] = useState(false);
  const [selectedShopIds, setSelectedShopIds] = useState([]);

  useEffect(() => {
    if (!UserStore.user) return;
    (async () => {
      const { list } = await ShopStore.getList(1, UserStore.user.id);
      setShops(list);
    })();
  }, [UserStore.user]);

  const publish = async () => {
    if (selectedShopIds.length == 0) return showPopupMessage({
      title: '',
      content: 'Please choose at least one store',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'danger',
      contentHighlight: 'Error'

    });
    // alert('Please choose at least one store');
    setUI({ loading: true });

    const packingSlips = await Promise.all(selectedShopIds.map(async storeId => {
      try {
        const res = await Store.Api.PackingSlip.getPackingSlip({ storeId });
        return res?.data?.data;
      } catch (error) {
        return undefined;
      }
    }))
    if (packingSlips.some(i => !i?.companyName || !i.address || !i.phone)) {
      modalConfirm({
        title: 'Warning',
        content: 'Please update packing slip info before publishing product to store',
        onOk: () => navigation.navigate(SCREEN.Settings, { tabId: 'store' }),
        onCancel: () => { },
      })
      setUI({ loading: false });
      modal.hide();
      return;
    }

    const res = await Store.Api.Shop.publishProduct({
      designId: design.id,
      name: design.name,
      type: productType,
      updateExisting: shouldUpdateExisting,
      storeIds: selectedShopIds,
    })
    if (res.data.error) {
      setUI({ errorMes: res.data.error, loading: false });
      return;
    }
    showPopupMessage({
      title: '',
      content: 'Publish product Successfully',
      buttonOkText: 'OK',
      // 
      typeHighlight: 'success',
      contentHighlight: 'Success'

    });
    // alert('Publish product Successfully');

    setUI({ loading: false });
    modal.hide();
    onPublishDone(res.data.data);
  }

  return (
    <Col round1 bgWhite shadow p1>
      {errorMes ? (
        <Col height={200} width={320} middle>
          <Text color="red" subtitle1>{errorMes}</Text>
        </Col>
      ) : design.isCustomizable === false ? (
        <Col width={320} p1 m1 borderThin round1>
          <Text center>You can publish this design as <Text bold>a Finished product</Text></Text>
          <Text center mt2 caption>* Customer can only buy without any customization</Text>
        </Col>
      ) : (
        <Row width={320}>
          <Col flex1 m1 p1 borderThin round1 height={100}
            borderColor={productType === 'finished' ? COLOR.MAIN : COLOR.GREY}
            onPress={() => setProductType('finished')}
            backgroundColor={productType === 'finished' ? COLOR.GREY_LIGHT : 'white'}
          >
            <Text caption bold>Finished product</Text>
            <Text mt1 caption>* Customer can only buy without any customization</Text>
          </Col>
          <Col flex1 m1 p1 borderThin round1 height={100}
            borderColor={productType === 'customizable' ? COLOR.MAIN : COLOR.GREY}
            onPress={() => setProductType('customizable')}
            backgroundColor={productType === 'customizable' ? COLOR.GREY_LIGHT : 'white'}
          >
            <Text caption bold>Customisable</Text>
            <Text mt1 caption>* Customer can open the Editor to custom the design</Text>
          </Col>
        </Row>
      )}

      <Col mt1 p1>
        <Text subtitle1>Choose stores</Text>
        {shops.map((val, i) => {
          const isSelected = selectedShopIds.includes(val.id);
          return (
            <Col p0 key={val.id} onPress={() => {
              if (isSelected) setSelectedShopIds(v => v.filter(s => s !== val.id))
              else setSelectedShopIds(v => [...v, val.id])
            }}>
              <Row>
                <Ionicons name={isSelected ? "radio-button-on" : "radio-button-off"} size={24} color={COLOR.FONT} />
                <Col ml1 flex1>
                  <Text>{val.name}</Text>
                </Col>
              </Row>
            </Col>
          )
        })}
      </Col>

      {design?.products?.length > 0 && (
        <Col mt1 p1>
          <Text subtitle1>Settings</Text>
          <Col p0 onPress={() => setShouldUpdateExisting(v => !v)}>
            <Row>
              <Col width30 height30 middle mr0 >
                <Ionicons name={shouldUpdateExisting ? "radio-button-on" : "radio-button-off"} size={24} color={COLOR.FONT} />
              </Col>
              <Text caption>Update to existing products</Text>
            </Row>
          </Col>
        </Col>
      )}

      <Row middle mt2>
        <TouchField
          height={30}
          width={70}
          middle
          borderColor={COLOR.FONT}
          borderRadius={15}
          borderWidth={1}
          m0
          ph2
          onPress={() => {
            if (errorMes) return setUI({ errorMes: '' });
            return modal.hide();
          }}
        >
          <Text color={COLOR.FONT}>Cancel</Text>
        </TouchField>
        <TouchField
          height={30}
          ph2
          middle
          borderColor={COLOR.FONT}
          borderRadius={15}
          borderWidth={1}
          backgroundColor={COLOR.FONT}
          m0
          onPress={publish}
        >
          <Row>
            {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
            <Text color={'white'}>Publish</Text>
          </Row>
        </TouchField>
      </Row>
    </Col>
  );
};

export default PublishProductModal;
