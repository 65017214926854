import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Image as AntImage } from "antd";

interface Props {
}

const PreviewModal = (props: Props, ref: any) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [uri, setUri] = useState('');

  useImperativeHandle(ref, () => ({
    show: (uri: string) => {
      setUri(uri);
      setTimeout(() => {
        setPreviewVisible(true);
      }, 50);
    },
    hide: () => {
      setPreviewVisible(false);
      setUri('');
    }
  }));

  return (
    <AntImage
      style={{ display: 'none', width: 0, height: 0 }}
      src={uri}
      preview={{
        visible: previewVisible,
        src: uri,
        onVisibleChange: setPreviewVisible,
      }}
    />
  )
}

export default forwardRef(PreviewModal);
