import { Col, Text, Button, Input, useUIState, commonStyles, useShopifyEmbedAppFlow, TouchField, Row } from 'components';
import { IScreen, TUser } from 'type';
import React, { useState, useRef, useEffect } from 'react';
import { COLOR, SCREEN } from 'const';
import { SVG, ASSETS } from 'assets';
import Store from 'store';
import { useNavFunc } from 'navigation';
import {
  ActivityIndicator,
  Animated,
  Image,
} from 'react-native';
import * as Animatable from 'react-native-animatable';

const HEADER_LOGIN_WIDTH = 269 * 0.7;
const HEADER_LOGIN_HEIGHT = 79 * 0.7;

class TText extends React.Component<any>{
  render() {
    return (
      <Text {...this.props} />
    )
  }
}
const AnimatedText = Animatable.createAnimatableComponent(TText);

interface ILoginAnimationProps {
  width: Animated.Value,
  height: Animated.Value,
  logoHeight: Animated.AnimatedInterpolation,
  position: Animated.ValueXY,
  inputOpacity: Animated.Value,
  showWelcomeMessage: boolean,
  showAfterLoginLayout: boolean,
  onLoginSuccess: any,
  redirect: string,
}

const Login = ({
  width,
  height,
  position,
  inputOpacity,
  onLoginSuccess,
  showWelcomeMessage,
  logoHeight,
  showAfterLoginLayout,
  redirect
}: ILoginAnimationProps) => {
  const UserStore = Store.useUserStore();
  const { navigate } = useNavFunc();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [borderRadius, setBorderRadius] = useState(5);
  const [{ loading, errorMes }, setUI] = useUIState();
  // const { isEmbedAppFlow } = useShopifyEmbedAppFlow();

  useEffect(() => {
    inputOpacity.addListener(({ value }) => {
      if (value === 0) {
        setBorderRadius(0)
      } else if (value === 1) {
        setBorderRadius(5)
      }
    })
  }, []);

  const onPressForgot = () => {
    navigate(SCREEN.ForgotPassword);
  }

  const onPressRegister = () => {
    navigate(SCREEN.ResellerRegister, { redirect: redirect === 'undefined' ? '' : redirect });
  }

  const getAdditionData = () => {
    UserStore.checkIfResellerCompleteSetup();
  }

  const handleLogin = async () => {
    setUI({ loading: true });
    try {
      if (!email || !password) return setUI({ loading: false, errorMes: 'Please input your email and password' });
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });
      if (res.data.success && res.data.data?.token) {
        setUI({ loading: false, errorMes: '' });
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        getAdditionData();
        onLoginSuccess();
      } else {
        setUI({
          loading: false,
          errorMes: res.data.error === 'Error: Not existed' ? 'Error: User does not exist' : res.data.error,
        });
        // setUI({ loading: false, errorMes: '' });
        // onLoginSuccess();
      }
    } catch (err) {
      setUI({ loading: false, errorMes: String(err) });
    }
  };

  return (
    <Col flex1 middle backgroundColor={COLOR.GREY_BG}>
      {showAfterLoginLayout && (
        <>
          <Animatable.View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: HEADER_LOGIN_WIDTH,
              bottom: 0,
              backgroundColor: 'white',
            }}
            animation="fadeInLeftBig"
            duration={300}
          />
          <Animatable.View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: HEADER_LOGIN_HEIGHT,
              backgroundColor: 'white',
            }}
            animation="fadeInDownBig"
            duration={300}
          />
        </>
      )}
      <Animated.View
        style={{
          position: 'absolute',
          top: position.y,
          left: position.x,
          width,
          height,
          backgroundColor: 'white',
          borderRadius,
          overflow: 'hidden',
          ...commonStyles.shadow,
        }}
      >
        <Col>
          <Animated.View
            style={{
              backgroundColor: COLOR.MAIN,
              height: logoHeight,
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {/* <SVG.BottleGoose
              fill='white'
              width={'56.25%'}
            /> */}
            <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
          </Animated.View>
          {showWelcomeMessage && (
            <>
              <Col absolute top={160} right={0} left={0} bottom={0} pt={20}>
                {/* @ts-ignore */}
                <AnimatedText
                  duration={500} animation="fadeInUp"
                  center fontSize={20}
                >
                  Hi there, we're getting things ready for you.
                  {'\n'}
                  Have a productive day.
                </AnimatedText>
                <ActivityIndicator style={{ marginTop: 20 }} size="small" color={COLOR.MAIN} />
              </Col>
            </>
            

          )}
          <Animated.View
            style={{
              opacity: inputOpacity,
              padding: 20,
              position: 'absolute',
              top: 120,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          >
            {window.location.host.includes('dev.bg-production') && (
              <Col absolute top={0} left={0} right={0} backgroundColor={'red'}>
                <Text color="white" fontSize={12} fontStyle={'italic'} center>This is not the <a style={{ color: 'white', fontWeight: 'bold' }} href="https://msc.personify.tech">production</a> envrionment</Text>
              </Col>
            )}
            {errorMes ? (
              <Text color="red" bold>{errorMes}</Text>
            ) : (
              <Text>Please input your email/password</Text>
            )}
            <Input
              mt2
              value={email}
              onChange={setEmail}
              placeholder={"Input your email"}
            />
            <Input
              mt2
              value={password}
              onChange={setPassword}
              placeholder={"Input your password"}
              password
              inputProps={{
                onSubmitEditing: handleLogin,
              }}
            />
            <Button
              mt2
              solid
              text='LOGIN'
              width='100%'
              onPress={handleLogin}
              isLoading={loading}
            />
            <Row justifyContent={'center'}>
              <TouchField p1 alignSelf="center" onPress={onPressRegister}>
                <Text color={COLOR.MAIN}>Reseller Register</Text>
              </TouchField>
              <TouchField p1 alignSelf="center" onPress={onPressForgot}>
                <Text color={COLOR.MAIN}>Forgot password</Text>
              </TouchField>
            </Row>
          </Animated.View>
        </Col>
      </Animated.View>

      
    </Col>
  )
}

export default Login;
