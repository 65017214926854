import GlobalEvent from 'js-events-listener';

export const urltoFile = (url: string, filename: string, mimeType: string) =>
    fetch(url)
        .then((res) => res.blob())
        .then((blob) => new File([blob], filename, { type: mimeType }));

class Store {
    _element: HTMLIFrameElement | null = null;
    _matchListeners: any = {};

    init = (element: HTMLIFrameElement | null) => {
        this._element = element;
        if (!this._element) {
            console.log('iframe not found');
            return;
        }
        window.addEventListener("message", this._handleMessage);
        // this._sendMessage('INIT_EDITOR_SDK', {});
    }
    _ready = false;
    onReady = async () => {
        if (this._ready) return Promise.resolve();
        return new Promise((resolve, reject) => {
            const event = GlobalEvent.on('EDITOR_READY', () => {
                resolve(undefined);
                GlobalEvent.rm(event as string);
            });
        });
    }
    markReady = () => {
        setTimeout(() => {
            this._ready = true;
            GlobalEvent.emit('EDITOR_READY', undefined);
        }, 500);
    }
    _sendMessage(event: string, payload: any) {
        if (!this._element) return;
        this._element?.contentWindow?.postMessage(
            { event, payload },
            "*"
        );
    }
    _handleMessage = (e: any) => {
        if (!e.data?.payload?.match) return;
        console.log("e.data", e.data)
        if (e.data.event === 'EDITOR_CLIENT_SDK_READY') {
            this._sendMessage('INIT_EDITOR_SDK', {});
            this.markReady();
        } else if (e.data.event === 'EDITOR_METHODS_RESPONSE') {
            GlobalEvent.emit('EDITOR_METHODS_RESPONSE_' + e.data?.payload?.match, e.data.payload);
        } else if (e.data.event === 'EDITOR_ON_CHANGE') {
            if (!e.data.payload) return;
            const { selectedElements, activePage, store } = e.data.payload;
            this.json = store;
            this.activePage = this._addPageMethods(activePage);
            this.selectedElements = selectedElements || [];
            this.$$0 = selectedElements?.[0];
            if (!!this.$$0) {
                this.$$0.set = (data: any) => {
                    this._sendMessage('EDITOR_$$0_SET', data);
                }
            }
            // @ts-ignore
            window.$$0 = this.$$0;
        } else if (e.data.event == "EDITOR_UPDATE_ARTWORK_DATA") {
            if (!e.data.payload) return;
            GlobalEvent.emit('EDITOR_UPDATE_ARTWORK_DATA', e.data.payload)
        }
    }
    _storeMethod(method: string, ...args: any) {
        return new Promise((resolve, reject) => {
            const match = new Date().getTime() + '_' + Math.random().toString(36).substring(7);
            const eventId = GlobalEvent.on('EDITOR_METHODS_RESPONSE_' + match, (data: any) => {
                resolve(data?.result);
                if (typeof eventId == 'string') GlobalEvent.rm(eventId);
            });
            this._sendMessage('EDITOR_METHODS', { method, args, match });
        });
    }
    _addPageMethods(pageJSON: any, prefix = 'activePage.') {
        return {
            ...pageJSON,
            set: (data: any) => {
                this._storeMethod(prefix + 'set', data);
            },
            clone: () => {
                this._storeMethod(prefix + 'clone');
            },
            setZIndex: (zIndex: number) => {
                this._storeMethod(prefix + 'setZIndex', zIndex);
            },
            setSize: ({ width, height, useMagic }: { width: number, height: number, useMagic: boolean }) => {
                this._storeMethod(prefix + 'setSize', { width, height, useMagic });
            },
            addElement: (data: any) => {
                return this._storeMethod(prefix + 'addElement', data);
            }
        }
    }

    json: any = {};
    selectedElements: any = [];
    activePage: any = {};
    $$0: any = undefined;

    toJSON() {
        return this.json;
    }
    setSize(width: number, height: number, useMagic: boolean) {
        return this._storeMethod('setSize', width, height, useMagic);
    }
    selectPage(id: any) {
        return this._storeMethod('selectPage', id);
    }
    deletePages(ids: Array<any>) {
        return this._storeMethod('deletePages', ids);
    }
    clear() {
        return this._storeMethod('clear');
    }
    setScale(zoom: number) {
        return this._storeMethod('setScale', zoom);
    }
    selectElements(ids: Array<any>) {
        return this._storeMethod('selectElements', ids);
    }
    deleteElements(ids: Array<any>) {
        return this._storeMethod('deleteElements', ids);
    }
    getElementById(id: any) {
        return this._storeMethod('getElementById', id);
    }
    groupElements(ids: Array<any>) {
        return this._storeMethod('groupElements', ids);
    }
    ungroupElements(ids: Array<any>) {
        return this._storeMethod('ungroupElements', ids);
    }
    history = {
        redo: () => this._storeMethod('history.redo'),
        undo: () => this._storeMethod('history.undo'),
    }

    loadJSON(json: any) {
        return this._storeMethod('loadJSON', json);
    }
    waitLoading() {
        return this._storeMethod('waitLoading');
    }
    toDataURL(options: any = {}) {
        return this._storeMethod('toDataURL', options);
    }
    saveAsImage(options: any = {}) {
        return this._storeMethod('saveAsImage', options);
    }
    toPDFDataURL(options: any = {}) {
        return this._storeMethod('toPDFDataURL', options);
    }
    saveAsPDF(options: any = {}) {
        return this._storeMethod('saveAsPDF', options);
    }
    toBlob(options: any = {}) {
        return this._storeMethod('toBlob', options);
    }
    toHTML() {
        return this._storeMethod('toHTML');
    }
    setElementsPixelRatio(ratio: number) {
        return this._storeMethod('setElementsPixelRatio', ratio);
    }
    addFont({ fontFamily, url }: { fontFamily: string, url: string }) {
        return this._storeMethod('addFont', { fontFamily, url });
    }
    addPage() {
        return this._storeMethod('addPage');
    }
    removeFont(name: string) {
        return this._storeMethod('removeFont', name);
    }
    loadFont(name: string) {
        return this._storeMethod('loadFont', name);
    }
    showPanel(should: boolean) {
        // return this._storeMethod('EDITOR_SHOW_PANEL', name);
        this._sendMessage('EDITOR_SHOW_PANEL', { should });
    }
    showToolbar(should: boolean) {
        this._sendMessage('EDITOR_SHOW_TOOLBAR', { should });
    }
    setArtworkBase64(data: string) {
        this._sendMessage('EDITOR_SET_ARTWORK_BASE64', data);
    }
    generateArtwork(toBase64?: boolean) {
        this._sendMessage('EDITOR_GENERATE_ARTWORK', toBase64);
        return new Promise((resolve) => {
            const event = GlobalEvent.on('EDITOR_UPDATE_ARTWORK_DATA', (data: any) => {
                resolve(data);
                if (typeof event == 'string') GlobalEvent.rm(event as string);
            });
        });
    }
    refreshPreview() {
        this._sendMessage('EDITOR_REFRESH_PREVIEW', {});
    }
};

export const previewImageOnMask = async (mask: string, artworkBase64: string, maskColor: string = '#FFFFFF') => {
    const maskImage = new Image();
    maskImage.src = mask;
    await new Promise((resolve) => (maskImage.onload = resolve));

    const canvas = document.createElement('canvas');
    canvas.width = maskImage.width;
    canvas.height = maskImage.height;
    const ctx = canvas.getContext('2d');

    if (ctx) {
        // Draw the mask image
        ctx.drawImage(maskImage, 0, 0);

        // Get the mask data
        const maskData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // Apply custom color to the mask
        const r = parseInt(maskColor.slice(1, 3), 16);
        const g = parseInt(maskColor.slice(3, 5), 16);
        const b = parseInt(maskColor.slice(5, 7), 16);

        for (let i = 0; i < maskData.data.length; i += 4) {
            if (maskData.data[i + 3] !== 0) {
                maskData.data[i] = r;
                maskData.data[i + 1] = g;
                maskData.data[i + 2] = b;
            }
        }

        // Put the colored mask back on the canvas
        ctx.putImageData(maskData, 0, 0);

        // Draw the base64 image
        const overlayImage = new Image();
        overlayImage.src = artworkBase64;
        await new Promise((resolve) => (overlayImage.onload = resolve));

        ctx.drawImage(overlayImage, 0, 0, canvas.width, canvas.height);

        // Get the overlay image data
        const overlayData = ctx.getImageData(0, 0, canvas.width, canvas.height);

        // Combine the images
        for (let i = 0; i < maskData.data.length; i += 4) {
            // If mask pixel is transparent, make overlay pixel transparent
            if (maskData.data[i + 3] === 0) {
                overlayData.data[i + 3] = 0;
            }
        }

        // Put the combined image data back on the canvas
        ctx.putImageData(overlayData, 0, 0);
        return canvas.toDataURL()
    }
};

export const checkPolotnoData = (entryPoint: any) => {
    return !!entryPoint?.width && !!entryPoint?.height && !!entryPoint?.pages;
}

export default Store;

