import React, { useMemo, useState, useEffect } from 'react';
import { Button, Col, Input, InputField, Row, Select, Text, useMobileScreen } from 'components';
import { Select as AntSelect, DatePicker as AntDatePicker } from 'antd';
import { Feather } from '@expo/vector-icons';
import { COLOR } from 'const';
import DatePicker from 'react-datepicker';
const moment = require('moment');
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { saveAs } from 'file-saver';
import { TUser } from 'type';
import { ValHelper } from 'helpers';
import { useUserStore } from 'store/User.Store';
import ReportOrders from './components/ReportOrders';
import { Tooltip as TooltipComponent } from 'react-tippy';
import apiClient, { TInvoice } from 'store/api-client';
import dayjs from 'dayjs';

let XLSX;

interface Props {
  customers: Array<{
    client: string,
    email: string,
    first_name?: string,
    last_name?: string,
    products: Array<{
      name: string,
      product_id: number,
      variant_id: number,
      timestamp: string,
      quantity: number,
      order_number: number,
    }>
  }>,
  resellerId: string,
  listReseller: Array<TUser>,
}

const clients = [
  { id: '440478626329', name: 'MSupply', slug: 'bg' },
  { id: '665847158329', name: 'Partner In Wine', slug: 'piw' },
  { id: '578953627077', name: 'Great Harbour Gifts', slug: 'ghg' },
];

const ReportsCustomer = ({ resellerId, listReseller }: Props) => {
  const { user } = useUserStore();
  const [filterClientId, setFilterClientId] = useState<string>();
  const [filterMonth, setFilterMonth] = useState(new Date());
  const [searchKeyword, setSearchKeyword] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<TInvoice[]>([]);
  const isMobile = useMobileScreen();

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  const clientOptions = useMemo(() => {
    const combinedClients = [...clients, ...listReseller.map(i => ({
      id: i.id,
      name: [i.firstName, i.lastName].join(' '),
      slug: 'bg',
    }))]
    return combinedClients.map((c, index) => ({
      label: c.name,
      text: c.name,
      value: c.id,
      slug: c.slug,
      index,
    }));
  }, [listReseller]);

  const getReportData = async ({
    clientId = filterClientId,
    month = filterMonth,
  }) => {
    const _client = clientId
      ? clientOptions.find(i => i.value === clientId)
      : clientOptions.find(i => i.slug === resellerId || i.value === String(resellerId));

    const filterClientId = resellerId === 'all' ? _client?.value : (user.resellerId || user.id);
    const params: any = {
      month: moment(month).format("YYYYMM"),
    };
    setLoading(true);
    if (filterClientId) params.resellerId = filterClientId;
    try {
      const res = await apiClient.Api.Order.orderInvoices(params)
      setData(res.data?.invoices?.filter(i => Boolean(i.lineItems)) || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onChangeFilterClient = (d) => {
    setFilterClientId(d.value);
    getReportData({ clientId: d.value });
  }

  const onChangeFilterMonth = (date) => {
    setFilterMonth(date);
    getReportData({ month: date });
  }

  useEffect(() => {
    getReportData({});
  }, []);

  const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  const displayOrders = useMemo(() => {
    if (!data?.length) return [];
    if (!searchKeyword) return data;
    const withoutHashKeyword = searchKeyword.replace(/#/g, '');
    return data.filter(i => {
      const _customer = i.customerInfo;
      return ValHelper.isKeywordInFields([
        i.orderId, i.orderNumber, i.store, JSON.stringify(i.lineItems),
        _customer?.first_name, _customer?.last_name, _customer?.email,
      ], withoutHashKeyword);
    })
  }, [data, searchKeyword]);

  const downloadExcel = () => {
    var wb = XLSX.utils.book_new();
    const formatedData = [];
    displayOrders.forEach((v: TInvoice) => {
      const prices = v.lineItems?.map(i => {
        const printJobID = i.properties.find(i => i.name === "Print Job")?.value;
        return printJobID;
      }).filter(Boolean)
        .map(i => `£${ValHelper.formatMoney(v.prices?.[i])}`)
        .join('\n');

      formatedData.push({
        'Job Number': `#${v.orderNumber}`,
        'Store / Brand': v.store,
        'Product SKU': v.lineItems?.map(i => i.name).join('\n'),
        'Quantity': v.lineItems?.map(i => i.quantity).join('\n'),
        'Our price ex vat': prices,
        'Postage cost': v.data?.shippingFee ? `£${ValHelper.formatMoney(v.data?.shippingFee)}` : '',
        'VAT': `£${ValHelper.formatMoney(v.taxes)}`,
        'Total': `£${ValHelper.formatMoney(v.total)}`,
        'Paid': v.paidAt ? moment(v.paidAt).format("DD-MM-YYYY") : "",
        'Forfilled': v.paidAt ? moment(v.fulfilledAt).format("DD-MM-YYYY") : "",
        'Customer Name': [v.customerInfo?.first_name, v.customerInfo?.last_name].filter(Boolean).join(" "),
        'Customer Email': v.customerInfo?.email,
      })
    });
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(formatedData), "Orders");

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `export--${moment(filterMonth).format('MM/yyyy')}.xlsx`);
  };

  if (isMobile) {
    return (
      <>
        <Col padding={10} borderRadius={8} bgWhite marginHorizontal={6} marginTop={4}>
          <AntDatePicker
            size="large"
            value={dayjs(filterMonth)}
            onChange={(val) => onChangeFilterMonth(val.toDate())}
            picker="month"
            placeholder='Filter by month'
          />
          <Col height={8} />
          <InputField
            value={searchKeyword}
            onChangeText={setSearchKeyword}
            inputProps={{ placeholder: "Search Client / Order No." }}
          />
        </Col>
        <Col marginHorizontal={6} marginTop={6}>
          <Button
            iconLeft={(
              <MaterialCommunityIcons name="microsoft-excel" size={14} color={COLOR.WHITE} style={{ marginRight: 5 }} />
            )}
            text={'Export'}
            borderRadius={15} height={30} width={120}
            onPress={downloadExcel}
          />
        </Col>
        <ReportOrders data={displayOrders} isLoading={isLoading} />
      </>
    )
  }
  return (
    <Col>
      <Row zIndex={100} p1>
        <Text>Filter by month:</Text>
        <Col ml2>
          <DatePicker
            selected={filterMonth}
            onChange={onChangeFilterMonth}
            renderMonthContent={renderMonthContent}
            showMonthYearPicker
            dateFormat="MM/yyyy"
          />
        </Col>
        <TooltipComponent
          title={'Downloadable spreadsheet of your summary page. You can filter this by month'}
          position="top"
        >
          <Button
            outline
            iconLeft={(
              <MaterialCommunityIcons name="microsoft-excel" size={24} color={COLOR.MAIN} style={{ marginRight: 5 }} />
            )}
            text={'Excel'}
            m0 borderRadius={30} height={30} width={100} ml2 mr4
            onPress={downloadExcel}
          />
        </TooltipComponent>
        <Row
          ml2
          flex1
          height={30}
          borderRadius={15}
          backgroundColor={COLOR.GREY_LIGHT}
        >
          <Input
            height={30}
            borderColor="transparent"
            value={searchKeyword}
            flex1
            onChange={setSearchKeyword}
            inputProps={{
              style: {
                // @ts-ignore
                outline: "none",
                fontSize: 12,
              },
              placeholderTextColor: '#939496',
            }}
            placeholder={"Search for Client name, Order no. or Product"}
          />
          <Col
            absolute
            top={5}
            right={10}
          >
            <Feather name="search" size={18} color={COLOR.BLACK} />
          </Col>
        </Row>
      </Row>
      <ReportOrders data={displayOrders} isLoading={isLoading} />
    </Col>
  )
}

export default ReportsCustomer;
