import { Col, Text, CMSLayout, useShopifyEmbedAppFlow } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { ActivityIndicator } from 'react-native';

import Report from '../reports/Reports';
import ReportsOneClient from '../reports/ReportsOneClient';
import { RolesHelper } from 'helpers';
import apiClient from 'store/api-client';
import GetStarted from './components/GetStarted';

const HomeGetStarted : IScreen = () => {
  const { navigate, reset } = useNavFunc();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  useEffect(() => {
    if (user?.role === 'admin') {
      reset(SCREEN.Reports);
    }
  }, [user])

  const userName = [user?.firstName, user?.lastName].filter(Boolean).join(' ')
  return (
    <CMSLayout
      requireAuthen
      breadcrumbs={[{ title: `Welcome, ${userName}` }]}
      description={`Let’s setup your business`}
    >
      <Col flex1>
        {/* {renderCustomElemetns()} */}
        <GetStarted />
      </Col>
    </CMSLayout>
  )
};

HomeGetStarted.routeInfo = {
  title: 'Get started - MSupply',
  path: '/get-started',
};

export default HomeGetStarted;
