import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IScreen } from 'type';
import { ActivityIndicator } from 'react-native';
import { Col, Grid, ImageItem, Row, showPopupMessage, Table, Text } from 'components';
import { useNavFunc } from 'navigation';
import apiClient, { TCMSOrder } from 'store/api-client';
import { Button as AntButton, Descriptions, DescriptionsProps } from 'antd';
import { COLORS } from 'const';
import moment from 'moment';
import { ColumnsType } from 'antd/es/table';
import UpdateAddressModal from './OrderDetail.UpdateAddressModal';

const OrderConfirm: IScreen = () => {
  const { route } = useNavFunc();
  const { requestId }: any = route.params || {};
  const [order, setOrder] = useState<TCMSOrder>();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const addressModalRef = useRef<any>(null);
  const getOrder = async () => {
    try {
      setLoading(true);
      const res = await apiClient.Api.Order.getConfirmRequest({
        requestId,
      });
      setOrder(res.data.order);
    } catch (error) {
      showPopupMessage({
        title: "",
        content: typeof error === "string" ? error : JSON.stringify(error),
        buttonOkText: "OK",
        // 
        typeHighlight: "danger",
        contentHighlight: "Notice",
      });
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getOrder();
  }, []);

  const renderAddress = (data: any) => {
    if (!data) return '';
    return [data.address1, data.address2, data.city, data.country].filter(Boolean).join(', ')
  }
  const items: DescriptionsProps['items'] = [
    {
      key: 'orderNo',
      label: 'Order Number',
      children: order?.['Order Number'],
      span: 3,
    },
    {
      key: 'created',
      label: 'Created',
      children: moment(order?.CreatedAt).format('DD/MM/YYYY HH:mm:ss'),
      span: 3,
    },
  ];
  const customerItems: DescriptionsProps['items'] = [
    {
      key: 'customer',
      label: 'Customer Name',
      children: order?.['Raw Data']?.name,
      span: 3,
    },
    {
      key: 'shippingAddress',
      label: 'Address',
      children: renderAddress(order?.['Raw Data']?.shipping_address),
      span: 3,
    },
  ];

  const canBeProcessedItem = useMemo(() => {
    const pipelines = order?.Pipelines;
    if (!pipelines || pipelines.length === 0) return [];
    return pipelines[pipelines.length - 1].SharedData?.canBeProcessedItems || [];
  }, [order]);
  const lastPipeline = order?.Pipelines?.[order?.Pipelines?.length - 1];

  const onSubmit = async (type: string) => {
    try {
      setSubmitting(true);
      const res = await apiClient.Api.Order.updateConfirmRequest({ requestId, type });
      setOrder(res.data.order);
      showPopupMessage({
        title: "",
        content: "Update successfully",
        buttonOkText: "OK",
        // 
        typeHighlight: "success",
        contentHighlight: "Success",
      });
    } catch (error) {
      showPopupMessage({
        title: "",
        content: typeof error === "string" ? error : JSON.stringify(error),
        buttonOkText: "OK",
        // 
        typeHighlight: "danger",
        contentHighlight: "Notice",
      });
    } finally {
      setSubmitting(false);
    }
  }

  const renderLineItemPreview = (lineItemId: string) => {
    const previewUrl = canBeProcessedItem?.find(i => i.id === lineItemId)?.previewUrl;
    if (!previewUrl) return null;
    return (
      <ImageItem
        uri={previewUrl}
        style={{ width: 100, height: 100 }}
      />
    )
  }
  const lineItemColumns: ColumnsType<any> = [
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Preview',
      key: 'preview',
      render: (record) => (
        <Col width={100} height={100}>
          {renderLineItemPreview(record.id)}
        </Col>
      )
    },
  ]

  return (
    <Col flex1 m2 mv1 p2 round1 bgWhite>
      {loading ? (
        <Col flex1 middle>
          <ActivityIndicator />
        </Col>
      ) : (
        <Grid xs='100%' md='100%' lg='3:7' alignItems="flex-start">
          <Col>
            <Descriptions
              title="Order Information"
              bordered
              items={items}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
            />
          </Col>
          <Col p1>
            <Descriptions
              title="Customer Information"
              bordered
              items={customerItems}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
              extra={(
                <AntButton
                  type="text"
                  size="large"
                  onClick={() => addressModalRef.current?.show(order['Raw Data'].shipping_address)}
                >
                  <Text fontSize={16} fontWeight="600" color={COLORS.BLUE_LIGHT}>
                    Update
                  </Text>
                </AntButton>
              )}
            />
            <Col height={24} />
            <Table
              data={canBeProcessedItem}
              columns={lineItemColumns}
              rowKey={record => `${record.id}`}
              minWidth={400}
            />
            <Col height={12} />
            <Row paddingVertical={12} justifyContent="flex-end">
              {!!lastPipeline?.SharedData?.customerAproval && lastPipeline?.SharedData?.customerAproval !== "pending" && (
                <Text fontSize={16} fontWeight="600">
                  {lastPipeline?.SharedData?.customerAproval === "approved" ? "Approved" : "Rejected"}
                </Text>
              )}
              <AntButton
                size="large"
                type="primary"
                loading={submitting}
                onClick={() => onSubmit("rejected")}
                style={{ marginLeft: 16, backgroundColor: COLORS.RED }}
              >
                <Text fontSize={16} fontWeight="600" colorWhite>Reject</Text>
              </AntButton>
              <AntButton
                size="large"
                type="primary"
                loading={submitting}
                onClick={() => onSubmit("approved")}
                style={{ marginLeft: 16, backgroundColor: COLORS.GREEN }}
              >
                <Text fontSize={16} fontWeight="600" colorWhite>Approve</Text>
              </AntButton>
            </Row>
          </Col>
        </Grid>
      )}
      <UpdateAddressModal order={order} onUpdate={getOrder} ref={addressModalRef} />
    </Col>
  );
};

OrderConfirm.routeInfo = {
  title: 'MSupply Order Confirmation',
  path: '/confirm-order/:requestId',
};

export default OrderConfirm;
