import { SCREEN } from 'const';

export const adminNav = [
  {
    title: 'Summary', screen: SCREEN.Home, highlights: [], params: {},
    icon: 'home',
    url: '/',
  },
  {
    title: 'Job Bag', screen: SCREEN.ListOrders,
    icon: 'projects',
    highlights: [
      SCREEN.DeletedOrders,
    ],
    url: '/list-orders/pre-production',
  },
  {
    title: 'Product Catalogue', screen: SCREEN.ListSyncedProducts,
    highlights: [
      SCREEN.SyncedProductDetail,
    ],
    icon: 'control',
    url: '/products',
  },
  {
    title: 'Shops',
    screen: SCREEN.ListResellers,
    icon: 'people',
    url: '/shops',
  },
  // {
  //   title: 'Withdraw',
  //   screen: SCREEN.ListWithdrawRequest,
  //   icon: 'bank-account',
  //   url: '/products/list-withdraw-request',
  // },
  // {
  //   title: 'Manual Invoice',
  //   screen: SCREEN.ManualInvoice,
  //   icon: 'box',
  //   url: '/manual-invoice',
  // },
];

export const resellerNav = [
  {
    title: 'Get Started',
    screen: SCREEN.HomeGetStarted, params: {},
    icon: 'get-started',
    url: '/get-started',
  },
  {
    title: 'Summary',
    screen: SCREEN.Reports, params: {},
    icon: 'home',
    url: '/reports',
  },
  {
    title: 'My Orders',
    icon: 'transaction',
    screen: SCREEN.ListOrderForReseller,
    tooltip: 'Manage your orders from here',
    highlights: [
      SCREEN.DeletedOrders,
    ],
    url: '/my-list-orders/pre-production',
  },
  {
    title: 'Product Catalogue',
    icon: 'library',
    screen: SCREEN.ListSyncedProducts,
    tooltip: 'Choose products you want to start selling', 
    highlights: [
      SCREEN.SyncedProductDetail,
    ],
    url: '/products',
  },
  // {
  //   title: 'My Products',
  //   icon: 'product',
  //   screen: SCREEN.ListDessignsReseller,
  //   tooltip: 'Manage your products here',
  //   highlights: [
  //     SCREEN.ResellerCreateMyOwnProduct,
  //   ],
  //   url: '/my-designs',
  // },
  // {
  //   title: 'Connect stores', screen: SCREEN.UpsertStore, params: { id: "new", storetype: "shopify" },
  //   icon: '/img/connection.svg',
  //   url: '/my-stores/new?storetype=shopify',
  // },
  {
    title: 'My Stores', screen: SCREEN.ListStores, tooltip: 'Manage and add new stores',
    icon: 'store',
    url: '/my-stores',
  },
  // {
  //   title: 'My Wallet', screen: SCREEN.MyWallet,
  //   icon: 'GBP',
  //   url: '/wallet',
  // },
  // { title: 'BG Service', screen: SCREEN.BGService },
  {
    title: 'Settings', screen: SCREEN.Settings, highlights: [
      SCREEN.UpsertUsers,
      SCREEN.PackingSlipDetail,
    ],
    icon: 'setting',
    url: '/settings',
  },
];
