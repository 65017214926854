import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import { Button, Col, Grid, Input, Row, Text, MoneyValue, modal, DepositFundPopup } from 'components';
import { COLOR } from 'const';
import { AntDesign, Entypo } from '@expo/vector-icons';
import Store from 'store';
import { useStripe, CardElement, useElements } from '@stripe/react-stripe-js';
import { usePaymentStore } from 'store/Payment.Store';
import { useSettingsForm } from './FormContext';
import { TimeHelper, modalConfirm } from 'helpers';
import TransactionHistory from '../components/TransactionHistory';
import { useDynamicResponsiveValue } from 'quickly-react';
import { Elements } from "@stripe/react-stripe-js";
import { BillingProfile } from '../components/BillingProfile';


const BillingSettings = (props, ref) => {
  const { billingInformation, updateBillingInformation, checkIsChanged } = useSettingsForm();
  // const listCurencies = useListCurrencies();
  // const currencyOptions = useMemo(() => {
  //   return listCurencies.map(v => ({ label: `${v.cc} (${v.symbol})`, value: v.cc }));
  // }, [listCurencies])
  const UserStore = Store.useUserStore();
  const { stripePromise, getStripeConfig, getExistedCard, balance, stripeClientSecret, refreshBalance } = usePaymentStore();

  const stripe = useStripe();
  const elements = useElements();

  const { user } = UserStore;

  const [openAddForm, setOpenAddForm] = useState<boolean>(false);
  const [state, setState] = useState({
    depositBalance: '',
    withdrawAmount: '',
  })
  const resellerId = UserStore.getResellerId(user);
  const rV = useDynamicResponsiveValue();
  const isMobile = rV({ xs: true, lg: false });

  

  useEffect(() => {
    getStripeConfig();
    getExistedCard();
    Store.Api.User.detail({ id: resellerId }).then(res => {
      updateBillingInformation({
        ...billingInformation,
        addressLine1: res.data.data?.addressLine1,
        addressLine2: res.data.data?.addressLine2,
        city: res.data.data?.town,
        postcode: res.data.data?.postCode,
        country: res.data.data?.country,
        currency: res.data.data?.currency,
        accountName: res.data.data?.accountName,
        firstName: res.data.data?.firstName,
        lastName: res.data.data?.lastName,
      }, true)
    })
  }, []);



  const onSubmitAddcard = async () => {
    if (!openAddForm) return;
    const res = await stripe.confirmCardSetup(stripeClientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          address: {
            city: billingInformation.city,
            line1: billingInformation.addressLine1,
            line2: billingInformation.addressLine2,
            postal_code: billingInformation.postcode,
          }
        }
      }
    })
    if (res.error?.message) {
      throw new Error(res.error?.message);
    }
    getExistedCard();
  }

  useImperativeHandle(ref, () => ({
    submit: onSubmitAddcard,
  }));

  const onPressAddCard = () => {
    setOpenAddForm(true);
  }

  const onChangeField = (key) => (value) => {
    updateBillingInformation(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onChangeState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onPressAddBalance = () => {
    if (!state.depositBalance) return;
    if (checkIsChanged('billing')) {
      modalConfirm({
        title: 'Warning',
        content: 'Please save your changes before adding funds',
        onOk: () => { },
      })
      return;
    }
    modal.show(
      <DepositFundPopup
        resellerId={resellerId}
        amount={+state.depositBalance}
        currency={billingInformation.currency || 'GBP'}
      />
    )
  }

  useEffect(() => {
    onChangeState("depositBalance")('');
  }, [balance]);

  const totalBalanceRef = useRef(0);

  const withdrawFunds = async () => {
    TimeHelper.runOnceIn(500)(() => {
      modalConfirm({
        title: 'Withdraw',
        content: 'Requesting a refund will refund all the money within your wallet - are you sure you wish to continue?',
        onOk: async () => {
          const res = await Store.Api.Payment.requestWithdrawFund({
            amount: Number(balance?.balance * -1) / 100,
            currency: balance?.currency ? balance?.currency?.toUpperCase() : 'GBP',
          });
          if (res.data.success) {
            setTimeout(() => {
              refreshBalance();
              modalConfirm({
                type: 'success',
                title: '',
                cancelButtonProps: {
                  style: {
                    visibility: 'hidden',
                  }
                },
                content: 'We have sent through your request to withdraw your funds. Your refund could take up to 5-10 business days to process.',
              });
            }, 500);
          }
        },
      })
    })
  }

  return (
    <>
      {!!stripePromise && (
        <Elements stripe={stripePromise}>
          <Col flex1 overflow={"scroll"} {...props}>
            <Grid xs="100%" md="50%" alignItems={"flex-start"} mb2>
              <BillingProfile billingInformation={billingInformation} onChangeField={onChangeField} />
              <Col m1>
                <Col width={isMobile ? '100%' : '70%'}>
                  <Text subtitle1 mb2>
                    MSupply balance
                  </Text>
                  <Row>
                    <AntDesign name="checkcircle" size={18} color={COLOR.MAIN} />
                    <Text marginLeft={8}>
                      Easily cover order and production costs
                    </Text>
                  </Row>
                  <Row marginTop={4}>
                    <AntDesign name="checkcircle" size={18} color={COLOR.MAIN} />
                    <Text marginLeft={8}>
                      Order processing without the need for card payment
                    </Text>
                  </Row>
                  <MoneyValue
                    baseAmountGBP={(balance?.balance * -1 || 0) / 100}
                    toCurrency={billingInformation.currency}
                    renderingProp
                  >
                    {({ amount }) => {
                      totalBalanceRef.current = amount;
                      return (
                        <Col backgroundColor={amount < 0 ? '#BC0100' : COLOR.MAIN} paddingTop={10} paddingBottom={20} borderRadius={8} marginTop={20}>
                          <Text marginLeft={14} subtitle1 mb2 color={COLOR.WHITE}>
                            Current Balance
                          </Text>
                          <Row marginLeft={14}>
                            <Entypo name="wallet" size={44} color={COLOR.WHITE} />
                            <Text marginLeft={24} h2 color={COLOR.WHITE}>
                              {String(billingInformation.currency || 'GBP').toUpperCase()}
                            </Text>
                            <Text marginLeft={6} h2 color={COLOR.WHITE}>{amount}</Text>
                          </Row>
                        </Col>
                      )
                    }}
                  </MoneyValue>

                  <Row mt1 marginLeft={-5} marginRight={-5}>
                    <Input
                      placeholder="+ Enter amount"
                      value={state.depositBalance}
                      onChange={onChangeState("depositBalance")}
                      flex1
                      m0
                    />
                    <Button
                      text='Add funds'
                      solid
                      opacity={Number(state.depositBalance) ? 1 : 0.7}
                      disabled={!Number(state.depositBalance)}
                      onPress={onPressAddBalance}
                      alignSelf='center'
                      width={120}
                      height={45}
                      nativeID='add-fund'
                      m0
                    />
                  </Row>
                  <Row mt1>
                    <Button
                      text='Withdraw funds'
                      solid
                      onPress={withdrawFunds}
                      width="100%"
                      height={45}
                      opacity={balance?.balance >= 0 ? 0.7 : 1}
                      disabled={balance?.balance >= 0}
                    />
                  </Row>
                </Col>
              </Col>
            </Grid>
            <TransactionHistory />
          </Col>
          {/* POWERED BY STRIPE */}
          <Col position={'fixed'} backgroundColor={COLOR.MAIN} bottom={20} right={30} paddingVertical={10} paddingHorizontal={20} round1>
            <Text colorWhite>Powered by <Text bold colorWhite fontSize={20}>stripe</Text></Text>
          </Col>
        </Elements>
      )}
    </>
  );
};

export default forwardRef(BillingSettings);
