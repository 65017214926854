import { Col, Text, Button, Input, TouchField, commonStyles } from 'components';
import { IScreen } from 'type';
import React, { useState } from 'react';
import { COLOR, SCREEN } from 'const';
import { ASSETS } from 'assets';
import { useNavFunc } from 'navigation';
import Store from 'store';
import { Image, View } from 'react-native';

const ForgotPassword: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { code }: any = route.params || {};
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMes, setErrorMes] = useState('');
  const [showMessage, setShowMessage] = useState(false);

  const onSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const res = await Store.Api.User.forgotPassword({
        email,
      });
      if (!res.data.success) {
        setErrorMes(res.data.error);
      } else {
        setShowMessage(true);
      }
    } catch (error) {
      setErrorMes(error.message || JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  }

  const onPressLogin = () => {
    navigate(SCREEN.Login);
  }

  return (
    <Col flex1 middle backgroundColor={COLOR.GREY_BG}>
      <Col bgWhite borderRadius={8} width={320} style={{ ...commonStyles.shadow, overflow: "hidden" }}>
        <View
          style={{
            backgroundColor: COLOR.MAIN,
            height: 120,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image source={ASSETS.LOGO} style={{ width: '65%', height: '80%' }} resizeMode='contain' />
        </View>
        {showMessage ?
          <Col middle padding={20}>
            <Text textAlign={"center"}>Check your email and reset your password</Text>
          </Col>
          :
          <Col padding={20} paddingBottom={4}>
            <Text>What is your email?</Text>
            <Input
              mt2
              value={email}
              onChange={setEmail}
              placeholder={"Input your email"}
              inputProps={{ nativeID: 'input-your-email' }}
            />
            {!!errorMes &&
              <Text color="red" body1 bold mv1>{errorMes}</Text>
            }
            <Button
              mt2
              solid
              text='SUBMIT'
              width='100%'
              isLoading={loading}
              disabled={!email}
              onPress={onSubmit}
            />
            <TouchField p1 alignSelf="center" onPress={onPressLogin}>
              <Text color={COLOR.MAIN}>Login</Text>
            </TouchField>
          </Col>
        }
      </Col>
    </Col>
  )
}

ForgotPassword.routeInfo = {
  title: 'Forgot Password',
  path: '/forgot-password',
}

export default ForgotPassword;
