import { Col, Row, Text, CMSLayout, useUIState, TouchField, Button, Grid, modal, Table, BgIcon } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { IScreen, TCMSOrder } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN } from 'const';
import { Image } from 'react-native';
import moment from 'moment';
import { FontAwesome5 } from '@expo/vector-icons';
import { ValHelper } from 'helpers';
import { ListOrderLoading } from './ListOrders.Comps';
import apiClient from 'store/api-client';
import { Descriptions, DescriptionsProps } from 'antd';
import { ColumnsType } from 'antd/es/table';
import OverrideModal from './OrderDetail.OverrideModal';

const shippingServices = {
  RM24: "Royal Mail 24",
  RM48: "Royal Mail 48",
  DPD: "DPD",
  RMInternational: 'Royal Mail International',
};

const shippingPrice = {
  RM24: 5.2,
  // RM48: 4.18,
  RM48: 4.2,
  DPD: 10.99,
};
const EUROPEAN_COUNTRIES = ["Russia", "Germany", "France", "Italy", "Spain", "Poland", "Ukraine", "Romania", "Netherlands", "Belgium", "Sweden", "Czech Republic (Czechia)", "Greece", "Portugal", "Hungary", "Belarus", "Austria", "Switzerland", "Serbia", "Bulgaria", "Denmark", "Slovakia", "Finland", "Norway", "Ireland", "Croatia", "Moldova", "Bosnia and Herzegovina", "Albania", "Lithuania", "Slovenia", "North Macedonia", "Latvia", "Estonia", "Luxembourg", "Montenegro", "Malta", "Iceland", "Andorra", "Liechtenstein", "Monaco", "San Marino", "Holy See"];

const shippingPriceEurope = {
  RM24: 8.99,
  RM48: 15,
  RMInternational: 15,
  DPD: 14.99,
};
const shippingPriceTheRestOfTheWorld = {
  RM24: 8.99,
  RM48: 22,
  RMInternational: 22,
  DPD: 14.99,
};

const OrderDetail: IScreen = ({ }) => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { orderId } = route.params || {};

  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const resellerId = user?.role === 'admin' ? 'all' : user?.role === 'reseller' ? user?.id
    : user?.resellerId;

  const OrderStore = Store.useOrderStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({ fetching: true });
  const [order, setOrder] = useState<TCMSOrder>();
  const [previewData, setPreviewData] = useState<any>({});
  const [trackingNumber, setTrackingNumber] = useState('');
  const [customShippingMethod, setCustomShippingMethod] = useState('');
  const [shippedTime, setShippedTime] = useState('');
  const [invoiceData, setInvoiceData] = useState<any>({});
  const [lineItems, setLineItems] = useState<any[]>([]);


  const getData = async () => {
    setUI({ fetching: true });
    try {
      const res = await OrderStore.findByOrderNo('', resellerId, orderId);
      const findClient = OrderStore.clients.find(v => v['Client ID'] === res[0]["Client ID"]);
      const fetchPipelines = await OrderStore.getPipelinesByOrderId("msc", orderId);
      if (res[0]) {
        let invoice = await apiClient.Api.Payment.getOrderInvoice({ orderId });
        if (!invoice.data?.data) {
          await OrderStore.getChargeAmount([{
            ...res[0],
            Pipelines: fetchPipelines,
          }])
          invoice = await apiClient.Api.Payment.getOrderInvoice({ orderId });
        }
        setInvoiceData(invoice.data?.data);
      }
      setOrder({
        ...res[0],
        Pipelines: fetchPipelines,
      });
      setUI({ fetching: false });
      const lineItemsWithPrintJob = await Promise.all(res[0]?.['Raw Data'].line_items.map(async (v) => {
        const lineItem = { ...v }
        if (res[0]?.['Item Supported']?.includes(String(v.id))) {
          const hasColorName = v.properties?.some(i => i.name === "Color Name");
          if (!hasColorName) {
            const printJobId = v.properties?.find(i => i.name === "Print Job")?.value;
            if (printJobId) {
              const printJobRes = await apiClient.Api.PrintJob.detail({ id: printJobId });
              const canvas = printJobRes.data?.data?.data?.design?.canvas;
              let colorName = canvas.pages[0].children.find(i => i.type === "text" && !!i.custom?.colorName)?.custom?.colorName;
              if (colorName) {
                lineItem.properties = [...(lineItem.properties || []), { name: "Color Name", value: colorName }];
              }
            }
          }
          return lineItem;
        }
        return;
      }))
      setLineItems(lineItemsWithPrintJob.filter(Boolean));
    } catch (err) {
      setUI({ fetching: false });
    }
  }

  useEffect(() => {
    if (!resellerId) return;
    getData();
  }, [orderId, resellerId]);

  useEffect(() => {
    let didSetTrackingNumber = false
    const last = order?.Pipelines[order?.Pipelines.length - 1];
    if (last && last.SharedData && last.SharedData.trackingNumber) {
      setTrackingNumber(last.SharedData.trackingNumber);
      didSetTrackingNumber = true;
    }
    if (last && last.SharedData && last.SharedData.shippingMethod) {
      setCustomShippingMethod(last.SharedData.shippingMethod);
    }
    if (last?.SharedData?.shippedOn) {
      setShippedTime(
        moment(last?.SharedData?.shippedOn).format('DD/MM/YYYY HH:mm')
      )
    }
    if (!didSetTrackingNumber && !!last?.SharedData?.trackingNumber) {
      setTrackingNumber(last.SharedData.trackingNumber)
    }
  }, [order]);

  const canBeProcessedItem = useMemo(() => {
    const pipelines = order?.Pipelines;
    if (!pipelines || pipelines.length === 0) return [];
    return pipelines[pipelines.length - 1].SharedData?.canBeProcessedItems || [];
  }, [order]);

  useEffect(() => {
    if (canBeProcessedItem.length === 0) return;
    const thumbMatch = {};
    canBeProcessedItem.forEach(v => {
      if (v.previewUrl) {
        thumbMatch[v.id] = v.previewUrl;
      }
    });
    setPreviewData(thumbMatch);
  }, [canBeProcessedItem]);

  const renderAddress = (data: any) => {
    if (!data) return '';
    return [data.address1, data.address2, data.city, data.country].filter(Boolean).join(', ')
  }

  const goBack = () => {
    // check if can go back
    if (navigation.canGoBack()) {
      navigation.goBack();
      return;
    }
    navigation.reset({
      index: 0,
      routes: [{ name: SCREEN.ListOrders }],
    });
  }

  const unPaidAmount = useMemo(() => {
    const shippingMethod = invoiceData?.shippingAddress?.country !== 'United Kingdom'
      ? 'Royal Mail International' : 'RM48';

    const postage = (() => {
      const address = invoiceData?.shippingAddress;
      if (address?.country === 'United Kingdom') {
        return shippingPrice.RM48 || 0;
      }
      // TODO: handle international shipping fee
      if (EUROPEAN_COUNTRIES.includes(address?.country)) {
        return shippingPriceEurope.RM48 || 0;
      }
      return shippingPriceTheRestOfTheWorld.RM48 || 0;
    })();

    const productPrice = invoiceData?.total ? invoiceData?.total - invoiceData?.taxes : 0;

    const vat = invoiceData?.shippingAddress?.country !== 'United Kingdom' ? 0 : (
      productPrice * 0.2 + postage * 0.2
    )

    const total = productPrice + postage + vat;

    return {
      productPrice,
      shippingMethod,
      postage,
      vat,
      total
    }
  }, [invoiceData])

  const invoiceInfo = useMemo(() => {
    let _productPrice = invoiceData?.total ? invoiceData?.total - invoiceData?.taxes : 0;
    let _shippingFee = invoiceData?.data?.shippingFee;
    let _vat = invoiceData?.taxes ? (invoiceData?.total - invoiceData?.taxes + _shippingFee) * 0.2 : 0;
    let _total = !invoiceData?.paidAt ? unPaidAmount.total : invoiceData?.total + (_shippingFee || 0) * (invoiceData?.taxes ? 1.2 : 1);

    if (invoiceData?.stripeInvoice) {
      const item = invoiceData?.stripeInvoice;

      const shipItem = item.lines?.data?.find(i => i.description?.includes("Shipping fee"));
      const taxItem = item.lines?.data?.find(i => i.description?.includes("VAT "));

      if (!shipItem && !taxItem) {
        _productPrice = item.total / 100;
      } else {
        const productsOnly = item.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
        _productPrice = productsOnly / 100;
      }
      if (shipItem) {
        _shippingFee = shipItem?.amount / 100;
      }
      if (taxItem) {
        _vat = taxItem?.amount / 100;
      }
      _total = item.total / 100;
    }
    return {
      productPrice: _productPrice,
      shippingFee: _shippingFee,
      vat: _vat,
      total: _total,
    }
  }, [invoiceData, unPaidAmount]);

  const renderJob = () => {
    if (fetching) return (
      <Col flex1>
        <Text>Loading order data..</Text>
      </Col>
    );
    if (!order) {
      return (
        <Col flex1>
          <Text>No order found</Text>
        </Col>
      )
    }
    const renderLineItemPreview = (lineItemId) => {
      if (!previewData[lineItemId]) return null;
      return (
        <Image source={{ uri: previewData[lineItemId] }} style={{ width: '100%', height: '100%' }} resizeMode='contain' />
      )
    }
    const isPaid = order.Pipelines[order.Pipelines.length - 1]?.SharedData.isPaid;
    const items: DescriptionsProps['items'] = [
      {
        key: 'stage',
        label: 'Stage',
        children: order.Stage,
        span: 3,
      },
      {
        key: 'status',
        label: 'Status',
        children: order.StageStatus,
        span: 3,
      },
      {
        key: 'orderNo',
        label: 'Order Number',
        children: order['Order Number'],
        span: 3,
      },
      {
        key: 'created',
        label: 'Created',
        children: moment(order.CreatedAt).format('DD/MM/YYYY HH:mm:ss'),
        span: 3,
      },
      {
        key: 'paid',
        label: 'Paid',
        children: isPaid ? moment(order.UpdatedAt).format('DD/MM/YYYY HH:mm:ss') : "",
        span: 3,
      },
      {
        key: 'shipped',
        label: 'Shipped',
        children: shippedTime,
        span: 3,
      },
      {
        key: 'shippingMethod',
        label: 'Shipping Method',
        children: (() => {
          if (customShippingMethod) return customShippingMethod;
          console.log('invoiceData', invoiceData);
          const shippingService = invoiceData?.data?.shippingService;
          if (!shippingService) return unPaidAmount.shippingMethod;
          if (shippingService === 'RM48' && invoiceData?.shippingAddress?.country !== 'United Kingdom') {
            return 'Royal Mail International';
          }
          return shippingService ? shippingServices[shippingService] : "";
        })(),
        span: 3,
      },
      {
        key: 'trackingNumber',
        label: 'Tracking Number',
        children: <a href={'https://www.royalmail.com/track-your-item#/tracking-results/' + trackingNumber} target="_blank">{trackingNumber}</a>,
        span: 3,
      },
      // {
      //   key: 'paymentMethod',
      //   label: 'Payment Method',
      //   children: isPaid ? 'Wallet Credit' : (
      //     (order.Status === 'Accepted' || order.Status === 'Fulfilled') ? 'Free of charge' : ''
      //   ),
      //   span: 3,
      // },
      {
        key: 'productPrice',
        label: 'Product Price',
        children: invoiceData.manualInvoiceData?.productPrice
          ? `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.productPrice)}`
          : `£${ValHelper.formatMoney(invoiceInfo.productPrice)}`,
        span: 3,
      },
      {
        key: 'postage',
        label: 'Postage',
        // separated shipping fee and tax
        children: (() => {
          if (invoiceData.manualInvoiceData?.postage)
            return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.postage)}`;
          return !invoiceData?.paidAt ? (
            `£${ValHelper.formatMoney(unPaidAmount.postage)}`
          ) : (
            `£${ValHelper.formatMoney(invoiceInfo.shippingFee)}`
          )
        })(),
        span: 3,
      },
      {
        key: 'vat',
        label: (() => {
          if (invoiceData.manualInvoiceData?.vat) return 'VAT 20%';
          return invoiceData?.taxes ? 'VAT 20%' : 'VAT not applicable';
        })(),
        children: (() => {
          if (invoiceData.manualInvoiceData?.vat) return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.vat)}`;
          return `£${ValHelper.formatMoney(invoiceInfo.vat)}`;
        })(),
        span: 3,
      },
      {
        key: 'total',
        label: 'Total',
        children: (() => {
          if (invoiceData.manualInvoiceData?.total) return `£${ValHelper.formatMoney(invoiceData.manualInvoiceData?.total)}`;
          return `£${ValHelper.formatMoney(invoiceInfo.total)}`;
        })(),
        span: 3,
      },
    ];
    const customerItems: DescriptionsProps['items'] = [
      {
        key: 'customer',
        label: 'Customer Name',
        children: order['Customer Name'],
        span: 3,
      },
      {
        key: 'shippingAddress',
        label: 'Shipping Address',
        children: renderAddress(order['Raw Data'].shipping_address),
        span: 3,
      },
      {
        key: 'billingAddress',
        label: 'Billing Address',
        children: renderAddress(order['Raw Data'].billing_address),
        span: 3,
      },
    ];

    const lineItemColumns: ColumnsType<any> = [
      {
        title: 'Product',
        key: 'product',
        render: (record) => (
          <Col width={50} height={50}>
            {renderLineItemPreview(record.id)}
          </Col>
        )
      },
      {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Properties',
        key: 'properties',
        render: (record) => {
          return (
            <Col>
              {record.properties.map(p => (
                <Text key={p.name}>
                  {p.name}: {p.value?.value || p.value}
                </Text>
              ))}
            </Col>
          );
        }
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'Your sell price',
        key: 'resalePrice',
        render: (record) => {
          const linePrice = record.price;
          return `£${ValHelper.formatMoney(linePrice)}`;
        }
      },
    ]

    return (
      <Col flex1>
        <Grid xs='100%' md='100%' lg='3:7' alignItems="flex-start">
          <Col p>
            <Descriptions
              title="Order Information"
              bordered
              items={items}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
            />
            {user?.role === 'admin' && (
              <TouchField
                width={140}
                flexDirection={'row'} p1 mt2
                onPress={() => {
                  modal.show(<OverrideModal invoice={invoiceData} />)
                }}
              >
                <BgIcon name='edit' />
                <Text ml0>Admin override</Text>
              </TouchField>
            )}
          </Col>
          <Col p1>
            <Descriptions
              title="Customer Information"
              bordered
              items={customerItems}
              contentStyle={{ fontSize: 15 }}
              labelStyle={{ fontSize: 15 }}
            />
            <Col height={24} />
            <Table
              data={lineItems}
              columns={lineItemColumns}
              rowKey={record => `${record.id}`}
              minWidth={800}
            />
            <Col height={24} />
          </Col>
        </Grid>
      </Col>
    )
  }

  const sourceId = order?.['Order Source ID'];
  const orderNumber = order?.['Raw Data']?.order_number;

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder='Search by Order No'
      onSearchInput={(orderNo) => {
        navigation.navigate(SCREEN.OrderSearchResult, { query: orderNo })
      }}
      searchAutoClear
    >
      <Row m2 marginBottom={0}>
        <Button
          solid
          height={30} paddingHorizontal={8}
          iconLeft={<FontAwesome5 name="chevron-left" size={12} color="white" />}
          text='Back'
          fitContent
          mr1
          onPress={goBack}
          borderRadius={15}
        />
        <Text h3 >Order tracking {'>'} Order #{sourceId ? orderId : orderNumber} {!!(sourceId || order?.['Order ID']) ? ' ID: ' + (sourceId || order?.['Order ID']) : ''}</Text>
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>{errorMes}</Text>
          </Col>
        ) : (
          fetching ? (
            <ListOrderLoading />
          ) : (
            renderJob()
          )
        )}
      </Col>
    </CMSLayout>
  )
};

OrderDetail.routeInfo = {
  title: 'Order detail - MSupply',
  path: '/order/:orderId',
};

export default OrderDetail;
