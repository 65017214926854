import _ from 'lodash';

class ValHelper {
  isDev = typeof window === undefined ? true : window.location.hostname.includes('dev.')|| process.env.NODE_ENV === 'development'
  editorHost = "https://msc-personify-editor.vercel.app";

  jsUCFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);

  formatBalance = (balance: number) => (Math.round(balance * 100) / 100).toFixed(2);

  isKeywordInFields = (values: string[], keyword: string) => {
    if (!values?.length) return false;
    if (!keyword) return true;
    const txt = values.map(i => String(i).toLowerCase()).join('|');
    return txt.includes(keyword.toLowerCase());
  }

  parseClientId = (id) => {
    if (Number(id)) return id;
    if (id === 'partnet-in-wine' || id === 'piw') return '665847158329';
    if (id === 'ghg') return '578953627077';
    return '440478626329';
  }

  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  formatMoney = (amount: string | number) => {
    const _amount = Number(amount || 0);
    if (Number.isNaN(_amount)) return amount || "0.00";
    return Number(amount || 0).toFixed(2)
  };

  objectToQueryString = (obj) => {
    return _.map(obj, (value, key) => {
      return encodeURIComponent(key) + '=' + encodeURIComponent(value);
    }).join('&');
  };

  toDisplayStage = (s?: string) => {
    if (!s || typeof s !== "string") return undefined;
    return s.split("-").map(_.capitalize).join(" ");
  };

  noSingleLastWordHTML = (value: string) => {
    if (typeof value !== 'string') return '';
    if (!value.trim().includes(" ")) return value;
    const parts = value.trim().split(" ");
    const length = parts.length;
    parts[length-2] = parts[length-2] + `\u00A0` + parts[length-1];
    parts[length-1] = '';
    return parts.join(" ");
  }

  calculateDPI = (widthMM: number, heightMM: number): number => {
    const minDPI = 300;
    const inchToMM = 25.4;
    
    let dpi = minDPI;
    while (true) {
      const pixelPerMM = dpi / inchToMM;
      const calculatedWidth = widthMM * pixelPerMM;
      const calculatedHeight = heightMM * pixelPerMM;
      const roundWidth = Math.round(calculatedWidth);
      const roundHeight = Math.round(calculatedHeight);

      const convertBackWidth = (roundWidth / pixelPerMM).toFixed(2);
      const convertBackHeight = (roundHeight / pixelPerMM).toFixed(2);

      if (convertBackWidth === widthMM.toFixed(2) && convertBackHeight === heightMM.toFixed(2)) {
        return dpi;
      }

      dpi += 1;
      if (dpi >= 500) return minDPI;
    }
  }
}

export default new ValHelper();
