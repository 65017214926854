import React, { useEffect, useState } from 'react';
import { Button, Col, InputField, SelectField, TableForm, Text } from 'components';
import { TProduct } from 'type';
import { COLOR } from 'const';

interface IProps {
  product?: TProduct;
  updateProduct?: any;
  updateProductData?: any;
}

interface IConfig {
  title: string;
  type: string;
  options?: Array<{ label: string, value: string, image?: string }>;
}

const FIELD_TYPES = [
  { label: 'Input', value: 'input' },
  { label: 'Select', value: 'select' },
]

const UpsertProductConfig = ({ product, updateProduct, updateProductData }: IProps) => {
  const [configs, setConfigs] = useState<IConfig[]>(product?.data?.configs || [])

  useEffect(() => {
    updateProductData({ configs })
  }, [configs])

  const onChangeFieldType = (val: string, idx: number) => {
    setConfigs(s => {
      const arr = [...s]
      arr[idx].type = val
      return arr
    })
  }

  const onChangeConfigTitle = (val: string, idx: number) => {
    setConfigs(s => {
      const arr = [...s]
      arr[idx].title = val
      return arr
    })
  }

  const onAddConfig = () => {
    setConfigs(s => [...s, {
      title: '',
      type: 'input',
      options: [],
    }])
  }

  const onChangeConfigOptions = (data: any, idx: number) => {
    setConfigs(s => {
      const arr = [...s]
      arr[idx].options = data
      return arr
    })
  }

  return (
    <Col flex1 bgWhite>
      <Text caption mb1>* Config options for customer to select</Text>
      <Button
        mb1
        text={"Add config"}
        onPress={onAddConfig}
      />

      {configs.map((config, idx) => (
        <Col key={idx} borderTopWidth={1} borderColor={COLOR.BORDER} mt1>
          <Text mv2 h4 semiBold>{config.title || `Config ${idx + 1}`}</Text>
          <InputField
            title="Title"
            value={config.title}
            onChangeText={(val: string) => onChangeConfigTitle(val, idx)}
            mb1
          />
          <SelectField
            title="Field type"
            options={FIELD_TYPES}
            value={config.type}
            onChange={(val: string) => onChangeFieldType(val, idx)}
            isMultiple={false}
            mb1
          />
          {config.type === 'select' && (
            <TableForm
              addBtnTitle="Add option"
              data={config.options}
              columns={[
                { key: 'label', label: 'Label', flex: 1 },
                { key: 'value', label: 'Value', flex: 1 },
                { key: 'image', label: 'Image', isUpload: true, width: 120 },
              ]}
              editable
              onChange={(data: any) => onChangeConfigOptions(data, idx)}
              isBottomBtn
              marginBottom={12}
            />
          )}
        </Col>
      ))}
    </Col>
  )
};

export default UpsertProductConfig;
