import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IScreen } from 'type';
import { ActivityIndicator } from 'react-native';
import { ButtonOutline, Col, Row, Text } from 'components';
import { useNavFunc } from 'navigation';
import apiClient, { TDesign, TPrintJob, TProduct } from 'store/api-client';
import { MICRO_SERVICES_HOST } from 'store/Order.Store';
import { Button as AntButton } from 'antd';
import { COLOR } from 'const';
import Store, { checkPolotnoData, previewImageOnMask } from 'helpers/EditorSDK';
import { BLEND_SUPPORT_URL, BLEND_URL, getPreviewPTSAction, renderAngle, uploadFileToS3 } from 'components/widgets/editor-iframe/utils';
import { isEmpty } from 'lodash';

const tShirtMask = require("../../assets/img/t-shirt-mask.png");

const ShopifyEditor: IScreen = () => {
  const { route } = useNavFunc();
  const { designId, printJobId, customArtwork, productName, storeUrl, storeName, storeProductId, ...restParams }: any = route.params || {};
  const [productType, setProductType] = useState<string | null>(null);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const iframePreviewRef = useRef<HTMLIFrameElement>(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [editorStoreReady, setEditorStoreReady] = useState(false);
  const [previewStoreReady, setPreviewStoreReady] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isCheckingOut, setIsCheckingOut] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [productData, setProductData] = useState<TProduct | null>(null);
  const [designData, setDesignData] = useState<TDesign | null>(null);
  const editorStore = useRef(new Store());
  const previewStore = useRef(new Store());
  const storeReadyRef = useRef(false);
  const savedCanvasRef = useRef('');

  useEffect(() => {
    editorStore.current?.onReady().then(() => {
      if (storeReadyRef.current) return;
      storeReadyRef.current = true;
      setEditorStoreReady(true);
      if (savedCanvasRef.current) {
        editorStore.current?.loadJSON(savedCanvasRef.current);
      }
    });
    return () => {
      setEditorStoreReady(false);
      setPreviewStoreReady(false);
      storeReadyRef.current = false;
      setPreviewUrl('');
    }
  }, [designId, printJobId, customArtwork]);

  const getData = async () => {
    let savedCanvas = '';
    if (printJobId) {
      try {
        const resPrint = await apiClient.Api.PrintJob.detail({
          id: printJobId,
        });
        const printJob = resPrint.data?.data as TPrintJob;
        if (printJob?.data?.design?.canvas && checkPolotnoData(printJob.data.design.canvas)) {
          savedCanvas = printJob.data.design.canvas;
        }
      } catch (error) {
        console.log("error", error)
      }
    }
    try {
      const resDesign = await apiClient.Api.Design.detail({
        id: designId,
      });
      const design = resDesign.data?.data as TDesign;
      setDesignData(design);
      if (design?.productId) {
        if (!savedCanvas && design.data?.canvas && checkPolotnoData(design.data.canvas)) {
          savedCanvas = design.data.canvas;
        }
        const resProduct = await apiClient.Api.Product.detail({
          id: design.productId,
        });
        const product = resProduct.data?.data as TProduct;
        setProductData(product);
        let productType = product?.data?.productType;
        if (product?.data?.printAreas?.length) {
          if (product?.data?.productType === "signs") {
            productType = "signs";
          } else {
            if (product?.data?.maskImg || product?.data?.bgImg) {
              productType =  product?.data?.productType || "common";
            } else {
              productType = "common";
            }
          }
        }
        setProductType(productType);
        if (!savedCanvas && product?.data?.canvas && checkPolotnoData(product.data.canvas)) {
          savedCanvas = product.data.canvas;
        }
      } else {
        setErrorMessage("Product not found");
      }
    } catch (error) {
      console.log("error_design", error)
    }
    if (savedCanvas) savedCanvasRef.current = savedCanvas;
  }

  useEffect(() => {
    getData();
  }, [designId, printJobId, customArtwork]);

  const onPressPreviewPTSAction = async () => {
    const editorRes: any = await editorStore.current?.generateArtwork(true)
    if (editorRes?.artworkUrl) {
      const artworkUrl = await uploadFileToS3(editorRes?.artworkUrl);
      const res = await getPreviewPTSAction({
        artworkUrl,
        productType,
        product: productData,
      })
      return {
        previewUrl: res.data?.data,
        artworkUrls: [artworkUrl],
      };
    }
    if (editorRes?.artworkUrls?.length) {
      if (productType === "pants") {
        console.log("artworkUrls", editorRes?.artworkUrls)
        if (editorRes?.artworkUrls?.length < 2) return null;
      }
      const artworkUrls = await Promise.all(editorRes?.artworkUrls.map(async (url: string) => {
        const artworkUrl = await uploadFileToS3(url);
        return artworkUrl;
      }))
      const res = await getPreviewPTSAction({
        artworkUrls,
        productType,
        product: productData,
      });
      return {
        previewUrl: res.data?.data,
        artworkUrls,
      };
    }
    return {
      previewUrl: '',
      artworkUrls: [],
    };
  }

  const onPressPreview = async () => {
    if (["backpack", "tshirt", "pants", "common"].includes(productType)) {
      setIsLoadingPreview(true);
      const previewRes = await onPressPreviewPTSAction();
      const previewImage = previewRes?.previewUrl;
      if (previewImage) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview?image=${previewImage}`);
        previewStore.current = new Store();
        setTimeout(() => {
          previewStore.current?.onReady().then(() => {
            setPreviewStoreReady(true);
          });
        }, 1000);
      }
      setIsLoadingPreview(false);
    } else if (productType === "hat") {
      const data: any = await editorStore.current?.generateArtwork()
      // if (data?.previewUrl) {
      //   setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview?image=${data.previewUrl}`);
      // }
      if (data?.artworkUrl) {
        setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview-2d?image=${data.artworkUrl}&blend=${BLEND_URL.hat}&blend_support=${BLEND_SUPPORT_URL}`);
      }
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
    } else if (["signs", "other"].includes(productType)) {
      setIsLoadingPreview(true);
      const artworkBase64 = await editorStore.current?.toDataURL({
        pixelRatio: 1,
        pageId: editorStore.current?.activePage.id,
      }) as string;
      let artworkUrl = await uploadFileToS3(artworkBase64)
      setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview?image=${artworkUrl}`);
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
        });
      }, 1000);
      setIsLoadingPreview(false);
    } else {
      const data = await editorStore.current?.toDataURL({
        pixelRatio: 1,
        pageId: editorStore.current?.activePage.id,
      }) as string;
      setPreviewUrl(`${MICRO_SERVICES_HOST}/products/${productType}/preview`);
      previewStore.current = new Store();
      setTimeout(() => {
        previewStore.current?.onReady().then(() => {
          setPreviewStoreReady(true);
          previewStore.current?.setArtworkBase64(data);
        });
      }, 1000);
    }
  }

  const onPressEditor = async () => {
    setPreviewUrl('');
  }

  const onCheckout = async () => {
    setIsCheckingOut(true);
    try {
      const canvas = editorStore.current?.toJSON();
      let artworkUrls = [];
      let previewUrl = '';
      if (["tshirt", "backpack", "pants", "common"].includes(productType)) {
        const previewRes = await onPressPreviewPTSAction();
        previewUrl = previewRes?.previewUrl;
        artworkUrls = previewRes?.artworkUrls;
      } else if (["hat"].includes(productType)) {
        const data: any = await editorStore.current?.generateArtwork(true)
        if (data?.artworkUrl) {
          const blendPreviewUrl = await uploadFileToS3(data.artworkUrl)
          previewUrl = await renderAngle(blendPreviewUrl, "0");
          artworkUrls = [data.artworkUrl];
        }
      } else if (["signs", "other"].includes(productType)) {
        if (isEmpty(canvas)) return;
        const artworkBase64 = await editorStore.current?.toDataURL({
          pixelRatio: 3,
          pageId: editorStore.current?.activePage.id,
        }) as string;
        previewUrl = await uploadFileToS3(artworkBase64)
        artworkUrls = [artworkBase64];
      } else {
        if (isEmpty(canvas)) return;
        const artworkBase64 = await editorStore.current?.toDataURL({
          pixelRatio: 3,
          pageId: editorStore.current?.activePage.id,
        }) as string;
        const previewBase64 = await previewImageOnMask(tShirtMask, artworkBase64);
        previewUrl = await uploadFileToS3(previewBase64)
        artworkUrls = [artworkBase64];
      }

      artworkUrls = await Promise.all(artworkUrls.map(async (url: string) => {
        if (url.startsWith("http")) return url;
        return await uploadFileToS3(url);
      }))

      const { data: final } = await apiClient.Api.PrintJob.upsert({
        id: printJobId,
        artworkUrls,
        previewUrl,
        productName: productName || productData?.name || `From ${storeName}`.trimEnd(),
        clientId: designData?.createdByUserId,
        quantity: 1,
        designId,
        data: {
          storeUrl,
          storeProductId,
          design: {
            canvas,
          },
          product: {
            editorHeight: productData?.editorHeight,
            editorWidth: productData?.editorWidth,
            physicalWidth: designData?.width,
            physicalHeight: designData?.height,
            printAreas: designData?.printAreas,
          },
        },
      });
      const _printJobId = final.data.id;

      apiClient.editor.sendToParent({
        event: "CUSTOMIZATION_DONE",
        payload: {
          printJobId: _printJobId,
          thumbnail: previewUrl
        }
      })
    } catch (error) {
      alert(error?.message || JSON.stringify(error));
    } finally {
      setIsCheckingOut(false);
    }
  }


  const editorUrl = useMemo(() => {
    let queryObj: any = {}
    if (productData?.data?.printAreas?.length) {
      const printAreas = productData.data.printAreas
      queryObj.masks = printAreas.map(i => i.mask).join("|")
      queryObj.maskNames = printAreas.map(i => i.label).join("|")
    }
    if (productData?.data?.maskImg) {
      queryObj.maskImg = productData.data.maskImg
    }
    if (productData?.data?.bgImg) {
      queryObj.bgImg = productData.data.bgImg
    }
    if (customArtwork) {
      queryObj.image = customArtwork
    }
    if (Object.keys(restParams).length > 0) {
      queryObj = {
        ...queryObj,
        ...restParams
      }
    }
    let query = Object.entries(queryObj).map(([key, value]) => {
      if (!!value && String(value).trim()) {
        return `${key}=${value}`
      }
      return null;
    }).filter(i => !!i).join("&")
    return `${MICRO_SERVICES_HOST}/products/${productType}/editor${query ? `?${query}` : ""}`;
  }, [productType, productData, customArtwork, restParams]);

  const previewComp = useMemo(() => {
    if (!previewUrl) return null;
    if (productType === "hat") {
      return (
        <Col absoluteFill backgroundColor="#ccc">
          <iframe
            ref={iframePreviewRef}
            src={previewUrl}
            style={{ width: "100%", height: "100%", border: "none", backgroundColor: "#fff" }}
            onLoad={() => {
              previewStore.current?.init(iframePreviewRef.current);
            }}
          />
        </Col>
      )
    }
    return (
      <Col absoluteFill backgroundColor="#ccc" flex1>
        <Col flex1>
          <iframe
            ref={iframePreviewRef}
            src={previewUrl}
            style={{ width: "100%", height: "100%", border: "none", backgroundColor: "#fff" }}
            onLoad={() => {
              previewStore.current?.init(iframePreviewRef.current);
            }}
          />
        </Col>
      </Col>
    )
  }, [previewUrl, productType])

  if (!productType) {
    return (
      <Col flex1 middle>
        {!errorMessage ? (
          <ActivityIndicator color={COLOR.MAIN} />
        ) : (
          <Text color="red">{errorMessage}</Text>
        )}
      </Col>
    )
  };
  return (
    <Col flex1>
      <iframe
        ref={iframeRef}
        src={editorUrl}
        style={{ width: "100%", height: "100%", border: "none" }}
        onLoad={() => {
          editorStore.current?.init(iframeRef.current);
        }}
      />
      {previewComp}
      <Row absolute right={12} top={previewUrl ? 12 : 62}>
        {editorStoreReady && !previewUrl && !!editorStore.current?.activePage && (
          <ButtonOutline
            title="Preview"
            onPress={onPressPreview}
            loading={isLoadingPreview}
          />
        )}
        {previewStoreReady && previewUrl && (
          <ButtonOutline
            title="Editor"
            onPress={onPressEditor}
          />
        )}
        {editorStoreReady && (
          <AntButton
            size="large"
            loading={isCheckingOut}
            type="primary"
            onClick={onCheckout}
            style={{ marginLeft: 8 }}
          >
            <Text fontSize={14} fontWeight="600" colorWhite>Checkout</Text>
          </AntButton>
        )}
      </Row>
    </Col>
  );
};

ShopifyEditor.routeInfo = {
  title: 'MSupply Editor',
  path: '/legacy/shopify-editor/:designId',
};

export default ShopifyEditor;
