import React, { forwardRef, useEffect } from 'react';
import { Col } from 'components';
import Store from 'store';
import { useSettingsForm } from './FormContext';
import { BillingProfile } from '../components/BillingProfile';


const BillingSettings = (props, ref) => {
  const { billingInformation, updateBillingInformation } = useSettingsForm();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;

  const resellerId = UserStore.getResellerId(user);

  useEffect(() => {
    Store.Api.User.detail({ id: resellerId }).then(res => {
      updateBillingInformation({
        ...billingInformation,
        addressLine1: res.data.data?.addressLine1,
        addressLine2: res.data.data?.addressLine2,
        city: res.data.data?.town,
        postcode: res.data.data?.postCode,
        country: res.data.data?.country,
        currency: res.data.data?.currency,
        accountName: res.data.data?.accountName,
        firstName: res.data.data?.firstName,
        lastName: res.data.data?.lastName,
      }, true)
    })
  }, []);

  const onChangeField = (key) => (value) => {
    updateBillingInformation(s => ({
      ...s,
      [key]: value,
    }))
  }

  return (
    <Col flex1 {...props}>
      <BillingProfile billingInformation={billingInformation} onChangeField={onChangeField} />
    </Col>
  );
};

export default forwardRef(BillingSettings);
