import { Col, Row, Text, TouchField, Table, BgIcon, useMobileScreen, ButtonOutline } from 'components';
import React, { useMemo, useRef } from 'react';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR, COLORS } from 'const';
import { Image, Pressable } from 'react-native';
import moment from 'moment';
import { ASSETS } from 'assets';
import { Feather, EvilIcons, AntDesign } from '@expo/vector-icons';
import { commonFuncs } from './ListOrders.funcs';
import RequestUtils from 'store/api-client/Request.utils';
import { ClientName, DateDispatched, DownloadArtwork, OrderLineItems, OrderNo, PreviewIcon, TrackingID } from './ListOrders.Comps';
import { ColumnsType } from 'antd/es/table';
import { Tag, Button, Popover, Checkbox } from 'antd';
import OrderActions from './OrderActions';
import { TCMSOrder } from 'type';
import { ORDER_STAGE, ORDER_STATUS, STATUS_BY_STAGE } from 'store/Order.Store';
import { ValHelper } from 'helpers';
import { MICRO_SERVICES_HOST } from 'store/Order.Store';
import RequireApprovalModal from './RequireApprovalModal';

const getStatusTxt = (order: TCMSOrder, OrderStore) => {
  const lastPipeline = order.Pipelines.length === 0 ? undefined : order.Pipelines[order.Pipelines.length - 1];
  const { reprint, isPaid, isAdminApproved, resellerReprint, dispatchedDate, shippedOn } = lastPipeline?.SharedData || {};
  const orderId = order['Order ID'];

  let statusTxt: any = order.Status;
  if (order.Status === "Pending") {
    statusTxt = "Awaiting payment";
    if (isPaid) statusTxt = "Queued for production";
    // if (isPaid && !isAdminApproved) statusTxt = "Held";
  }
  if (order.Status === "Accepted") {
    statusTxt = OrderStore.isInProduction(order) ? "In production" : "Delayed";
    if (!!dispatchedDate) statusTxt = "Manifested"
    if (!!shippedOn) {
      statusTxt = "Delivered"
    }
  }
  return statusTxt;
}

const OrderTable = ({ isLoading, onRefresh, jobs, setJobs, stage, selectedJobs, setSelectedJobs, isSearch = false, onChangeFilter, onLayout = undefined }) => {
  const isMobile = useMobileScreen();
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const OrderStore = Store.useOrderStore();
  const requireApprovalModalRef = useRef<any>(null);

  const deleteOrder = async (val) => {
    const success = await commonFuncs.onDelete([val]);
    if (!Boolean(success)) return;
    setJobs(l => l.filter(i => i.Id !== val.Id));
  }

  const stageStatusOpts = useMemo(() => {
    let opts = [];
    if (stage && stage !== "all") {
      opts = STATUS_BY_STAGE[ValHelper.toDisplayStage(stage)] || [];
    }
    if (!opts.length) opts = ORDER_STATUS;
    return opts.map(i => ({ text: i, value: i }))
  }, [stage]);

  const renderStatus = (order) => {
    let statusTxt;
    if (order.StageStatus) {
      statusTxt = order.StageStatus;
    } else {
      statusTxt = getStatusTxt(order, OrderStore);
    }
    return (
      <Col alignItems="flex-start">
        <Tag bordered={false} color={COLORS.BG_DARKER}>
          <Text>{statusTxt}</Text>
        </Tag>
        {/* {!!reprint && (
          <Text caption mt0>FOC - reprint</Text>
        )}
        {!!resellerReprint && (
          <Text caption mt0>Reprint</Text>
        )} */}
      </Col>
    )
  }

  const renderStage = (order) => {
    let stageTxt = order.Status;
    if (order.Status === "Pending") {
      stageTxt = "Pre production";
    }
    if (order.Status === "Accepted") {
      stageTxt = "In production";
    }
    if (order.Status === "Fulfilled") stageTxt = "Fulfillment"
    if (order.Status === "Rejected") stageTxt = "Cancelled"
    if (order.Stage) stageTxt = order.Stage
    return (
      <Col alignItems="flex-start">
        <Tag bordered={false} color={COLORS.BG_DARKER}>
          <Text>{stageTxt}</Text>
        </Tag>
      </Col>
    )
  }

  const renderOrderType = (order) => {
    const lastPipeline = order.Pipelines.length === 0 ? undefined : order.Pipelines[order.Pipelines.length - 1];
    const { isSampleRequest } = lastPipeline?.SharedData || {};

    return (
      <Col alignItems="flex-start">
        {(order['Raw Data']?.is_sample_request || isSampleRequest || order.OrderType === "Sample") ? (
          <Tag bordered={false} color="#D6E0FF">
            <Text color="#2952CC">Sample</Text>
          </Tag>
        ) : (order.OrderType === "Etsy" ? (
          <Tag bordered={false} color="default">
            <Text color="#E56F26">Etsy</Text>
          </Tag>
        ) : (
          <Tag bordered={false} color="default">
            <Text color="#2952CC">Shopify</Text>
          </Tag>
        ))}
        {order["Other Data"]?.isTestOrder && (
          <Tag bordered={false} color="warning" style={{ marginTop: 4 }}>
            <Text color="black">Test</Text>
          </Tag>
        )}
      </Col>
    )
  }

  const renderRequireApproval = (order) => {
    const lastPipeline = order.Pipelines.length === 0 ? undefined : order.Pipelines[order.Pipelines.length - 1];
    const { customerAproval } = lastPipeline?.SharedData || {};

    return (
      <Col alignItems="flex-start">
        {!customerAproval && (
          <Button type="default" onClick={() => requireApprovalModalRef.current.show(order)}>
            Send
          </Button>
        )}
        {!!customerAproval && (
          <Tag bordered={false} color="default">
            {customerAproval === "pending" && <Text color="#E56F26">Sent</Text>}
            {customerAproval === "approved" && <Text color="green">Approved</Text>}
            {customerAproval === "rejected" && <Text color="red">Rejected</Text>}
          </Tag>
        )}
      </Col>
    )
  }

  const columns: ColumnsType<any> = [
    {
      title: 'No.',
      key: 'orderNo',
      render: (order) => (
        <OrderNo order={order} canShowId={user?.role === 'admin'} />
      )
    },
    {
      title: 'Client',
      key: 'client',
      width: 180,
      render: (order) => (
        <Text>{order['Customer Name']}</Text>
      )
    },
    {
      title: 'Product',
      key: 'product',
      render: (order) => (
        <OrderLineItems order={order['Raw Data']} supportedItemIDs={order['Item Supported']} />
      )
    },
    {
      title: 'Date created',
      key: 'CreatedAt',
      sorter: true,
      // sorter: (a, b) => moment(a.CreatedAt).unix() - moment(b.CreatedAt).unix(),
      // sortDirections: ['descend'],
      render: (order) => (
        <Text>{moment(order.CreatedAt).format('DD/MM/YYYY')}</Text>
      )
    },
    // (['accepted', 'fulfilled'].includes(status) || isSearch) && {
    //   title: 'Date dispatched',
    //   key: 'dateDispatched',
    //   render: (order) => (
    //     <DateDispatched order={order} />
    //   )
    // },
    {
      title: 'Status',
      key: 'stageStatus',
      filters: stageStatusOpts,
      // onFilter: (value, order) => {
      //   const statusTxt = getStatusTxt(order, OrderStore);
      //   return statusTxt === value;
      // },
      render: renderStatus
    },
    {
      title: 'Stage',
      key: 'stage',
      filters: stage === "all" ? ORDER_STAGE.map(i => ({ text: i, value: i })) : undefined,
      render: renderStage,
    },
    window.location.href.includes("debug=1") && {
      title: 'Pipeline',
      key: 'pipelineRef',
      render: (order) => {
        const openLink = () => {
          window.open(`${MICRO_SERVICES_HOST}/pipeline?slug=${order.ApiSlug || "bg"}&orderId=${order["Order ID"]}&token=${RequestUtils.getToken()}`, '_blank');
        }
        return (
          <Col alignItems="flex-start" onPress={openLink}>
            <Tag>
              <Text>Open</Text>
            </Tag>
          </Col>
        )
      }
    },
    {
      title: 'Order type',
      key: 'orderType',
      filters: ["Shopify", "Sample", "Etsy"].map(i => ({ text: i, value: i })),
      render: renderOrderType,
    },
    {
      title: 'Require Approval',
      key: 'requireApproval',
      render: renderRequireApproval,
    },
    ((['in-production', 'all'].includes(stage) || isSearch) && user?.role === 'admin') && {
      title: 'Artwork',
      key: 'artwork',
      render: (order) => (
        <DownloadArtwork order={order} apiSlug={order.ApiSlug} onRefresh={onRefresh} />
      )
    },
    // (status === 'accepted' || isSearch) && {
    //   title: 'Packing Slips',
    //   key: 'packingSlip',
    //   render: (order) => (
    //     <Col>
    //       <a href={`${RequestUtils.host}/api/online-stores/order-packing-slips/${order['Order ID']}?clientId=${order['Client ID']}`} target="_blank" style={{ textDecoration: 'none' }}
    //       >
    //         <TouchField cirle middle>
    //           {/* PACKING SLIPS */}
    //           <Feather name="external-link" size={24} color="black" />
    //         </TouchField>
    //       </a>
    //     </Col>
    //   )
    // },
    // (status === 'fulfilled' || isSearch) && {
    //   title: 'Info Job',
    //   key: 'infoJob',
    //   render: (order) => (
    //     <TrackingID order={order} />
    //   )
    // },
    {
      title: 'Actions',
      key: 'actions',
      render: (order) => {
        return <OrderActions order={order} stage={stage} isSearch={isSearch} />
      }
    }
  ].filter(Boolean);

  if (isMobile) {
    return (
      <Col onLayout={onLayout}>
        {jobs.map((job: TCMSOrder, idx) => {
          const rowKey = `${job.Id}_${job['Order ID']}`;
          const isSelected = selectedJobs[rowKey];
          const onSelect = () => {
            const isSelected = selectedJobs[rowKey];
            const obj = { ...selectedJobs };
            if (isSelected) {
              delete obj[rowKey];
            } else {
              obj[rowKey] = true;
            }
            setSelectedJobs(obj);
          }
          return (
            <Row bgWhite borderRadius={8} padding={10} marginBottom={10} key={`${job.Id}-${idx}`} alignItems="flex-start">
              <Pressable onPress={onSelect} hitSlop={10} style={{ marginTop: 12 }}>
                <Checkbox checked={isSelected} onClick={onSelect} />
              </Pressable>
              <Col paddingLeft={15} flex1>
                <Row>
                  <Col flex1>
                    <Row>
                      <Text>#{job['Order Source ID'] ? job['Order ID'] : job?.['Order Number']}</Text>
                      <Col marginLeft={14}>
                        {renderStatus(job)}
                      </Col>
                    </Row>
                    <Col height={24} marginTop={2}>
                      <OrderLineItems order={job['Raw Data']} supportedItemIDs={job['Item Supported']} />
                    </Col>
                  </Col>
                  <OrderActions order={job} stage={stage} isSearch={isSearch} />
                </Row>
                <Row marginTop={12} alignItems="flex-start">
                  <Row flex1 alignItems="flex-start">
                    <Text>Client</Text>
                    <Col marginLeft={10} flex1>
                      <ClientName order={job} canShowId={user?.role === 'admin'} />
                    </Col>
                  </Row>
                  <Row flex1 alignItems="flex-start">
                    <Text>Order date</Text>
                    <Col marginLeft={10} flex1>
                      <Text>{moment(job.CreatedAt).format('DD/MM/YYYY')}</Text>
                    </Col>
                  </Row>
                </Row>
                <Row marginTop={10} alignItems="flex-start">
                  <Row flex1 alignItems="flex-start">
                    <Text>Stage</Text>
                    <Col marginLeft={10} flex1>
                      {renderStage(job)}
                    </Col>
                  </Row>
                  <Row flex1 alignItems="flex-start">
                    <Text>Order type</Text>
                    <Col marginLeft={10} flex1>
                      {renderOrderType(job)}
                    </Col>
                  </Row>
                </Row>
              </Col>
            </Row>
          )
        })}
      </Col>
    )
  }
  return (
    <Col onLayout={onLayout}>
      <Table
        data={jobs}
        columns={columns}
        rowKey={val => `${val.Id}_${val['Order ID']}`}
        minWidth={600}
        loading={isLoading}
        rowSelection={(user?.role !== 'admin' && ['fulfillment', 'cancelled'].includes(stage)) ? undefined : {
          selectedRowKeys: Object.keys(selectedJobs),
          onChange: (selectedRowKeys) => {
            const obj = {};
            selectedRowKeys.forEach(i => obj[i] = true);
            setSelectedJobs(obj);
          },
        }}
        onChange={onChangeFilter}
        onRow={(val, idx) => ({
          onClick: () => {
            const rowKey = `${val.Id}_${val['Order ID']}`;
            const isSelected = selectedJobs[rowKey];
            const obj = { ...selectedJobs };
            if (isSelected) {
              delete obj[rowKey];
            } else {
              obj[rowKey] = true;
            }
            setSelectedJobs(obj);
          }
        })}
      />
      <RequireApprovalModal ref={requireApprovalModalRef} />
    </Col>
  )
};

export default OrderTable;
