import React, { useState } from "react";
import { Button } from "components/elements";
import { PopupModal } from "react-calendly";
import { useUserStore } from "store/User.Store";

const CalendlyButton = () => {
  const [show, setShow] = useState(false);
  const { user } = useUserStore();

  return (
    <>
      <Button
        text={"Need help? Book a call"}
        width={180}
        height={40}
        borderRadius={20}
        onPress={() => setShow(true)}
        outline
      />
      <PopupModal
        url="https://calendly.com/admin-gfy/30min"
        pageSettings={{
          primaryColor: '#32abe2',
        }}
        prefill={user ? {
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          name: [user.firstName, user.lastName].filter(Boolean).join(" ")
        } : undefined}
        onModalClose={() => setShow(false)}
        open={show}
        rootElement={document.getElementById("root")}
      />
    </>
  )
}

export default CalendlyButton;
